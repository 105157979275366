import { GridItem, useDisclosure, Portal } from '@chakra-ui/react';
import size from 'lodash/size';
import dynamic from 'next/dynamic';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  DepartmentId,
  DepartmentNanoId
} from '../../../../departments/departmentsTypes';
import { usePaginatedJobCandidates } from '../../../../jobCandidates/hooks/usePaginatedJobCandidates';
import { JobCandidatesCache } from '../../../../jobCandidates/JobCandidatesCache';
import {
  FetchJobCandidatesCountType,
  FetchJobCandidatesFilters,
  JobCandidateFields,
  JobCandidateNanoId,
  JobCandidateSearchTypes
} from '../../../../jobCandidates/jobCandidatesTypes';
import {
  fetchJobCandidatesQuery,
  FetchJobCandidatesResponse
} from '../../../../jobCandidates/queries/fetchJobCandidates.query';
import { JobId, JobNanoId } from '../../../../jobs/jobsTypes';
import { UserId } from '../../../../users/usersTypes';
import { TalentPoolCandidatesList } from '../../lists/TalentPoolCandidatesList';

const TalentPoolFilterPopup = dynamic(
  () => import('../TalentPoolFilterPopup/TalentPoolFilterPopup'),
  {
    ssr: false
  }
);

interface TalentPoolCandidatesListContainerProps {
  currentUser: {
    id: UserId;
  };
  personNanoId?: JobCandidateNanoId;
  jobCandidateRoute: (
    departmentNanoId: DepartmentNanoId,
    personNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) => string;
  isDisabled?: boolean;
  fullHeight?: boolean;
  department?: {
    nanoId: DepartmentNanoId;
    id: DepartmentId;
  };
  job?: {
    nanoId: JobNanoId;
    id: JobId;
    department: {
      nanoId: DepartmentNanoId;
      id: DepartmentId;
    };
  };
  updateProspectsCount?: (count: number) => void;
}

export interface TalentPoolFilterParams {
  isNew: boolean;
  isLiked: boolean;
  isSharing: boolean;
  isApplicant: boolean;
  isRecruited: boolean;
  isScheduled: boolean;
  recruiters: string[];
  department: string[];
  location: string[];
  stages: string[];
  hiringManager: string[];
  jobStatus: string[];
}

type TalentPoolFilterParamKey = keyof TalentPoolFilterParams;

export type HandleTalentPoolFilterChangeType = (
  key: TalentPoolFilterParamKey,
  value: unknown
) => void;

function TalentPoolCandidatesListContainer({
  job,
  department,
  personNanoId,
  currentUser,
  isDisabled,
  fullHeight,
  jobCandidateRoute,
  updateProspectsCount
}: TalentPoolCandidatesListContainerProps) {
  const initialFilters = useMemo(
    () => ({
      isNew: false,
      isLiked: false,
      isSharing: false,
      isApplicant: false,
      isRecruited: false,
      isScheduled: false,
      location: [],
      recruiters: [],
      department: [
        ...[
          department?.id ? String(department.id) : null,
          job?.department?.id ? String(job.department.id) : null
        ].filter((id): id is string => Boolean(id))
      ],
      hiringManager: [],
      jobStatus: [],
      stages: []
    }),
    [department?.id, job?.department?.id]
  );

  const containerRef = useRef<HTMLDivElement>(null);

  const [currentFilters, setCurrentFilters] =
    useState<TalentPoolFilterParams>(initialFilters);
  const [activeFilters, setActiveFilters] =
    useState<TalentPoolFilterParams>(initialFilters);

  const getDefaultJobCandidateFilters = useCallback(
    () => ({
      [JobCandidateFields.SEARCH_TYPE]: {
        operator: 'not.eq',
        value: JobCandidateSearchTypes.LEAD
      },
      ...(department?.id || job?.department?.id
        ? {
            [JobCandidateFields.DEPARTMENT_ID]: {
              operator: 'eq',
              value: department?.id || job?.department?.id
            }
          }
        : {})
    }),
    [department?.id, job?.department?.id]
  );

  const {
    jobCandidates,
    jobCandidatesIsFetched,
    jobCandidatesIsLoading,
    jobCandidatesTotalCount,
    jobCandidatesErrorMessage,
    changeJobCandidatesFilters,
    filterJobCandidates
  } = usePaginatedJobCandidates<FetchJobCandidatesResponse>({
    query: fetchJobCandidatesQuery,
    cacheKey: JobCandidatesCache.indexCacheKey(),
    countType: 'exact' as FetchJobCandidatesCountType,
    initialFilters:
      getDefaultJobCandidateFilters() as unknown as FetchJobCandidatesFilters,

    onSuccess(data) {
      const count = size(data.data);
      updateProspectsCount?.(count);
    }
  });

  const filterCandidatesByDepartment = useCallback<
    (departmentId: DepartmentId) => void
  >(
    (departmentId) => {
      if (departmentId === 'all') {
        filterJobCandidates({
          [JobCandidateFields.SEARCH_TYPE]: {
            operator: 'not.eq',
            value: JobCandidateSearchTypes.LEAD
          }
        });
      } else {
        changeJobCandidatesFilters({
          [JobCandidateFields.DEPARTMENT_ID]: {
            operator: 'eq',
            value: departmentId
          }
        });
      }
    },
    [changeJobCandidatesFilters, filterJobCandidates]
  );

  const { isOpen, onToggle, onClose } = useDisclosure({
    onClose: () => {
      setCurrentFilters(activeFilters);
    }
  });

  const toggleFilter = useCallback(() => {
    onToggle();
  }, [onToggle]);

  const handleFilterChange: HandleTalentPoolFilterChangeType = useCallback(
    (key, value) => {
      setCurrentFilters((prevFilters) => ({
        ...prevFilters,
        [key]: value
      }));
    },
    []
  );

  const applyFilters = useCallback(() => {
    setActiveFilters(currentFilters);
    onClose();

    filterJobCandidates({
      ...(currentFilters.location.length
        ? {
            [JobCandidateFields.CITY_ID]: {
              operator: 'eq',
              value: currentFilters.location
            }
          }
        : {}),
      ...(currentFilters.department.length
        ? {
            [JobCandidateFields.DEPARTMENT_ID]: {
              operator: 'eq',
              value: currentFilters.department
            }
          }
        : {}),
      [JobCandidateFields.SEARCH_TYPE]: {
        operator: 'eq',
        value: currentFilters.isApplicant
          ? JobCandidateSearchTypes.APPLICANT
          : JobCandidateSearchTypes.LEAD
      }
    });
  }, [currentFilters, filterJobCandidates, onClose]);

  const clearFilters = useCallback(() => {
    setCurrentFilters(initialFilters);
    setActiveFilters(initialFilters);

    filterJobCandidates(
      getDefaultJobCandidateFilters() as unknown as FetchJobCandidatesFilters
    );

    onClose();
  }, [
    filterJobCandidates,
    getDefaultJobCandidateFilters,
    initialFilters,
    onClose
  ]);

  return (
    <>
      <GridItem
        w="full"
        h="full"
        pos="relative"
        overflow="hidden"
        ref={containerRef}
      >
        <TalentPoolCandidatesList
          jobCandidates={jobCandidates}
          jobCandidatesTotalCount={jobCandidatesTotalCount}
          jobCandidatesIsFetched={jobCandidatesIsFetched}
          jobCandidatesIsLoading={jobCandidatesIsLoading}
          jobCandidatesErrorMessage={jobCandidatesErrorMessage}
          department={department || job?.department}
          personNanoId={personNanoId}
          currentUser={currentUser}
          jobCandidateRoute={jobCandidateRoute}
          filterCandidatesByDepartment={filterCandidatesByDepartment}
          isDisabled={isDisabled}
          fullHeight={fullHeight}
          activeFilters={activeFilters}
          clearFilters={clearFilters}
          toggleFilter={toggleFilter}
          isFilterOpen={isOpen}
        />
      </GridItem>

      {isOpen && (
        <Portal>
          <TalentPoolFilterPopup
            isOpen={isOpen}
            onClose={onClose}
            applyFilters={applyFilters}
            clearFilters={clearFilters}
            onFilterChange={handleFilterChange}
            filters={currentFilters}
            positionRef={containerRef}
          />
        </Portal>
      )}
    </>
  );
}

export default TalentPoolCandidatesListContainer;
