import { ViewJobCandidateDetailsProfileOverviewJobFitScorecardField } from './ViewJobCandidateDetailsProfileOverviewJobFitScorecard';

export const mockScorecardData: ViewJobCandidateDetailsProfileOverviewJobFitScorecardField[] =
  [
    {
      label: 'Qualifications',
      value: 12,
      color: '#AB56B2',
      subfields: [
        { label: 'Degree', value: 5, color: '#D88BDA' },
        { label: 'Certifications', value: 4, color: '#C374C6' },
        { label: 'Experience', value: 3, color: '#A85CA5' }
      ]
    },
    {
      label: 'Job fit',
      value: 8,
      color: '#287CC9',
      subfields: [
        { label: 'Skills', value: 4, color: '#5A9DE0' },
        { label: 'Cultural Fit', value: 2, color: '#4B8CD8' },
        { label: 'Attitude', value: 2, color: '#3B7BCC' }
      ]
    },
    {
      label: 'Tenure',
      value: 12,
      color: '#EEA401',
      subfields: [
        { label: 'Previous Jobs', value: 6, color: '#FFC233' },
        { label: 'Loyalty', value: 3, color: '#E6A500' },
        { label: 'Growth Potential', value: 3, color: '#D89200' }
      ]
    },
    {
      label: 'Screening',
      value: 10,
      color: '#507D7B',
      subfields: [
        { label: 'Background Check', value: 4, color: '#6A9F9D' },
        { label: 'Reference Check', value: 3, color: '#5D908E' },
        { label: 'Initial Interview', value: 3, color: '#4F8180' }
      ]
    },
    {
      label: 'Questions',
      value: 8,
      color: '#82BFD1',
      subfields: [
        { label: 'Technical Questions', value: 3, color: '#A1D4E3' },
        { label: 'Behavioral Questions', value: 3, color: '#8FC7D8' },
        { label: 'Problem-Solving', value: 2, color: '#7DBBCF' }
      ]
    }
  ];
