import {
  JobCandidateItemBffID,
  JobCandidateItemBffNanoID,
  FetchJobCandidatesBffURL,
  FetchJobCandidateBffURL,
  FetchJobCandidateByNanoIdBffURL,
  CreateJobCandidateBffURL,
  UpdateJobCandidateBffURL,
  DeleteJobCandidateBffURL,
  AddCandidateToJobBffURL,
  AddCandidateToTalentPoolBffURL,
  RemoveCandidateFromTalentPoolBffURL,
  RejectJobCandidateBffURL,
  RecruitJobCandidateBffURL,
  SaveJobCandidateBffURL,
  RevertJobCandidatePipelineStatusBffURL,
  UpdateJobCandidateOwnerBffURL,
  ImportJobCandidateEmailsBffURL
} from './jobCandidatesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class JobCandidatesBffRoutes extends BaseBffRoutes {
  protected static route = 'jobCandidates';

  static fetchJobCandidatesRoute() {
    return this.fetchItemsRoute<FetchJobCandidatesBffURL>();
  }

  static fetchJobCandidateRoute(jobCandidateItemBffID: JobCandidateItemBffID) {
    return this.fetchItemRoute<FetchJobCandidateBffURL>(jobCandidateItemBffID);
  }

  static fetchJobCandidateByNanoIdRoute(
    jobCandidateItemBffNanoID: JobCandidateItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchJobCandidateByNanoIdBffURL>(
      jobCandidateItemBffNanoID
    );
  }

  static createJobCandidateRoute() {
    return this.createItemRoute<CreateJobCandidateBffURL>();
  }

  static updateJobCandidateRoute(
    jobCandidateItemBffNanoID: JobCandidateItemBffID | JobCandidateItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateJobCandidateBffURL>(
      jobCandidateItemBffNanoID
    );
  }

  static deleteJobCandidateRoute(
    jobCandidateItemBffNanoID: JobCandidateItemBffNanoID
  ) {
    return this.deleteItemRoute<DeleteJobCandidateBffURL>(
      jobCandidateItemBffNanoID
    );
  }

  static addCandidateToJobRoute(
    jobCandidateItemBffNanoID: JobCandidateItemBffNanoID
  ) {
    return `${this.basePath}/${this.route}/${jobCandidateItemBffNanoID}/add-to-job` as AddCandidateToJobBffURL;
  }

  static addCandidateToTalentPoolRoute(
    jobCandidateItemBffNanoID: JobCandidateItemBffNanoID
  ) {
    return `${this.basePath}/${this.route}/${jobCandidateItemBffNanoID}/add-to-talent-pool` as AddCandidateToTalentPoolBffURL;
  }

  static removeCandidateFromTalentPoolRoute(
    jobCandidateItemBffNanoID: JobCandidateItemBffNanoID
  ) {
    return `${this.basePath}/${this.route}/${jobCandidateItemBffNanoID}/remove-from-talent-pool` as RemoveCandidateFromTalentPoolBffURL;
  }

  static recruitJobCandidateRoute(
    jobCandidateItemBffNanoID: JobCandidateItemBffID | JobCandidateItemBffNanoID
  ) {
    return `${this.basePath}/${this.route}/${jobCandidateItemBffNanoID}/recruit` as RecruitJobCandidateBffURL;
  }

  static rejectJobCandidateRoute(
    jobCandidateItemBffNanoID: JobCandidateItemBffID | JobCandidateItemBffNanoID
  ) {
    return `${this.basePath}/${this.route}/${jobCandidateItemBffNanoID}/reject` as RejectJobCandidateBffURL;
  }

  static saveJobCandidateRoute(
    jobCandidateItemBffNanoID: JobCandidateItemBffID | JobCandidateItemBffNanoID
  ) {
    return `${this.basePath}/${this.route}/${jobCandidateItemBffNanoID}/save` as SaveJobCandidateBffURL;
  }

  static revertJobCandidatePipelineStatusRoute(
    jobCandidateItemBffNanoID: JobCandidateItemBffID | JobCandidateItemBffNanoID
  ) {
    return `${this.basePath}/${this.route}/${jobCandidateItemBffNanoID}/pipeline-status-none` as RevertJobCandidatePipelineStatusBffURL;
  }

  static importJobCandidateEmailsRoute(
    jobCandidateItemBffNanoID: JobCandidateItemBffID | JobCandidateItemBffNanoID
  ) {
    return `${this.basePath}/${this.route}/${jobCandidateItemBffNanoID}/import-emails` as ImportJobCandidateEmailsBffURL;
  }

  static updateJobCandidateOwnerRoute(
    jobCandidateItemBffNanoID: JobCandidateItemBffID | JobCandidateItemBffNanoID
  ) {
    return `${this.basePath}/${this.route}/${jobCandidateItemBffNanoID}/update-owner` as UpdateJobCandidateOwnerBffURL;
  }
}
