import React from 'react';

import { ErrorMessage, I18nText } from '../../../../../types';

import { JobCandidateId } from '../../../../jobCandidates/jobCandidatesTypes';

import {
  JobResumeId,
  JobResumeJobResumesSkills
} from '../../../../jobResumes/jobResumesTypes';

import {
  SkillBadgeJobCandidateId,
  SkillBadgeJobResumeId
} from '../../../../skillBadges/skillBadgesTypes';

import {
  FetchSkillsCacheKey,
  SkillKinds
} from '../../../../skills/skillsTypes';

import { SkillBadgesCache } from '../../../../skillBadges/SkillBadgesCache';

import { CommonSkillsBox } from '../../../../skills/components/boxes/CommonSkillsBox';

interface JobCandidateSelectSkillBadgesFieldDefaultProps {
  label: I18nText;
  actionLabel?: I18nText;
  skillsCacheKey: FetchSkillsCacheKey;
  newSkillsCacheKey: FetchSkillsCacheKey;
  skillKind: SkillKinds;
  errorMessage?: ErrorMessage;
  isApplicantWorkflow?: boolean;
}

interface JobCandidateSelectSkillBadgesFieldWithJobCandidateProps {
  jobCandidate: {
    id: JobCandidateId;
  };
  jobResume?: never;
}

interface JobCandidateSelectSkillBadgesFieldWithJobResumeProps {
  jobCandidate?: never;
  jobResume: {
    id: JobResumeId;
    jobResumesSkills: JobResumeJobResumesSkills;
  };
}

type JobCandidateSelectSkillBadgesFieldProps =
  JobCandidateSelectSkillBadgesFieldDefaultProps &
    (
      | JobCandidateSelectSkillBadgesFieldWithJobCandidateProps
      | JobCandidateSelectSkillBadgesFieldWithJobResumeProps
    );

function JobCandidateSelectSkillBadgesField({
  label,
  actionLabel,
  jobCandidate,
  jobResume,
  skillKind,
  skillsCacheKey,
  newSkillsCacheKey,
  isApplicantWorkflow,
  errorMessage
}: JobCandidateSelectSkillBadgesFieldProps) {
  const skillBadgesCacheKey =
    (jobCandidate?.id
      ? SkillBadgesCache.postJobCandidateCacheKey(
          jobCandidate.id as SkillBadgeJobCandidateId
        )
      : null) ||
    (jobResume?.id
      ? SkillBadgesCache.postJobResumeCacheKey(
          jobResume.id as SkillBadgeJobResumeId
        )
      : null) ||
    SkillBadgesCache.listCacheKey();

  if (jobCandidate) {
    return (
      <CommonSkillsBox
        label={label}
        actionLabel={actionLabel}
        errorMessage={errorMessage}
        skillKind={skillKind}
        jobCandidateId={jobCandidate?.id}
        skillBadgesCacheKey={skillBadgesCacheKey}
        skillsCacheKey={skillsCacheKey}
        newSkillsCacheKey={newSkillsCacheKey}
        isApplicantWorkflow={isApplicantWorkflow as true | undefined}
        withoutPortal
      />
    );
  }

  return (
    <CommonSkillsBox
      label={label}
      actionLabel={actionLabel}
      errorMessage={errorMessage}
      skillKind={skillKind}
      jobResume={jobResume}
      skillBadgesCacheKey={skillBadgesCacheKey}
      skillsCacheKey={skillsCacheKey}
      newSkillsCacheKey={newSkillsCacheKey}
      withoutPortal
    />
  );

  // const { skillBadges, skillBadgesIsFetched, skillBadgesErrorMessage } =
  //   usePaginatedSkillBadges<FetchSkillBadgesResponse>({
  //     queryName: fetchSkillBadgesQueryName,
  //     cacheKey: skillBadgesCacheKey,
  //     initialFilters: {
  //       ...(jobCandidate?.id
  //         ? {
  //             [SkillBadgeFields.JOB_CANDIDATE_ID]: {
  //               operator: 'eq',
  //               value: jobCandidate.id
  //             }
  //           }
  //         : {}),
  //       ...(jobResume?.id
  //         ? {
  //             [SkillBadgeFields.JOB_RESUME_ID]: {
  //               operator: 'eq',
  //               value: `${jobResume.id}`
  //             }
  //           }
  //         : {}),
  //       'skill.kind': { operator: 'eq', value: skillKind }
  //     } as unknown as FetchSkillBadgesFilters,
  //     initialSort: {
  //       [SkillBadgeFields.CREATED_AT]: { ascending: false }
  //     } as unknown as FetchSkillBadgesSort,
  //     initialPageSize: 1000 as FetchSkillBadgesPageSize
  //   });
  //
  // const { skills, skillsIsFetched, skillsErrorMessage } =
  //   usePaginatedSkills<FetchSkillsResponse>({
  //     queryName: fetchSkillsQueryName,
  //     cacheKey: skillsCacheKey,
  //     initialPageSize: 500 as FetchSkillsPageSize,
  //     initialFilters: {
  //       [SkillFields.KIND]: { operator: 'eq', value: skillKind },
  //       ...(jobResume
  //         ? {
  //             [SkillFields.ID]: {
  //               operator: 'in',
  //               value: `(${(
  //                 jobResume.jobResumesSkills?.map(({ skillId }) => skillId) ||
  //                 []
  //               ).join(',')})`
  //             }
  //           }
  //         : {})
  //     } as unknown as FetchSkillsFilters,
  //     initialSort: {
  //       [SkillFields.CREATED_AT]: { ascending: false }
  //     } as unknown as FetchSkillsSort
  //   });
  //
  // const skillNanoIds = skillBadges.map(
  //   (skillBadge) => skillBadge.skill?.nanoId
  // );
  //
  // const skillIds = compact([
  //   ...skillBadges.map((skillBadge) => skillBadge.skillId),
  //   ...skills.map((skill) => skill.id)
  // ]);
  //
  // const [selectedSkillBadge, setSelectedSkillBadge] =
  //   useState<FetchSkillBadgesResponse | null>(null);
  //
  // const clearSelectedSkillBadge = useCallback<() => void>(
  //   () => setSelectedSkillBadge(null),
  //   [setSelectedSkillBadge]
  // );
  //
  // const [selectedSkill, setSelectedSkill] =
  //   useState<FetchSkillsResponse | null>(null);
  //
  // const [newSkill, setNewSkill] = useState<NewSkillType | null>(null);
  //
  // const clearSkills = useCallback<() => void>(() => {
  //   setSelectedSkill(null);
  //   setNewSkill(null);
  // }, [setSelectedSkill, setNewSkill]);
  //
  // return (
  //   <Stack spacing={4}>
  //     <Text textStyle="body2Regular" textTransform="uppercase">
  //       {label}
  //     </Text>
  //
  //     <Flex flexWrap="wrap" gap={3}>
  //       {skillBadges.map((skillBadge) => (
  //         <CandidateSkillBadgeWrapper
  //           key={skillBadge.nanoId}
  //           withoutEditButton
  //           withoutPopover
  //           skillBadge={skillBadge}
  //           isEditing={selectedSkillBadge?.nanoId === skillBadge.nanoId}
  //           onEdit={setSelectedSkillBadge}
  //         />
  //       ))}
  //       <AlertMessage message={skillBadgesErrorMessage} />
  //     </Flex>
  //
  //     {selectedSkillBadge && jobCandidate?.id ? (
  //       <AddEditCandidateSkillBadgeForm
  //         skillBadge={selectedSkillBadge}
  //         jobCandidateId={jobCandidate.id}
  //         currentUserId={currentUserId}
  //         onAdd={clearSelectedSkillBadge}
  //         onDiscard={clearSelectedSkillBadge}
  //       />
  //     ) : null}
  //
  //     {selectedSkillBadge && jobResume?.id ? (
  //       <AddEditCandidateSkillBadgeForm
  //         withoutRating
  //         withoutExperience
  //         withoutLicense
  //         currentUserId={currentUserId}
  //         jobResumeId={jobResume.id}
  //         skillBadge={selectedSkillBadge}
  //         onAdd={clearSelectedSkillBadge}
  //         onDiscard={clearSelectedSkillBadge}
  //       />
  //     ) : null}
  //
  //     <Text>Add applicable skills</Text>
  //
  //     <Flex flexWrap="wrap" gap={3}>
  //       {skills.map((skill) => (
  //         <Fragment key={skill.nanoId}>
  //           {includes(skillNanoIds, skill.nanoId) ? null : (
  //             <AddSkillBadgeButton
  //               currentUserId={currentUserId}
  //               mode="create"
  //               skill={skill}
  //               isSelected={selectedSkill?.nanoId === skill.nanoId}
  //               skillBadgesCacheKey={skillBadgesCacheKey}
  //               jobCandidateId={jobCandidate?.id}
  //               jobResumeId={jobResume?.id}
  //               onSelectSkill={setSelectedSkill}
  //             />
  //           )}
  //         </Fragment>
  //       ))}
  //
  //       {/*<AddSkillBadgeWrapper*/}
  //       {/*  skill={skill}*/}
  //       {/*  isSelected={selectedSkill?.nanoId === skill.nanoId}*/}
  //       {/*  onSelectSkill={setSelectedSkill}*/}
  //       {/*/>*/}
  //
  //       {skillBadgesIsFetched && skillsIsFetched ? (
  //         <Box minW="90px">
  //           <AddSkillDropdownWrapper
  //             currentUserId={currentUserId}
  //             skillKind={skillKind}
  //             skillIds={skillIds}
  //             skillsCacheKey={newSkillsCacheKey}
  //             skillBadges={skillBadges}
  //             onSelectExistingSkill={setSelectedSkill}
  //             onSelectExistingSkillBadge={setSelectedSkillBadge}
  //             onSelectSkill={setNewSkill}
  //           />
  //         </Box>
  //       ) : null}
  //
  //       <AlertMessage message={errorMessage || skillsErrorMessage} />
  //     </Flex>
  //
  //     {(selectedSkill || newSkill) && jobCandidate?.id ? (
  //       <AddEditCandidateSkillBadgeForm
  //         currentUserId={currentUserId}
  //         jobCandidateId={jobCandidate.id}
  //         skill={selectedSkill || newSkill || undefined}
  //         onAdd={clearSkills}
  //         onDiscard={clearSkills}
  //       />
  //     ) : null}
  //
  //     {(selectedSkill || newSkill) && jobResume?.id ? (
  //       <AddEditCandidateSkillBadgeForm
  //         withoutRating
  //         withoutExperience
  //         withoutLicense
  //         currentUserId={currentUserId}
  //         jobResumeId={jobResume.id}
  //         skill={selectedSkill || newSkill || undefined}
  //         onAdd={clearSkills}
  //         onDiscard={clearSkills}
  //       />
  //     ) : null}
  //   </Stack>
  // );
}

export default JobCandidateSelectSkillBadgesField;
