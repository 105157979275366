import {
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react';
import filter from 'lodash/filter';
import size from 'lodash/size';

import { Heading, HeadingProps } from '../../../../../../helpers/Heading';
import { usePaginatedJobJobCandidates } from '../../../../../jobJobCandidates/hooks/usePaginatedJobJobCandidates';
import { JobJobCandidatesCache } from '../../../../../jobJobCandidates/JobJobCandidatesCache';
import {
  FetchJobJobCandidatesFilters,
  FetchJobJobCandidatesSort,
  JobJobCandidateFields
} from '../../../../../jobJobCandidates/jobJobCandidatesTypes';
import {
  fetchJobJobCandidatesQuery,
  FetchJobJobCandidatesResponse
} from '../../../../../jobJobCandidates/queries/fetchJobJobCandidates.query';
import { JobNanoId } from '../../../../jobsTypes';
import { renderJobTitle } from '../../../../utils/renderJobTitle';
import { JobCandidateDetailsJobCandidate } from '../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsHeaderMenuJobsJobItem } from '../ViewJobCandidateDetailsHeaderMenuJobsJobItem';
import { ViewJobCandidateDetailsJobDetailsJob } from '../ViewJobCandidateDetailsJobDetails';

interface ViewJobCandidateDetailsHeaderMenuJobsProps
  extends Omit<HeadingProps, 'children' | 'level'> {
  jobCandidate: JobCandidateDetailsJobCandidate;
  job: ViewJobCandidateDetailsJobDetailsJob;
  jobRoute: (jobNanoId: JobNanoId) => string;
}

export default function ViewJobCandidateDetailsHeaderMenuJobs({
  jobCandidate,
  job,
  color = 'gray.900',
  jobRoute,
  ...headingProps
}: ViewJobCandidateDetailsHeaderMenuJobsProps) {
  const { jobJobCandidates } =
    usePaginatedJobJobCandidates<FetchJobJobCandidatesResponse>({
      query: fetchJobJobCandidatesQuery,
      cacheKey: JobJobCandidatesCache.jobCandidateListCacheKey(
        jobCandidate?.id
      ),
      initialFilters: {
        [JobJobCandidateFields.JOB_CANDIDATE_ID]: {
          operator: 'eq',
          value: jobCandidate?.id
        }
      } as unknown as FetchJobJobCandidatesFilters,
      initialSort: {
        [JobJobCandidateFields.CREATED_AT]: {
          ascending: false
        }
      } as unknown as FetchJobJobCandidatesSort
    });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const otherJobs = filter(jobJobCandidates, (jobJobCandidate) => {
    return jobJobCandidate.job.nanoId !== job.nanoId;
  });

  return (
    <Tooltip label={renderJobTitle(job)} aria-label="Job Title" placement="top">
      <span onMouseEnter={onOpen} onMouseLeave={onClose}>
        <Popover isOpen={isOpen} isLazy>
          <PopoverTrigger>
            <HStack
              spacing={2}
              cursor={size(otherJobs) > 0 ? 'pointer' : 'auto'}
            >
              <Heading
                level="h2"
                fontSize="md"
                fontWeight="semibold"
                lineHeight={1.5}
                noOfLines={1}
                color={size(otherJobs) > 0 ? 'primary.500' : color}
                wordBreak="break-all"
                sx={{
                  span: {
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 'var(--chakra-line-clamp)'
                  }
                }}
                {...headingProps}
              >
                {renderJobTitle(job)}
                {size(otherJobs) > 0 ? ' +' : null}
              </Heading>
            </HStack>
          </PopoverTrigger>

          {size(otherJobs) > 0 ? (
            <Portal>
              <PopoverContent
                minW="250px"
                maxW="350px"
                p={3}
                maxH={60}
                overflow="hidden"
              >
                <Stack width="100%" spacing={1}>
                  {otherJobs?.map((jobJobCandidate) => (
                    <ViewJobCandidateDetailsHeaderMenuJobsJobItem
                      key={jobJobCandidate.id}
                      jobJobCandidateId={jobJobCandidate.id}
                      jobJobCandidateJob={jobJobCandidate.job}
                      jobCandidateId={jobCandidate?.id}
                      jobRoute={jobRoute}
                      onClose={onClose}
                    />
                  ))}
                </Stack>
              </PopoverContent>
            </Portal>
          ) : null}
        </Popover>
      </span>
    </Tooltip>
  );
}
