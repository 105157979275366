import { Avatar, Box, Flex, Stack, StackDivider } from '@chakra-ui/react';
import lowerCase from 'lodash/lowerCase';
import { useCurrentUser } from '../../../../../../../../auth/hooks/useAuth';
import { Button } from '../../../../../../../../helpers/Button';
import { Logo } from '../../../../../../../../helpers/Logo';
import { Text } from '../../../../../../../../helpers/Text';
import { ImagesUrl } from '../../../../../../../images/ImagesUrl';
import { JobLocationHelper } from '../../../../../../helpers/JobLocationHelper';
import {
  JobId,
  JobInterviewers,
  JobJobTitle,
  JobName,
  JobNanoId,
  MayBeJobCities,
  MayBeJobCity,
  MayBeJobCompany,
  MayBeJobHiringManager
} from '../../../../../../jobsTypes';
import { renderJobTitle } from '../../../../../../utils/renderJobTitle';
import { JobCandidateDetailsJobCandidate } from '../../../../ViewJobCandidateDetailsPage.types';
import { useViewJobCandidateDetailsSendAssessmentContext } from '../../../../contexts/ViewJobCandidateDetailsSendAssessmentContext';
import { Heading } from '../../../../../../../../helpers/Heading';

interface ViewJobCandidateDetailsAssessmentSendEmailProps {
  job: {
    id: JobId;
    nanoId: JobNanoId;
    jobTitle: JobJobTitle;
    name: JobName;
    cities: MayBeJobCities;
    city: MayBeJobCity;
    interviewers: JobInterviewers;
    hiringManager: MayBeJobHiringManager;
    company: MayBeJobCompany;
  };
  jobCandidate: JobCandidateDetailsJobCandidate;
}

export default function ViewJobCandidateDetailsAssessmentSendEmail({
  job,
  jobCandidate
}: ViewJobCandidateDetailsAssessmentSendEmailProps) {
  const currentUser = useCurrentUser();
  const { message } = useViewJobCandidateDetailsSendAssessmentContext();

  return (
    <>
      <Heading level="h5" textStyle="body1Medium" fontWeight="semibold">
        Send request
      </Heading>

      <Stack flex={1} spacing={5}>
        <Flex gap={4} flexDir="column" flex={1}>
          <Stack
            spacing={2}
            color="gray.600"
            textStyle="body1Regular"
            divider={<StackDivider />}
          >
            <Flex as="label" gap={1} cursor="pointer" alignItems="center">
              <Box as="span">To:</Box>

              <Text color="gray.800" fontSize="md" textTransform="capitalize">
                {jobCandidate.name}
              </Text>
            </Flex>

            <Flex as="label" gap={1} cursor="pointer" alignItems="center">
              <Box as="span">Subject:</Box>

              <Text
                fontSize="md"
                textTransform="capitalize"
                color="gray.800"
                noOfLines={1}
              >
                Job Fit Assessment: {lowerCase(renderJobTitle(job))}
              </Text>
            </Flex>
          </Stack>

          <Box bg="gray.100" p={4} flex={1}>
            <Stack
              bg="white"
              spacing={0}
              overflow="hidden"
              borderRadius="md"
              border="1px solid"
              borderColor="gray.200"
            >
              <Flex
                pt={2.5}
                bg="white"
                alignItems="center"
                justifyContent="center"
                gap={1}
                flex={1}
              >
                {currentUser.company?.image ? (
                  <Avatar
                    h={8}
                    w={8}
                    src={ImagesUrl.file(currentUser.company.image) || undefined}
                  />
                ) : null}

                <Flex gap={0.5} flexDir="column" justifyContent="center">
                  <Text fontSize="xs" lineHeight={1.15}>
                    {currentUser.company?.name}
                  </Text>
                  <Text fontSize="2xs" lineHeight={1.15} color="gray.600">
                    Talent Acquisition
                  </Text>
                </Flex>
              </Flex>

              <Stack p={4} spacing={3}>
                <Flex
                  p={4}
                  bg="gray.100"
                  flexDir="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text fontSize="sm" fontWeight="medium">
                    {job?.jobTitle?.name || job.name}
                  </Text>

                  <JobLocationHelper job={job} fontSize="xs" lineHeight={1.2} />
                </Flex>

                <Text fontSize="xs" lineHeight={1.75} fontWeight="medium">
                  Hi, {jobCandidate?.name}!
                </Text>

                <Text fontSize="xs" lineHeight={1.75} fontWeight="medium">
                  {message}
                </Text>

                <Box>
                  <Button size="small" px={5} w="full">
                    Continue to assessment
                  </Button>
                </Box>
              </Stack>

              <Stack p={4} borderTop="1px solid" borderColor="gray.300">
                <Text
                  fontSize="3xs"
                  color="gray.600"
                  lineHeight={1.5}
                  textAlign="center"
                >
                  You are receiving a nxmoov invitation email.{' '}
                  <Box as="span" color="primary.500">
                    Unsubscribe or change frequency
                  </Box>
                  . This email was intended for Sherry Johnson (Senior Director,
                  Talent Acquistion at AECOM). © 2023 Nxmoov LLC Litchfield
                  Park, AZ 85340. Nxmoov and the Nxmoov logo are registered
                  trademarks of Nxmoov
                </Text>

                <Flex bg="white" alignItems="center" justifyContent="center">
                  <Text mb={1.5} fontSize="2xs">
                    Powered by
                  </Text>
                  <Logo h="26px" w="64px" />
                </Flex>
              </Stack>
            </Stack>
          </Box>
        </Flex>
      </Stack>
    </>
  );
}
