import { Box, ButtonGroup, Collapse, FormLabel, Stack } from '@chakra-ui/react';
import compact from 'lodash/compact';
import find from 'lodash/find';
import { useEffect } from 'react';
import { AlertMessage } from '../../../../../../../../../../helpers/AlertMessage';
import { Button } from '../../../../../../../../../../helpers/Button';
import { Form } from '../../../../../../../../../../helpers/Form';
import { CleaveInputField } from '../../../../../../../../../../helpers/forms/formFields/CleaveInputField';
import { RadioGroupField } from '../../../../../../../../../../helpers/forms/formFields/RadioGroupField';
import { SelectField } from '../../../../../../../../../../helpers/forms/formFields/SelectField';
import { Heading } from '../../../../../../../../../../helpers/Heading';
import { Text } from '../../../../../../../../../../helpers/Text';
import { FormatNumber } from '../../../../../../../../../../utils/FormatNumber';
import { useUpdateJobCandidate } from '../../../../../../../../../jobCandidates/hooks/useUpdateJobCandidate';
import { JobCandidatesCache } from '../../../../../../../../../jobCandidates/JobCandidatesCache';
import { JobCandidateFields } from '../../../../../../../../../jobCandidates/jobCandidatesTypes';
import {
  paidTimeOffOptions,
  startAvailabilityOptions
} from '../../../../../../../../../jobResumeRecruiters/JobResumeRecruiters.data';
import {
  JobResumeRecruiterActivelyInterviewingOptions,
  JobResumeRecruiterCounterOfferOptions
} from '../../../../../../../../../jobResumeRecruiters/jobResumeRecruitersTypes';
import { WorkflowStagesCache } from '../../../../../../../../../workflowStages/WorkflowStagesCache';
import { workAuthorizationOptions } from '../../../../../../../../jobs.data';
import { ViewJobCandidateDetailsAssessmentMyNxmoovFormJobCandidateType } from '../../ViewJobCandidateDetailsAssessmentMyNxmoov.types';
import { useEditJobCandidateMyNxmoovForm } from './hooks/useEditJobCandidateMyNxmoovForm';
import { EditJobCandidateMyNxmoovFormData } from './ViewJobCandidateDetailsAssessmentMyNxmoovForm.types';

interface ViewJobCandidateDetailsAssessmentMyNxmoovFormProps {
  onSave?: () => void;
  onCancel?: () => void;
  disableEdit?: boolean;
  saveButtonText?: string;
  withoutHeading?: boolean;
  cancelButtonText?: string;
  alignButtonsToRight?: boolean;
  buttonSize?: 'large' | 'medium' | 'small';
  jobCandidate: ViewJobCandidateDetailsAssessmentMyNxmoovFormJobCandidateType;
}

export default function ViewJobCandidateDetailsAssessmentMyNxmoovForm({
  onSave,
  onCancel,
  disableEdit,
  jobCandidate,
  withoutHeading,
  alignButtonsToRight,
  cancelButtonText = 'Cancel',
  saveButtonText = 'Save',
  buttonSize = 'large'
}: ViewJobCandidateDetailsAssessmentMyNxmoovFormProps) {
  const {
    updateJobCandidate,
    updateJobCandidateIsLoading,
    updateJobCandidateErrorMessage
  } = useUpdateJobCandidate({
    jobCandidateId: jobCandidate.id,
    cacheKeys: compact([
      JobCandidatesCache.showCacheKey(),
      jobCandidate.job
        ? WorkflowStagesCache.jobIndexCacheKey(jobCandidate.job.nanoId)
        : null
    ])
  });

  const {
    watch,
    control,
    validationErrors,
    handleEditJobCandidateMyNxmoovForm,
    setEditJobCandidateMyNxmoovFormValue,
    editJobCandidateMyNxmoovFormIsLoading,
    editJobCandidateMyNxmoovFormErrorMessage
  } = useEditJobCandidateMyNxmoovForm({
    defaultValues: jobCandidate
      ? {
          cityIds: jobCandidate.cityIds,
          maximumDesiredPay: jobCandidate.maximumDesiredPay,
          minimumDesiredPay: jobCandidate.minimumDesiredPay,
          paidTimeOff: jobCandidate.paidTimeOff,
          newJobStartAvailability: jobCandidate.newJobStartAvailability,
          employerCounterOffer: jobCandidate.employerCounterOffer,
          activelyInterviewing: jobCandidate.activelyInterviewing,
          workAuthorization: jobCandidate.workAuthorization,
          counterOffer: jobCandidate.counterOffer
        }
      : {},
    onEditJobCandidateMyNxmoovForm: async (data) => {
      const formattedData = {
        ...data,
        minimumDesiredPay:
          data.minimumDesiredPay &&
          FormatNumber.currencyToNumber(data.minimumDesiredPay),
        maximumDesiredPay:
          data.maximumDesiredPay &&
          FormatNumber.currencyToNumber(data.maximumDesiredPay)
      } as EditJobCandidateMyNxmoovFormData;

      await updateJobCandidate(formattedData);

      onSave?.();
    }
  });

  const isActivelyInterviewing =
    watch('activelyInterviewing') ===
    JobResumeRecruiterActivelyInterviewingOptions.YES;

  useEffect(() => {
    !isActivelyInterviewing &&
      setEditJobCandidateMyNxmoovFormValue('counterOffer', undefined);
  }, [isActivelyInterviewing, setEditJobCandidateMyNxmoovFormValue]);

  return (
    <Form onSubmit={handleEditJobCandidateMyNxmoovForm}>
      <Stack
        spacing={6}
        mb={{ base: 20, lg: 0 }}
        pointerEvents={disableEdit ? 'none' : 'all'}
      >
        {withoutHeading ? null : (
          <Heading level="h3" textAlign="center">
            My Nxmoov
          </Heading>
        )}

        <Stack spacing={6}>
          <SelectField
            isRequired
            control={control}
            label="Work authorization"
            placeholder="Work authorization"
            name={JobCandidateFields.WORK_AUTHORIZATION}
            options={workAuthorizationOptions}
            defaultValue={find(
              workAuthorizationOptions,
              (workAuthorizationOption) =>
                workAuthorizationOption.value ===
                jobCandidate?.workAuthorization
            )}
            errorMessage={validationErrors.workAuthorizationValidationError}
          />

          <SelectField
            isRequired
            control={control}
            menuPlacement="auto"
            isSearchable={false}
            placeholder="Select your availability"
            name={JobCandidateFields.NEW_JOB_START_AVAILABILITY}
            label="When would you be available to start a new position?"
            errorMessage={
              validationErrors.newJobStartAvailabilityValidationError
            }
            options={startAvailabilityOptions}
          />

          <Stack spacing={0}>
            <FormLabel display="flex">
              Do you anticipate a counter offer from your current employer?
            </FormLabel>

            <RadioGroupField
              spacing={4}
              size="small"
              fontSize="sm"
              control={control}
              stackDirection="row"
              name={JobCandidateFields.EMPLOYER_COUNTER_OFFER}
              errorMessage={
                validationErrors.employerCounterOfferValidationError
              }
              options={[
                {
                  value: 'yes',
                  label: 'Yes'
                },
                {
                  value: 'no',
                  label: 'No'
                }
              ]}
            />
          </Stack>

          <Stack spacing={0}>
            <FormLabel display="flex">
              Are you actively interviewing or have an offer in hand?
            </FormLabel>

            <RadioGroupField
              name={JobCandidateFields.ACTIVELY_INTERVIEWING}
              spacing={4}
              size="small"
              fontSize="sm"
              stackDirection="row"
              control={control}
              errorMessage={
                validationErrors.activelyInterviewingValidationError
              }
              options={[
                {
                  value: JobResumeRecruiterActivelyInterviewingOptions.YES,
                  label: 'Yes'
                },
                {
                  value: JobResumeRecruiterActivelyInterviewingOptions.NO,
                  label: 'No'
                }
              ]}
            />

            <Collapse in={isActivelyInterviewing} unmountOnExit>
              <Stack spacing={0} p={0.5} pt={6}>
                <SelectField
                  control={control}
                  menuPlacement="auto"
                  isSearchable={false}
                  placeholder="Have you received another offer?"
                  name={JobCandidateFields.COUNTER_OFFER}
                  label="Have you received another offer?"
                  errorMessage={validationErrors.counterOfferValidationError}
                  options={[
                    {
                      label: 'Yes',
                      value: JobResumeRecruiterCounterOfferOptions.YES
                    },
                    {
                      label: 'No',
                      value: JobResumeRecruiterCounterOfferOptions.NO
                    },
                    {
                      label: 'Not Anticipated',
                      value:
                        JobResumeRecruiterCounterOfferOptions.NOT_ANTIIPATED
                    },
                    {
                      label: 'Anticipated One Week',
                      value:
                        JobResumeRecruiterCounterOfferOptions.ANTICIPATED_ONE_WEEK
                    },
                    {
                      label: 'Anticipated Two Weeks',
                      value:
                        JobResumeRecruiterCounterOfferOptions.ANTICIPATED_TWO_WEEK
                    }
                  ]}
                />
              </Stack>
            </Collapse>
          </Stack>

          <SelectField
            isRequired
            control={control}
            isSearchable={false}
            label="Desired paid time off (PTO)"
            placeholder="Desired paid time off (PTO)"
            name={JobCandidateFields.PAID_TIME_OFF}
            options={paidTimeOffOptions}
            defaultValue={find(
              paidTimeOffOptions,
              (paidTimeOffOption) =>
                paidTimeOffOption.value === jobCandidate?.paidTimeOff
            )}
            errorMessage={validationErrors.paidTimeOffValidationError}
          />

          <Stack spacing={2}>
            <FormLabel display="flex" flex={1}>
              Annual base pay range
            </FormLabel>

            <Stack direction="row" spacing={4} alignItems="flex-start">
              <CleaveInputField
                control={control}
                leftElement="$"
                placeholder="Minimum"
                errorMessage={validationErrors.minimumDesiredPayValidationError}
                name={JobCandidateFields.MINIMUM_DESIRED_PAY}
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand'
                }}
              />

              <Text lineHeight={10} h={10}>
                to
              </Text>

              <CleaveInputField
                control={control}
                leftElement="$"
                placeholder="Maximum"
                errorMessage={validationErrors.maximumDesiredPayValidationError}
                name={JobCandidateFields.MAXIMUM_DESIRED_PAY}
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand'
                }}
              />
            </Stack>

            <Box>
              <Text color="gray.600" textStyle="body2Regular">
                This information will be used to help you match with relevant
                jobs.{' '}
                <Box as="span" color="gray.900" fontWeight="500">
                  It will not be shared with employers.
                </Box>
              </Text>
            </Box>
          </Stack>
        </Stack>

        <AlertMessage
          message={
            editJobCandidateMyNxmoovFormErrorMessage ||
            updateJobCandidateErrorMessage
          }
        />
      </Stack>

      {!disableEdit && (
        <ButtonGroup justifyContent="flex-end">
          {onCancel && (
            <Button
              size={buttonSize}
              w={alignButtonsToRight ? undefined : 'full'}
              hierarchy="secondary"
              onClick={onCancel}
              disabled={
                editJobCandidateMyNxmoovFormIsLoading ||
                updateJobCandidateIsLoading
              }
            >
              {cancelButtonText}
            </Button>
          )}

          <Button
            type="submit"
            size={buttonSize}
            w={alignButtonsToRight ? undefined : 'full'}
            isLoading={
              editJobCandidateMyNxmoovFormIsLoading ||
              updateJobCandidateIsLoading
            }
          >
            {saveButtonText}
          </Button>
        </ButtonGroup>
      )}
    </Form>
  );
}
