import { FieldValues } from 'react-hook-form';
import { SystemProps } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import size from 'lodash/size';

import { DEFAULT_COUNTRY_ID } from '../../../../../config';

import { INITIAL_PAGE_SIZE } from '../../../../../constants';

import { ErrorMessage, IsHidden } from '../../../../../types';

import {
  CityFields,
  CityId,
  CityLabel,
  FetchCitiesFilters,
  FetchCitiesPageSize,
  FetchCitiesSort
} from '../../../../cities/citiesTypes';

import {
  fetchCitiesQuery,
  FetchCitiesResponse
} from '../../../../cities/queries/fetchCities.query';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { usePaginatedCities } from '../../../../cities/hooks/usePaginatedCities';

import { CitiesCache } from '../../../../cities/CitiesCache';

import {
  SelectFieldReactHookFormControl,
  SelectFieldReactHookFormFieldPath
} from '../../../../../helpers/forms/formFields/SelectField';

import { MultiSelectField } from '../../../../../helpers/forms/formFields/MultiSelectField';

interface SelectCitiesFormFieldItem {
  city?: {
    id: CityId;
    label: CityLabel;
  } | null;
  cities:
    | {
        id: CityId;
        label: CityLabel;
      }[]
    | null;
}

interface SelectCitiesProps<T extends FieldValues> {
  control: SelectFieldReactHookFormControl<T>;
  errorMessage: ErrorMessage;
  isRequired?: boolean;
  visibility?: SystemProps['visibility'];
  isLabelHidden?: IsHidden;
  useCurrentUserCity?: boolean;
  label?: string;
  placeholder?: string;
  item?: SelectCitiesFormFieldItem;
  name?: SelectFieldReactHookFormFieldPath<T>;
}

function SelectCities<T extends FieldValues>({
  control,
  item,
  errorMessage,
  isRequired = true,
  visibility,
  isLabelHidden,
  useCurrentUserCity,
  label = 'Location(s)',
  placeholder = 'Select...',
  name = 'cityIds' as SelectFieldReactHookFormFieldPath<T>
}: SelectCitiesProps<T>) {
  const currentUser = useCurrentUser();

  const { cities, citiesIsLoading, citiesErrorMessage, changeCitiesFilters } =
    usePaginatedCities<FetchCitiesResponse>({
      query: fetchCitiesQuery,
      cacheKey: CitiesCache.postJobCacheKey(),
      initialPageSize: INITIAL_PAGE_SIZE as FetchCitiesPageSize,
      initialFilters: {
        [CityFields.COUNTRY_ID]: {
          operator: 'eq',
          value: currentUser.countryId || DEFAULT_COUNTRY_ID
        },
        [CityFields.LABEL]: {
          operator: 'ilike',
          value: `%${item?.city?.label || currentUser.city?.label}%`
        }
      } as unknown as FetchCitiesFilters,
      initialSort: {
        [CityFields.NAME]: { ascending: true }
      } as unknown as FetchCitiesSort
    });

  const defaultItemCities = () => {
    if (size(item?.cities) && item?.cities) {
      return item.cities.map((city) => ({
        value: city.id,
        label: city.label
      }));
    }

    if (useCurrentUserCity && currentUser.city) {
      return [{ value: currentUser.city.id, label: currentUser.city.label }];
    }

    return undefined;
  };

  const debouncedFilterCities = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeCitiesFilters({
        [CityFields.LABEL]: {
          operator: 'ilike',
          value: `%${updatedValue}%`
        }
      }),
    500
  );

  return (
    <MultiSelectField
      isRequired={isRequired}
      control={control}
      label={isLabelHidden ? undefined : label}
      placeholder={placeholder}
      name={name}
      options={cities.map((city) => ({
        value: city.id,
        label: city.label
      }))}
      defaultValue={defaultItemCities()}
      errorMessage={errorMessage || citiesErrorMessage}
      isLoading={citiesIsLoading}
      onInputChange={debouncedFilterCities}
      visibility={visibility}
    />
  );
}

export default SelectCities;
