const nameRules = {
  required: 'Name is required'
};

const applicationRules = {
  required: {
    value: true,
    message: 'Activity type is required'
  },
  min: {
    value: 1,
    message: 'Activity type is required'
  }
};

const dueDateRules = {
  required: {
    value: true,
    message: 'Due date is required'
  },
  min: {
    value: 1,
    message: 'Due date is required'
  }
};

const automationRules = {
  required: 'Automation is required'
};

const reminderRules = {
  required: 'Reminder is required'
};

function useAddWorkflowStageTaskFormValidationRules() {
  return {
    nameRules,
    dueDateRules,
    reminderRules,
    automationRules,
    applicationRules
  };
}

export default useAddWorkflowStageTaskFormValidationRules;
