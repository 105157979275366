import { JobCandidateStatuses } from '../../../../../../../../../../../jobCandidates/jobCandidatesTypes';

const statusRules = {
  required: 'Status is required',
  validate: {
    isValid: (value: string) =>
      value === JobCandidateStatuses.NONE ? 'Status is required' : undefined
  }
};

const employmentTypesRules = {
  required: 'Employment types are required'
};

const workplaceTypesRules = {
  required: 'Workplace types are required'
};

const cityIdsRules = {
  required: 'At least one location is required'
};

const employmentStatusRules = {
  required: 'Employment status is required'
};

const phoneCallAvailabilityRules = {
  required: 'Phone call availability is required'
};

function useEditJobCandidatePreferencesFormValidationRules() {
  return {
    statusRules,
    employmentTypesRules,
    workplaceTypesRules,
    cityIdsRules,
    employmentStatusRules,
    phoneCallAvailabilityRules
  };
}

export default useEditJobCandidatePreferencesFormValidationRules;
