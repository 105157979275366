import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  StackDivider,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react';
import { Button } from '../../../../../../helpers/Button';
import { IconButton } from '../../../../../../helpers/buttons/IconButton';
import { PureButtonWithRefHelper } from '../../../../../../helpers/buttons/PureButtonWithRefHelper';
import { LoadingSkeleton } from '../../../../../../helpers/LoadingSkeleton';
import { NextLinkHelper } from '../../../../../../helpers/NextLinkHelper';
import { RelativeDateHelper } from '../../../../../../helpers/RelativeDateHelper';
import { Text } from '../../../../../../helpers/Text';
import { ChevronDownIcon } from '../../../../../../icons/ChevronDownIcon';
import { ClockIcon } from '../../../../../../icons/ClockIcon';
import { EmailIcon } from '../../../../../../icons/EmailIcon';
import { JobsIcon } from '../../../../../../icons/JobsIcon';
import { LinkedInIcon } from '../../../../../../icons/LinkedInIcon';
import { MessageIcon } from '../../../../../../icons/MessageIcon';
import { PhoneIcon } from '../../../../../../icons/PhoneIcon';
import { IsFetched } from '../../../../../../types';
import { HiringPortalJobCandidateDetailsRoutes } from '../../../../../hiringPortal/HiringPortalJobCandidateDetailsRoutes';
import { PipelineKanbanRoutes } from '../../../../../pipeline/PipelineKanbanRoutes';
import { JobPriorityDropdown } from '../../../../../updates/components/JobPriorityDropdown';
import { ViewJobCandidateModalPhoneSidebarHelper } from '../../../../components/modals/ViewJobCandidateModal/helpers/ViewJobCandidateModalPhoneSidebarHelper';
import { JobLocationHelper } from '../../../../helpers/JobLocationHelper';
import { JobNanoId } from '../../../../jobsTypes';
import { renderJobEmployment } from '../../../../utils/renderJobEmployment';
import { useViewJobCandidateDetailsSendAssessmentContext } from '../../contexts/ViewJobCandidateDetailsSendAssessmentContext';
import { useViewJobCandidateDetailsSubmissionContext } from '../../contexts/ViewJobCandidateDetailsSubmissionContext';
import { JobCandidateDetailsJobCandidate } from '../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsAddComment } from '../ViewJobCandidateDetailsAddComment';
import { ViewJobCandidateDetailsBio } from '../ViewJobCandidateDetailsBio';
import { ViewJobCandidateDetailsComposeEmail } from '../ViewJobCandidateDetailsComposeEmail';
import { ViewJobCandidateDetailsHeaderMenuJobs } from '../ViewJobCandidateDetailsHeaderMenuJobs';
import { ViewJobCandidateDetailsJobDetailsJob } from '../ViewJobCandidateDetailsJobDetails';
import { ViewJobCandidateDetailsJobHiringTeam } from '../ViewJobCandidateDetailsJobHiringTeam';

interface ViewJobCandidateDetailsHeaderMenuProps {
  jobCandidate: JobCandidateDetailsJobCandidate;
  hasScrolled: boolean;
  requiresCredit: boolean;
  job: ViewJobCandidateDetailsJobDetailsJob;
  jobIsFetched: IsFetched;
  isHiringPortalPath: boolean;
  jobRoute: (jobNanoId: JobNanoId) => string;
}

export default function ViewJobCandidateDetailsHeaderMenu({
  job,
  hasScrolled,
  jobCandidate,
  requiresCredit,
  jobIsFetched,
  isHiringPortalPath,
  jobRoute
}: ViewJobCandidateDetailsHeaderMenuProps) {
  const { isCandidateSubmissionOpen, isCandidateEvaluationOpen } =
    useViewJobCandidateDetailsSubmissionContext();

  const { isSendEmailAssessmentOpen } =
    useViewJobCandidateDetailsSendAssessmentContext();

  const isRightSidebarOpen =
    isCandidateSubmissionOpen ||
    isSendEmailAssessmentOpen ||
    isCandidateEvaluationOpen;

  const {
    isOpen: isOpenPhoneCallActivity,
    onOpen: onOpenPhoneCallActivity,
    onToggle: onTogglePhoneCallActivity
  } = useDisclosure();

  return (
    <>
      <Flex
        h={14}
        top={0}
        gap={6}
        zIndex={99}
        overflow="hidden"
        position={isRightSidebarOpen ? 'relative' : 'sticky'}
        bg={isRightSidebarOpen ? 'transparent' : 'white'}
        pl={{
          base: 4,
          xl: isRightSidebarOpen ? 6 : 12
        }}
        pr={isRightSidebarOpen ? 6 : isHiringPortalPath ? 12 : 4}
      >
        <Flex
          h={28}
          top={0}
          flex={1}
          w="100%"
          flexDir="column"
          position="relative"
          transitionDuration="slow"
          transform={hasScrolled ? 'translate(0, -50%)' : undefined}
        >
          <Flex
            gap={4}
            opacity={hasScrolled ? 0 : 1}
            w="100%"
            flex={1}
            alignItems="center"
            flexWrap="nowrap"
          >
            <LoadingSkeleton count={1} loaded={jobIsFetched}>
              {job ? (
                <HStack
                  spacing={4}
                  divider={<StackDivider />}
                  flexWrap="nowrap"
                >
                  <ViewJobCandidateDetailsHeaderMenuJobs
                    jobCandidate={jobCandidate}
                    job={job}
                    jobRoute={jobRoute}
                  />

                  <Flex gap={2} alignItems="center" shrink={0}>
                    <Text>Job Priority</Text>
                    <JobPriorityDropdown priority="high" />
                  </Flex>

                  <JobLocationHelper job={job} withIcon />

                  <HStack spacing={2} sx={{ flexShrink: '0' }}>
                    <JobsIcon w="20px" h="20px" color="gray.500" />

                    <Text>{renderJobEmployment(job.employment) || '-'}</Text>
                  </HStack>

                  <HStack spacing={2} sx={{ flexShrink: '0' }}>
                    <ClockIcon w="20px" h="20px" color="gray.500" />

                    <Text>
                      <RelativeDateHelper date={job.createdAt as string} />
                    </Text>
                  </HStack>

                  <NextLinkHelper
                    href={
                      isHiringPortalPath
                        ? HiringPortalJobCandidateDetailsRoutes.viewJobDetails(
                            job.nanoId,
                            jobCandidate.nanoId
                          )
                        : PipelineKanbanRoutes.viewJobDetails(
                            job.nanoId,
                            jobCandidate.nanoId
                          )
                    }
                    passHref
                  >
                    <PureButtonWithRefHelper
                      as="a"
                      size="medium"
                      hierarchy="link"
                      i18nText="Job Details"
                      _hover={{ textDecoration: 'none' }}
                      sx={{ flexShrink: '0' }}
                    />
                  </NextLinkHelper>

                  <ViewJobCandidateDetailsJobHiringTeam job={job} />
                </HStack>
              ) : null}
            </LoadingSkeleton>
          </Flex>

          <Flex
            h={14}
            gap={2}
            flex={1}
            w="100%"
            alignItems="center"
            top={hasScrolled ? 0 : '100%'}
            transitionDuration="slow"
            zIndex={999}
          >
            <HStack spacing={4} divider={<StackDivider />}>
              <HStack spacing={2}>
                {jobCandidate && job ? (
                  <ViewJobCandidateDetailsBio
                    requiresCredit={requiresCredit}
                    jobCandidate={jobCandidate}
                    isCollapsed
                    job={job}
                    isHiringPortalPath={isHiringPortalPath}
                  />
                ) : null}

                <Flex
                  w={6}
                  h={6}
                  bg="#069"
                  alignItems="center"
                  borderRadius="full"
                  justifyContent="center"
                >
                  <LinkedInIcon borderRadius="full" w={3.5} h={3.5} />
                </Flex>
              </HStack>

              {job ? (
                <HStack spacing={4}>
                  <ViewJobCandidateDetailsHeaderMenuJobs
                    jobCandidate={jobCandidate}
                    job={job}
                    jobRoute={jobRoute}
                  />

                  <JobLocationHelper job={job} withIcon />
                </HStack>
              ) : null}
            </HStack>

            <Flex gap={3} alignItems="center" ml="auto">
              <ViewJobCandidateDetailsComposeEmail
                trigger={
                  <Tooltip label="Email" textTransform="capitalize" hasArrow>
                    <IconButton
                      h={6}
                      w={6}
                      minW={0}
                      minH={0}
                      value="email"
                      display="flex"
                      aria-label=""
                      alignItems="center"
                      borderRadius="full"
                      justifyContent="center"
                      icon={<EmailIcon w={3.5} h={3.5} />}
                      hierarchy="tertiary"
                    />
                  </Tooltip>
                }
              />

              <ViewJobCandidateDetailsAddComment
                trigger={
                  <Tooltip label="Comment" textTransform="capitalize" hasArrow>
                    <IconButton
                      h={6}
                      w={6}
                      minW={0}
                      minH={0}
                      value="comment"
                      display="flex"
                      aria-label=""
                      alignItems="center"
                      borderRadius="full"
                      justifyContent="center"
                      icon={<MessageIcon w={3.5} h={3.5} />}
                      hierarchy="tertiary"
                    />
                  </Tooltip>
                }
              />

              <HStack
                spacing={0}
                overflow="hidden"
                border="1px solid"
                borderColor="gray.300"
                borderRadius="base"
                divider={<StackDivider borderColor="gray.200" />}
              >
                <Button
                  h={7}
                  px={4}
                  hierarchy="tertiary"
                  size="extra-small"
                  borderRadius="none"
                  border="none"
                  onClick={onOpenPhoneCallActivity}
                  leftIcon={<PhoneIcon w={3} h={3} fill="gray.500" />}
                >
                  Call
                </Button>

                <IconButton
                  h={7}
                  border="none"
                  display="flex"
                  aria-label=""
                  size="extra-small"
                  alignItems="center"
                  borderRadius="none"
                  hierarchy="tertiary"
                  justifyContent="center"
                  icon={<ChevronDownIcon w={3.5} h={3.5} fill="gray.500" />}
                />
              </HStack>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Drawer
        size="md"
        isOpen={isOpenPhoneCallActivity}
        onClose={onTogglePhoneCallActivity}
      >
        <DrawerOverlay />
        <DrawerContent p={0}>
          <DrawerBody p={0}>
            {jobCandidate ? (
              <>
                <ViewJobCandidateModalPhoneSidebarHelper
                  fullWidth
                  withoutBorder
                  jobCandidate={jobCandidate}
                  toggleFollowUpEmail={console.log}
                  isSidebarOpen={isOpenPhoneCallActivity}
                  sidebarToggle={onTogglePhoneCallActivity}
                  personalPhoneNumber={jobCandidate.phone}
                  companyPhoneNumber={jobCandidate.companyPhone}
                />
              </>
            ) : null}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
