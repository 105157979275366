import {
  FetchJobCandidatesCacheKey,
  FetchJobCandidateCacheKey,
  UpdateJobCandidateOwnerErrorMessage,
  UpdateJobCandidateOwnerIsLoading,
  UpdateJobCandidateOwnerNanoId,
  UpdateJobCandidateOwnerID
} from '../../jobCandidatesTypes';

import { usePostItem } from '../../../common/hooks/usePostItem';

import {
  JobCandidatesBffRequests,
  UpdateJobCandidateOwnerRequestData,
  UpdateJobCandidateOwnerErrorResponse
} from '../../JobCandidatesBffRequests';

interface UpdateJobCandidateOwnerOptions {
  jobCandidateNanoId: UpdateJobCandidateOwnerNanoId | UpdateJobCandidateOwnerID;
  cacheKeys?: (FetchJobCandidatesCacheKey | FetchJobCandidateCacheKey)[];
}

function useUpdateJobCandidateOwner({
  jobCandidateNanoId,
  cacheKeys = []
}: UpdateJobCandidateOwnerOptions) {
  const {
    postItemData,
    postItemError,
    postItemErrorMessage,
    postItemIsLoading,
    postItem,
    postItemReset
  } = usePostItem<
    UpdateJobCandidateOwnerRequestData,
    UpdateJobCandidateOwnerErrorResponse
  >({
    mutationFn: (queryInput) =>
      JobCandidatesBffRequests.updateJobCandidateOwner(
        jobCandidateNanoId,
        queryInput
      ),
    cacheKeys
  });

  return {
    updateJobCandidateOwnerData: postItemData,
    updateJobCandidateOwnerError: postItemError,
    updateJobCandidateOwnerErrorMessage:
      postItemErrorMessage as UpdateJobCandidateOwnerErrorMessage,
    updateJobCandidateOwnerIsLoading:
      postItemIsLoading as UpdateJobCandidateOwnerIsLoading,
    updateJobCandidateOwner: postItem,
    updateJobCandidateOwnerReset: postItemReset
  };
}

export default useUpdateJobCandidateOwner;
