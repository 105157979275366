import { Collapse, Flex, useDisclosure } from '@chakra-ui/react';
import { Text } from '../../../../../../../../helpers/Text';
import { ViewJobCandidateDetailsProfileOverviewJobFitScorecardField } from '../ViewJobCandidateDetailsProfileOverviewJobFitScorecard/ViewJobCandidateDetailsProfileOverviewJobFitScorecard';

interface ViewJobCandidateDetailsProfileOverviewJobFitScorecardItemProps {
  field: ViewJobCandidateDetailsProfileOverviewJobFitScorecardField;
}

function ViewJobCandidateDetailsProfileOverviewJobFitScorecardItem({
  field
}: ViewJobCandidateDetailsProfileOverviewJobFitScorecardItemProps) {
  const {
    isOpen: isHovered,
    onOpen: onMouseHover,
    onClose: onMouseLeave
  } = useDisclosure();

  const { label, value, color, subfields } = field;

  return (
    <Flex
      flexDir="column"
      onMouseOver={onMouseHover}
      onMouseLeave={onMouseLeave}
    >
      <Flex gap={2} alignItems="center">
        <Text w={24} textStyle="body1Regular">
          {label}
        </Text>

        <Flex h={2} flex={1} bg="gray.100" rounded="sm">
          <Flex h={2} bg={color} w={`${(value / 15) * 100}%`} rounded="sm" />
        </Flex>

        <Text w={8} textAlign="right" textStyle="body2Medium">
          {value}/15
        </Text>
      </Flex>

      <Collapse in={isHovered}>
        <Flex flexDir="column" ml={2}>
          {subfields.map((item) => (
            <Flex gap={2} alignItems="center" key={item.label}>
              <Text
                w={24}
                noOfLines={1}
                textAlign="right"
                textStyle="body1Regular"
              >
                {item.label}
              </Text>

              <Flex h={2} flex={1} bg="gray.100" rounded="sm">
                <Flex
                  h={2}
                  bg={item.color}
                  w={`${(item.value / 15) * 100}%`}
                  rounded="sm"
                />
              </Flex>

              <Text w={8} textAlign="right" textStyle="body2Medium">
                {item.value}/{value}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Collapse>
    </Flex>
  );
}

export default ViewJobCandidateDetailsProfileOverviewJobFitScorecardItem;
