import { Icon } from '@chakra-ui/icon';

const NoScorecardIcon = () => (
  <Icon width="196" height="207" fill="none" viewBox="0 0 196 207">
    <path
      fill="#F4F6F7"
      d="M187 95c0 25.717-10.467 48.898-27.251 65.559C143.325 177.039 120.586 187 95.5 187c-24.905 0-47.645-10.142-64.249-26.441C14.468 143.898 4 120.717 4 95 4 44.11 44.968 3 95.5 3 146.033 3 187 44.291 187 95"
    ></path>
    <path
      fill="#E1EAED"
      d="M172.368 39.933a6.82 6.82 0 0 0 6.82-6.821 6.82 6.82 0 1 0-13.641 0 6.82 6.82 0 0 0 6.821 6.82M182.346 13.316a4.658 4.658 0 1 0 0-9.317 4.658 4.658 0 0 0 0 9.317M13.658 39.765a4.658 4.658 0 1 0 0-9.316 4.658 4.658 0 0 0 0 9.316M28.467 171.024a8.65 8.65 0 1 0 0-17.301 8.65 8.65 0 0 0 0 17.301"
    ></path>
    <g filter="url(#filter0_d_20243_220245)">
      <path
        fill="url(#paint0_linear_20243_220245)"
        d="M174 46.88v92.239c0 3.84-3.06 6.881-6.81 6.881H28.86c-3.75 0-6.86-3.091-6.86-6.881V46.881C22 43.09 25.06 40 28.86 40h138.33c3.75 0 6.81 3.091 6.81 6.88"
      ></path>
    </g>
    <path
      fill="#fff"
      d="M34 70v4a2 2 0 0 0 2 2h124a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2H36a2 2 0 0 0-2 2"
    ></path>
    <path
      fill="url(#paint1_linear_20243_220245)"
      d="M34 70v4a2 2 0 0 0 2 2h56a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2H36a2 2 0 0 0-2 2"
    ></path>
    <path
      fill="#fff"
      d="M34 90v4a2 2 0 0 0 2 2h124a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2H36a2 2 0 0 0-2 2"
    ></path>
    <path
      fill="url(#paint2_linear_20243_220245)"
      d="M34 90v4a2 2 0 0 0 2 2h96a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2H36a2 2 0 0 0-2 2"
    ></path>
    <path
      fill="#fff"
      d="M34 110v4a2 2 0 0 0 2 2h124a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2H36a2 2 0 0 0-2 2"
    ></path>
    <path
      fill="url(#paint3_linear_20243_220245)"
      d="M34 110v4a2 2 0 0 0 2 2h69a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2H36a2 2 0 0 0-2 2"
    ></path>
    <path
      fill="#fff"
      d="M34 130v4a2 2 0 0 0 2 2h124a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2H36a2 2 0 0 0-2 2"
    ></path>
    <path
      fill="url(#paint4_linear_20243_220245)"
      d="M34 130v4a2 2 0 0 0 2 2h58a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2H36a2 2 0 0 0-2 2"
    ></path>
    <path
      fill="#DAE1E3"
      d="M174 47.2V58H22V47.2c0-3.965 3.06-7.2 6.86-7.2h138.33c3.75 0 6.81 3.235 6.81 7.2"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_20243_220245"
        x1="97.951"
        x2="97.951"
        y1="37.548"
        y2="147.143"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ECF0F5"></stop>
        <stop offset="0.996" stopColor="#ECF0F5"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_20243_220245"
        x1="62.928"
        x2="62.947"
        y1="83.312"
        y2="62.145"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BED1D7" stopOpacity="0"></stop>
        <stop offset="1" stopColor="#78868A"></stop>
      </linearGradient>
      <linearGradient
        id="paint2_linear_20243_220245"
        x1="82.213"
        x2="82.224"
        y1="103.312"
        y2="82.145"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BED1D7" stopOpacity="0"></stop>
        <stop offset="1" stopColor="#78868A"></stop>
      </linearGradient>
      <linearGradient
        id="paint3_linear_20243_220245"
        x1="69.195"
        x2="69.211"
        y1="123.312"
        y2="102.145"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BED1D7" stopOpacity="0"></stop>
        <stop offset="1" stopColor="#78868A"></stop>
      </linearGradient>
      <linearGradient
        id="paint4_linear_20243_220245"
        x1="63.892"
        x2="63.911"
        y1="143.312"
        y2="122.145"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BED1D7" stopOpacity="0"></stop>
        <stop offset="1" stopColor="#78868A"></stop>
      </linearGradient>
      <filter
        id="filter0_d_20243_220245"
        width="196"
        height="150"
        x="0"
        y="29"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="11"></feOffset>
        <feGaussianBlur stdDeviation="11"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_20243_220245"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_20243_220245"
          result="shape"
        ></feBlend>
      </filter>
    </defs>
  </Icon>
);

export default NoScorecardIcon;
