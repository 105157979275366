import { MayBeSkillBadgeEmploymentSkillBadges } from '../../../skillBadges/skillBadgesTypes';

const renderMostRecentJob = (
  employmentHistory?: MayBeSkillBadgeEmploymentSkillBadges
) => {
  // Find the current job if it exists
  const currentJob = employmentHistory?.find(
    (job) => job.employment?.currently
  );
  if (currentJob) return currentJob;

  return employmentHistory?.reduce((latest, job) => {
    if (!latest) return job;

    const currentDate = new Date(job.employment.endDate || 0);
    const latestDate = new Date(latest.employment.endDate || 0);

    return currentDate > latestDate ? job : latest;
  }, employmentHistory[0]);
};

export default renderMostRecentJob;
