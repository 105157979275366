import {
  ReactHookFormFieldError,
  useReactHookForm
} from '../../../../../../../../../../../../common/hooks/base/useReactHookForm';
import {
  JobCandidateActivelyInterviewing,
  JobCandidateCityIds,
  JobCandidateCounterOffer,
  JobCandidateEmployerCounterOffer,
  JobCandidateFields,
  JobCandidateNewJobStartAvailability,
  JobCandidatePaidTimeOff,
  JobCandidateWorkAuthorization,
  MayBeJobCandidateMaximumDesiredPay,
  MayBeJobCandidateMinimumDesiredPay
} from '../../../../../../../../../../../jobCandidates/jobCandidatesTypes';
import {
  EditJobCandidateMyNxmoovFormData,
  OnEditJobCandidateMyNxmoovForm
} from '../../ViewJobCandidateDetailsAssessmentMyNxmoovForm.types';
import { useEditJobCandidateMyNxmoovFormValidationRules } from '../useEditJobCandidateMyNxmoovFormValidationRules';

const defaultEditJobCandidateMyNxmoovFormValues: EditJobCandidateMyNxmoovFormData =
  {
    paidTimeOff: '' as JobCandidatePaidTimeOff,
    minimumDesiredPay: '' as MayBeJobCandidateMinimumDesiredPay,
    maximumDesiredPay: '' as MayBeJobCandidateMaximumDesiredPay,
    newJobStartAvailability: '' as JobCandidateNewJobStartAvailability,
    employerCounterOffer: '' as JobCandidateEmployerCounterOffer,
    activelyInterviewing: '' as JobCandidateActivelyInterviewing,
    workAuthorization: '' as JobCandidateWorkAuthorization,
    counterOffer: '' as JobCandidateCounterOffer,
    cityIds: [] as JobCandidateCityIds
  };

interface EditJobCandidateMyNxmoovFormOptions {
  defaultValues?: Partial<EditJobCandidateMyNxmoovFormData>;
  onEditJobCandidateMyNxmoovForm: OnEditJobCandidateMyNxmoovForm;
}

function useEditJobCandidateMyNxmoovForm({
  defaultValues = {},
  onEditJobCandidateMyNxmoovForm
}: EditJobCandidateMyNxmoovFormOptions) {
  const {
    control,
    watch,
    errors,
    isLoading,
    isValid,
    isSubmitted,
    isSubmitSuccessful,
    errorMessage,
    register,
    resetForm,
    handleSubmitReactHookForm,
    setValue
  } = useReactHookForm<EditJobCandidateMyNxmoovFormData>({
    defaultValues: {
      ...defaultEditJobCandidateMyNxmoovFormValues,
      ...defaultValues
    }
  });

  const {
    paidTimeOffRules,
    workAuthorizationRules,
    minimumDesiredPayRules,
    maximumDesiredPayRules,
    newJobStartAvailabilityRules,
    activelyInterviewingRules
  } = useEditJobCandidateMyNxmoovFormValidationRules();

  return {
    control,
    watch,
    validationErrors: {
      paidTimeOffValidationError: (
        errors.paidTimeOff as ReactHookFormFieldError
      )?.message,
      minimumDesiredPayValidationError: (
        errors.minimumDesiredPay as ReactHookFormFieldError
      )?.message,
      maximumDesiredPayValidationError: (
        errors.maximumDesiredPay as ReactHookFormFieldError
      )?.message,
      newJobStartAvailabilityValidationError: (
        errors.newJobStartAvailability as ReactHookFormFieldError
      )?.message,
      employerCounterOfferValidationError: (
        errors.employerCounterOffer as ReactHookFormFieldError
      )?.message,
      workAuthorizationValidationError: (
        errors.workAuthorization as ReactHookFormFieldError
      )?.message,
      activelyInterviewingValidationError: (
        errors.activelyInterviewing as ReactHookFormFieldError
      )?.message,
      counterOfferValidationError: (
        errors.counterOffer as ReactHookFormFieldError
      )?.message
    },
    editJobCandidateMyNxmoovFormIsLoading: isLoading,
    editJobCandidateMyNxmoovFormIsValid: isValid,
    editJobCandidateMyNxmoovFormIsSubmitted: isSubmitted,
    editJobCandidateMyNxmoovFormIsSubmitSuccessful: isSubmitSuccessful,
    editJobCandidateMyNxmoovFormErrorMessage: errorMessage,
    resetEditJobCandidateMyNxmoovForm: resetForm,
    setEditJobCandidateMyNxmoovFormValue: setValue,
    registerFields: {
      registerPaidTimeOff: register(
        JobCandidateFields.PAID_TIME_OFF,
        paidTimeOffRules
      ),
      registerWorkAuthorization: register(
        JobCandidateFields.WORK_AUTHORIZATION,
        workAuthorizationRules
      ),
      registerMinimumDesiredPay: register(
        JobCandidateFields.MINIMUM_DESIRED_PAY,
        minimumDesiredPayRules
      ),
      registerMaximumDesiredPay: register(
        JobCandidateFields.MAXIMUM_DESIRED_PAY,
        maximumDesiredPayRules
      ),
      registerNewJobStartAvailability: register(
        JobCandidateFields.NEW_JOB_START_AVAILABILITY,
        newJobStartAvailabilityRules
      ),
      registerActivelyInterviewing: register(
        JobCandidateFields.ACTIVELY_INTERVIEWING,
        activelyInterviewingRules
      ),
      registerCounterOffer: register(JobCandidateFields.COUNTER_OFFER)
    },
    handleEditJobCandidateMyNxmoovForm: handleSubmitReactHookForm({
      onSubmit: (data) =>
        onEditJobCandidateMyNxmoovForm?.({
          cityIds: data.cityIds as JobCandidateCityIds,
          paidTimeOff: data.paidTimeOff as JobCandidatePaidTimeOff,
          workAuthorization:
            data.workAuthorization as JobCandidateWorkAuthorization,
          minimumDesiredPay:
            data.minimumDesiredPay as MayBeJobCandidateMinimumDesiredPay,
          maximumDesiredPay:
            data.maximumDesiredPay as MayBeJobCandidateMaximumDesiredPay,
          newJobStartAvailability:
            data.newJobStartAvailability as JobCandidateNewJobStartAvailability,
          employerCounterOffer:
            data.employerCounterOffer as JobCandidateEmployerCounterOffer,
          activelyInterviewing:
            data.activelyInterviewing as JobCandidateActivelyInterviewing,
          counterOffer: data.counterOffer as JobCandidateCounterOffer
        })
    })
  };
}

export default useEditJobCandidateMyNxmoovForm;
