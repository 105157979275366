import {
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react';
import { Button } from '../../../../../../../../../../helpers/Button';
import { ViewJobCandidateDetailsAssessmentPreferences } from '../../../../../../pages/ViewJobCandidateDetailsAssessmentPage/components/ViewJobCandidateDetailsAssessmentPreferences';
import { ViewJobCandidateDetailsAssessmentPreferencesJobCandidateType } from '../../../../../../pages/ViewJobCandidateDetailsAssessmentPage/components/ViewJobCandidateDetailsAssessmentPreferences/ViewJobCandidateDetailsAssessmentPreferences.types';

interface EditJobCandidatePreferencesModalProps {
  jobCandidate: ViewJobCandidateDetailsAssessmentPreferencesJobCandidateType;
}

export default function EditJobCandidatePreferencesModal({
  jobCandidate
}: EditJobCandidatePreferencesModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button size="small" hierarchy="link" ml="auto" onClick={onOpen}>
        Edit
      </Button>

      <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <ViewJobCandidateDetailsAssessmentPreferences
            isOpenDetails
            onSave={onClose}
            onCancel={onClose}
            jobCandidate={jobCandidate}
            addButtonLabel="Add Preferences"
          />
        </ModalContent>
      </Modal>
    </>
  );
}
