import {
  Box,
  ButtonGroup,
  Collapse,
  Flex,
  FormLabel,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import { compact } from 'lodash';
import filter from 'lodash/filter';
import find from 'lodash/find';
import includes from 'lodash/includes';
import { useScrollToFirstError } from '../../../../../../../../../../common/hooks/utils/useScrollToFirstError';
import { AlertMessage } from '../../../../../../../../../../helpers/AlertMessage';
import { Button } from '../../../../../../../../../../helpers/Button';
import { DropdownSelect } from '../../../../../../../../../../helpers/DropdownSelect';
import { Form } from '../../../../../../../../../../helpers/Form';
import { MultiSelectField } from '../../../../../../../../../../helpers/forms/formFields/MultiSelectField';
import { RadioGroupField } from '../../../../../../../../../../helpers/forms/formFields/RadioGroupField';
import { SelectField } from '../../../../../../../../../../helpers/forms/formFields/SelectField';
import { Heading } from '../../../../../../../../../../helpers/Heading';
import { Text } from '../../../../../../../../../../helpers/Text';
import { SelectCitiesFormField } from '../../../../../../../../../common/components/formFields/SelectCitiesFormField';
import { useUpdateJobCandidate } from '../../../../../../../../../jobCandidates/hooks/useUpdateJobCandidate';
import { JobCandidatesCache } from '../../../../../../../../../jobCandidates/JobCandidatesCache';
import { JobCandidateFields } from '../../../../../../../../../jobCandidates/jobCandidatesTypes';
import {
  employmentTypesOptions,
  statusOptions,
  workplaceTypesOptions
} from '../../../../../../../../../jobResumeRecruiters/JobResumeRecruiters.data';
import { WorkflowStagesCache } from '../../../../../../../../../workflowStages/WorkflowStagesCache';
import { ViewJobCandidateDetailsAssessmentPreferencesJobCandidateType } from '../../ViewJobCandidateDetailsAssessmentPreferences.types';
import { useEditJobCandidatePreferencesForm } from './hooks/useEditJobCandidatePreferencesForm';

interface ViewJobCandidateDetailsAssessmentPreferencesFormProps {
  onSave?: () => void;
  onCancel?: () => void;
  disableEdit?: boolean;
  saveButtonText?: string;
  withoutHeading?: boolean;
  cancelButtonText?: string;
  alignButtonsToRight?: boolean;
  buttonSize?: 'large' | 'medium' | 'small';
  jobCandidate: ViewJobCandidateDetailsAssessmentPreferencesJobCandidateType;
}

export default function ViewJobCandidateDetailsAssessmentPreferencesForm({
  onSave,
  onCancel,
  disableEdit,
  jobCandidate,
  withoutHeading,
  alignButtonsToRight,
  cancelButtonText = 'Cancel',
  saveButtonText = 'Save',
  buttonSize = 'medium'
}: ViewJobCandidateDetailsAssessmentPreferencesFormProps) {
  const {
    updateJobCandidate,
    updateJobCandidateIsLoading,
    updateJobCandidateErrorMessage
  } = useUpdateJobCandidate({
    jobCandidateId: jobCandidate.id,
    cacheKeys: compact([
      JobCandidatesCache.showCacheKey(),
      jobCandidate.job
        ? WorkflowStagesCache.jobIndexCacheKey(jobCandidate.job.nanoId)
        : null
    ])
  });

  const {
    control,
    validationErrors,
    editJobCandidatePreferencesFormIsLoading,
    editJobCandidatePreferencesFormErrorMessage,
    handleEditJobCandidatePreferencesForm
  } = useEditJobCandidatePreferencesForm({
    defaultValues: jobCandidate
      ? {
          status: jobCandidate.status,
          cityIds: jobCandidate.cityIds,
          workplaceTypes: jobCandidate.workplaceTypes,
          employmentTypes: jobCandidate.employmentTypes,
          employmentStatus: jobCandidate.employmentStatus,
          phoneCallAvailability: jobCandidate.phoneCallAvailability
        }
      : {},
    onEditJobCandidatePreferencesForm: async (data) => {
      await updateJobCandidate(data);
      onSave?.();
    }
  });

  const { isOpen: isOpenChooseDate, onToggle: onToggleChooseDate } =
    useDisclosure();

  useScrollToFirstError(validationErrors);

  return (
    <Form onSubmit={handleEditJobCandidatePreferencesForm}>
      <Stack
        spacing={6}
        mb={{ base: '20 !important', lg: '0 !important' }}
        pointerEvents={disableEdit ? 'none' : 'all'}
      >
        {withoutHeading ? null : (
          <Heading level="h3" textAlign="center">
            Preferences
          </Heading>
        )}

        <Stack spacing={6}>
          <RadioGroupField
            name={JobCandidateFields.EMPLOYMENT_STATUS}
            spacing={4}
            size="small"
            fontSize="sm"
            label="Employment status"
            stackDirection="row"
            control={control}
            errorMessage={validationErrors.employmentStatusValidationError}
            options={[
              {
                value: 'employed',
                label: 'Employed'
              },
              {
                value: 'not-employed',
                label: 'Not Employed'
              },
              {
                value: 'student',
                label: 'Student'
              }
            ]}
          />

          <SelectField
            isRequired
            control={control}
            label="Job market status"
            placeholder="Job market status"
            name={JobCandidateFields.STATUS}
            options={statusOptions}
            defaultValue={find(
              statusOptions,
              (statusOption) => statusOption.value === jobCandidate?.status
            )}
            errorMessage={validationErrors.statusValidationError}
          />

          <MultiSelectField
            control={control}
            label="Employment types"
            placeholder="Employments types"
            name={JobCandidateFields.EMPLOYMENT_TYPES}
            options={employmentTypesOptions.map((employmentTypesOption) => ({
              value: employmentTypesOption.value,
              label: employmentTypesOption.label
            }))}
            defaultValue={filter(
              employmentTypesOptions,
              (employmentTypesOption) =>
                includes(
                  jobCandidate?.employmentTypes,
                  employmentTypesOption.value
                )
            )}
            errorMessage={validationErrors.employmentTypesValidationError}
          />

          {/*<MultiSelectFieldControl*/}
          {/*  label="Preferred locations"*/}
          {/*  options={[]}*/}
          {/*  onChange={() => console.log('change')}*/}
          {/*/>*/}

          <SelectCitiesFormField
            control={control}
            label="Preferred locations"
            placeholder="Preferred locations"
            item={jobCandidate}
            errorMessage={validationErrors.cityIdsValidationError}
          />

          <MultiSelectField
            control={control}
            label="Workplace types"
            placeholder="Workplace types"
            name={JobCandidateFields.WORKPLACE_TYPES}
            options={workplaceTypesOptions.map((workplaceTypesOption) => ({
              value: workplaceTypesOption.value,
              label: workplaceTypesOption.label
            }))}
            defaultValue={filter(
              workplaceTypesOptions,
              (workplaceTypesOption) =>
                includes(
                  jobCandidate?.workplaceTypes,
                  workplaceTypesOption.value
                )
            )}
            errorMessage={validationErrors.workplaceTypesValidationError}
          />

          <Stack spacing={0}>
            <FormLabel display="flex">Phone call availability</FormLabel>

            <Flex
              gap={0}
              flexDir="column"
              ml="auto"
              mr="auto"
              w="full"
              maxW="sm"
            >
              <Collapse in={!isOpenChooseDate} animateOpacity>
                <Box pb={4}>
                  <RadioGroupField
                    name={JobCandidateFields.PHONE_CALL_AVAILABILITY}
                    size="medium"
                    fontSize="sm"
                    stackDirection="column"
                    control={control}
                    spacing={4}
                    options={[
                      {
                        value: 'mornings',
                        label: '8 - 11 am - Mornings (Mon - Fri)'
                      },
                      {
                        value: 'afternoons',
                        label: '12 - 5 pm - Afternoons (Mon - Fri)'
                      },
                      {
                        value: 'after-work',
                        label: '5 - 7 pm - After work (Mon - Fri)'
                      }
                    ]}
                    errorMessage={
                      validationErrors.phoneCallAvailabilityValidationError
                    }
                  />
                </Box>
              </Collapse>

              <Flex
                gap={3}
                flexDir="row"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Button
                  size="medium"
                  color="primary.500"
                  hierarchy="link"
                  textDecor="underline"
                  fontWeight="medium"
                  onClick={onToggleChooseDate}
                >
                  {isOpenChooseDate && 'Close'} Custom time
                </Button>

                <Collapse in={isOpenChooseDate} animateOpacity unmountOnExit>
                  <Flex gap={6} py={2} px={0.5}>
                    <DropdownSelect
                      popoverWidth="32"
                      fontWeight="normal"
                      placeholder="Select time slot"
                      onOptionSelect={(option) =>
                        console.log('Selected:', option)
                      }
                      options={[
                        { label: '8 - 11 am', value: '8 - 11 am' },
                        { label: '12 - 5 pm', value: '12 - 5 pm' },
                        { label: '5 - 7 pm', value: '5 - 7 pm' }
                      ]}
                    />

                    <DropdownSelect
                      popoverWidth="32"
                      fontWeight="normal"
                      placeholder="Select a day"
                      onOptionSelect={(option) =>
                        console.log('Selected:', option)
                      }
                      options={[
                        { label: 'Monday', value: 'Monday' },
                        { label: 'Tuesday', value: 'Tuesday' },
                        { label: 'Wednesday', value: 'Wednesday' },
                        { label: 'Thursday', value: 'Thursday' },
                        { label: 'Friday', value: 'Friday' }
                      ]}
                    />
                  </Flex>
                </Collapse>
              </Flex>

              {isOpenChooseDate &&
                validationErrors.phoneCallAvailabilityValidationError && (
                  <Text color="red.500" wordBreak="break-all">
                    {validationErrors.phoneCallAvailabilityValidationError}
                  </Text>
                )}
            </Flex>
          </Stack>
        </Stack>

        <AlertMessage
          message={
            editJobCandidatePreferencesFormErrorMessage ||
            updateJobCandidateErrorMessage
          }
        />
      </Stack>

      {!disableEdit && (
        <ButtonGroup justifyContent="flex-end" spacing={2}>
          {onCancel && (
            <Button
              size={buttonSize}
              w={alignButtonsToRight ? undefined : 'full'}
              hierarchy="secondary"
              onClick={onCancel}
              disabled={
                editJobCandidatePreferencesFormIsLoading ||
                updateJobCandidateIsLoading
              }
            >
              {cancelButtonText}
            </Button>
          )}

          <Button
            type="submit"
            size={buttonSize}
            w={alignButtonsToRight ? undefined : 'full'}
            isLoading={
              editJobCandidatePreferencesFormIsLoading ||
              updateJobCandidateIsLoading
            }
          >
            {saveButtonText}
          </Button>
        </ButtonGroup>
      )}
    </Form>
  );
}
