import { Box, Collapse, Flex, Switch } from '@chakra-ui/react';
import startCase from 'lodash/startCase';
import { FunctionComponent, ReactNode } from 'react';
import { Button } from '../../../../../../../../helpers/Button';
import { Heading } from '../../../../../../../../helpers/Heading';
import { DropUpIcon } from '../../../../../../../../icons/DropUpIcon';
import { PlusIcon } from '../../../../../../../../icons/PlusIcon';

interface ViewJobCandidateDetailsAssessmentListItemProps {
  title: string;
  children: ReactNode;
  defaultChecked?: boolean;
  handleToggle?: () => void;
  toggleDetails?: () => void;
  icon: FunctionComponent;
  isOpenDetails: boolean;
  addButtonLabel?: string;
  isCompleted?: boolean;
}

export default function ViewJobCandidateDetailsAssessmentListItem({
  icon,
  title,
  children,
  isCompleted,
  handleToggle,
  addButtonLabel,
  toggleDetails,
  defaultChecked,
  isOpenDetails
}: ViewJobCandidateDetailsAssessmentListItemProps) {
  return (
    <Flex
      p={6}
      bg="white"
      pos="relative"
      flexDir="column"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="base"
    >
      <Flex gap={2} alignItems="center">
        <Flex
          h={8}
          w={8}
          color="gray.700"
          alignItems="center"
          borderRadius="full"
          justifyContent="center"
          transitionDuration="slow"
          transitionProperty="all"
          bg={!!handleToggle && !defaultChecked ? 'gray.50' : 'gray.100'}
        >
          <Flex
            w={4}
            h={4}
            as={icon}
            transitionDuration="slow"
            transitionProperty="all"
            color={!!handleToggle && !defaultChecked ? 'gray.500' : 'gray.700'}
            fill={!!handleToggle && !defaultChecked ? 'gray.500' : 'gray.700'}
          />
        </Flex>

        <Heading
          flex={1}
          level="h5"
          fontWeight="semibold"
          color={!!handleToggle && !defaultChecked ? 'gray.500' : 'gray.700'}
          transitionDuration="slow"
          transitionProperty="all"
        >
          {startCase(title)}
        </Heading>

        <Flex gap={4} alignItems="center">
          {!!handleToggle && (
            <Switch
              hidden
              size="md"
              onChange={handleToggle}
              defaultChecked={defaultChecked}
            />
          )}

          {addButtonLabel && !isCompleted ? (
            <>
              {!isOpenDetails && (
                <Button
                  size="extra-small"
                  hierarchy="secondary"
                  onClick={toggleDetails}
                >
                  <Flex gap={1} alignItems="center">
                    <PlusIcon w={4} h={4} />
                    <Box as="span">{addButtonLabel}</Box>
                  </Flex>
                </Button>
              )}
            </>
          ) : (
            <Button
              p={0}
              size="small"
              fontSize="sm"
              boxShadow="none"
              hierarchy="ghost"
              _hover={{ bg: 'transparent' }}
              onClick={toggleDetails}
              rightIcon={
                <DropUpIcon
                  transform="auto"
                  transitionDuration="slow"
                  transitionProperty="transform"
                  rotate={isOpenDetails ? '-180deg' : '0deg'}
                />
              }
            >
              {isOpenDetails ? 'View less' : 'Details'}
            </Button>
          )}
        </Flex>
      </Flex>

      <Collapse in={isOpenDetails} animateOpacity>
        <Box
          pt={4}
          mt={4}
          px={0.5}
          pos="relative"
          borderTop="1px solid"
          borderColor="gray.200"
        >
          {children}
        </Box>
      </Collapse>
    </Flex>
  );
}
