import { Box } from '@chakra-ui/react';
import { PreferencesIcon } from '../../../../../../../../icons/PreferencesIcon';
import { ViewJobCandidateDetailsAssessmentListItem } from '../ViewJobCandidateDetailsAssessmentListItem';
import { ViewJobCandidateDetailsAssessmentPreferencesForm } from './components/ViewJobCandidateDetailsAssessmentPreferencesForm';
import { ViewJobCandidateDetailsAssessmentPreferencesJobCandidateType } from './ViewJobCandidateDetailsAssessmentPreferences.types';

interface ViewJobCandidateDetailsAssessmentPreferencesProps {
  disabled?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
  isOpenDetails: boolean;
  defaultChecked?: boolean;
  handleToggle?: () => void;
  toggleDetails?: () => void;
  jobCandidate: ViewJobCandidateDetailsAssessmentPreferencesJobCandidateType;
  markCompleted?: () => void;
  addButtonLabel?: string;
  isCompleted?: boolean;
}

export default function ViewJobCandidateDetailsAssessmentPreferences({
  onSave,
  onCancel,
  disabled,
  isCompleted,
  handleToggle,
  jobCandidate,
  isOpenDetails,
  toggleDetails,
  addButtonLabel,
  defaultChecked
}: ViewJobCandidateDetailsAssessmentPreferencesProps) {
  return (
    <ViewJobCandidateDetailsAssessmentListItem
      icon={PreferencesIcon}
      handleToggle={handleToggle}
      isOpenDetails={isOpenDetails}
      toggleDetails={toggleDetails}
      defaultChecked={defaultChecked}
      title="Preferences"
      addButtonLabel={addButtonLabel}
      isCompleted={isCompleted}
    >
      <ViewJobCandidateDetailsAssessmentPreferencesForm
        onSave={onSave}
        onCancel={onCancel}
        buttonSize="medium"
        disableEdit={disabled}
        jobCandidate={jobCandidate}
        alignButtonsToRight
        withoutHeading
      />

      {disabled && (
        <Box
          w="full"
          h="full"
          top={0}
          left={0}
          pos="absolute"
          bg="transparent"
          cursor="not-allowed"
        />
      )}
    </ViewJobCandidateDetailsAssessmentListItem>
  );
}
