import {
  CancelJobCandidateWorkflowStageBffURL,
  CreateJobCandidateWorkflowStageBffURL,
  FetchJobCandidateWorkflowStageBffURL,
  FetchJobCandidateWorkflowStageByNanoIdBffURL,
  FetchJobCandidateWorkflowStagesBffURL,
  JobCandidateWorkflowStageItemBffID,
  JobCandidateWorkflowStageItemBffNanoID,
  UpdateJobCandidateWorkflowStageBffURL
} from './jobCandidateWorkflowStagesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class JobCandidateWorkflowStagesBffRoutes extends BaseBffRoutes {
  protected static route = 'jobCandidateWorkflowStages';

  static fetchJobCandidateWorkflowStagesRoute() {
    return this.fetchItemsRoute<FetchJobCandidateWorkflowStagesBffURL>();
  }

  static fetchJobCandidateWorkflowStagesIndexRoute() {
    return this.fetchItemsIndexRoute<FetchJobCandidateWorkflowStagesBffURL>();
  }

  static fetchJobCandidateWorkflowStageRoute(
    jobCandidateWorkflowStageItemBffID: JobCandidateWorkflowStageItemBffID
  ) {
    return this.fetchItemRoute<FetchJobCandidateWorkflowStageBffURL>(
      jobCandidateWorkflowStageItemBffID
    );
  }

  static fetchJobCandidateWorkflowStageIndexRoute(
    jobCandidateWorkflowStageItemBffNanoID: JobCandidateWorkflowStageItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchJobCandidateWorkflowStageBffURL>(
      jobCandidateWorkflowStageItemBffNanoID
    );
  }

  static fetchJobCandidateWorkflowStageByNanoIdRoute(
    jobCandidateWorkflowStageItemBffNanoID: JobCandidateWorkflowStageItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchJobCandidateWorkflowStageByNanoIdBffURL>(
      jobCandidateWorkflowStageItemBffNanoID
    );
  }

  static createJobCandidateWorkflowStageRoute() {
    return this.createItemRoute<CreateJobCandidateWorkflowStageBffURL>();
  }

  static updateJobCandidateWorkflowStageRoute(
    updateJobCandidateWorkflowStageItemBffID:
      | JobCandidateWorkflowStageItemBffNanoID
      | JobCandidateWorkflowStageItemBffID
  ) {
    return this.updateItemRoute<UpdateJobCandidateWorkflowStageBffURL>(
      updateJobCandidateWorkflowStageItemBffID
    );
  }

  static cancelJobCandidateWorkflowStageRoute(
    cancelJobCandidateWorkflowStageItemBffID: JobCandidateWorkflowStageItemBffNanoID
  ) {
    return this.postItemRoute<CancelJobCandidateWorkflowStageBffURL>(
      'cancel',
      cancelJobCandidateWorkflowStageItemBffID
    );
  }
}
