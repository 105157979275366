import { ButtonGroup } from '@chakra-ui/button';
import { Box, SimpleGrid, Stack, StackDivider } from '@chakra-ui/layout';
import { FormControl, FormLabel } from '@chakra-ui/react';
import first from 'lodash/first';
import last from 'lodash/last';
import split from 'lodash/split';
import { useCallback, useEffect, useState } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';

import {
  ContactCertifications,
  ContactCityId,
  ContactCountryId,
  ContactEducations,
  ContactEmployments,
  ContactFields,
  ContactFirstName,
  ContactId,
  ContactLastName,
  ContactName,
  ContactNanoId,
  ContactSkillIds,
  ContactStateId,
  ContactUserId,
  MayBeContactCity,
  MayBeContactCountry,
  MayBeContactImage,
  MayBeContactState
} from '../../../../../contactsTypes';

import { ContactsCache } from '../../../../../ContactsCache';

import { useUpdateContact } from '../../../../../hooks/useUpdateContact';
import { useAddEditContactForm } from '../../hooks/useAddEditContactForm';

import { AlertMessage } from '../../../../../../../helpers/AlertMessage';
import { Button } from '../../../../../../../helpers/Button';
import { PureSubmitButtonHelper } from '../../../../../../../helpers/buttons/PureSubmitButtonHelper';
import { Form } from '../../../../../../../helpers/Form';
import { InputField } from '../../../../../../../helpers/forms/formFields/InputField';
import { UploadImageField } from '../../../../../../../helpers/forms/formFields/UploadImageField';
import { Text } from '../../../../../../../helpers/Text';

import {
  AddEditContactActiveContactLink,
  AddEditContactFormData
} from '../../AddEditContactForm.types';

import { SelectField } from '../../../../../../../helpers/forms/formFields/SelectField';
import { AddEditUserFormData } from '../../../../../../users/components/forms/AddEditUserForm/AddEditUserForm.types';
import {
  UserCertifications,
  UserEmployments
} from '../../../../../../users/usersTypes';
import { AddEditContactFormAddEmailFields } from './components/AddEditContactFormAddEmailFields';
import { AddEditContactFormAddPhoneFields } from './components/AddEditContactFormAddPhoneFields';

import { SelectCityFormField } from '../../../../../../common/components/formFields/SelectCityFormField';
import { SelectCountryFormField } from '../../../../../../common/components/formFields/SelectCountryFormField';
import {
  SelectLocationFormField,
  SelectLocationFormFieldFieldValue
} from '../../../../../../common/components/formFields/SelectLocationFormField';
import { SelectStateFormField } from '../../../../../../common/components/formFields/SelectStateFormField';

import { WysiwygField } from '../../../../../../../helpers/forms/formFields/WysiwygField';
import { EditUserFormAddCertificationFields } from '../../../../../../users/components/forms/AddEditUserForm/components/EditUserForm/components/EditUserFormAddCertificationFields';
import { EditUserFormAddEducationFields } from '../../../../../../users/components/forms/AddEditUserForm/components/EditUserForm/components/EditUserFormAddEducationFields';
import { EditUserFormAddExperienceFields } from '../../../../../../users/components/forms/AddEditUserForm/components/EditUserForm/components/EditUserFormAddExperienceFields';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { DateUtils } from '../../../../../../../utils/DateUtils';
import { generateNanoId } from '../../../../../../../utils/generateNanoId';
import { LinkedinInputFormField } from '../../../../../../common/components/formFields/LinkedinInputFormField';
import { SkillBadgesCache } from '../../../../../../skillBadges/SkillBadgesCache';
import { SkillBadgeContactId } from '../../../../../../skillBadges/skillBadgesTypes';
import { CommonSkillsBox } from '../../../../../../skills/components/boxes/CommonSkillsBox';
import { SkillsCache } from '../../../../../../skills/SkillsCache';
import { SkillKinds } from '../../../../../../skills/skillsTypes';
import { useCreateContact } from '../../../../../hooks/useCreateContact';

import { experienceOptions } from '../../../../../../jobs/jobs.data';
import { EmploymentSkillActionLabelFields } from '../../../../../../employmentSkillBadges/employmentSkillBadgesTypes';

interface AddEditContactFormContact {
  id: ContactId;
  nanoId: ContactNanoId;
  name: ContactName;
  image: MayBeContactImage;
  country: MayBeContactCountry;
  state: MayBeContactState;
  city: MayBeContactCity;
  profileEducations: ContactEducations;
  skillIds: ContactSkillIds;
  profileEmployments: ContactEmployments;
  profileCertifications: ContactCertifications;
}

export interface AddEditContactFormProps {
  contact?: AddEditContactFormContact & AddEditContactFormData;
  closeModal: () => void;
  onSave: () => void;
  activeContactLink?: AddEditContactActiveContactLink;
}

function AddEditContactForm({
  contact,
  activeContactLink,
  closeModal,
  onSave
}: AddEditContactFormProps) {
  const currentUser = useCurrentUser();

  const { createContact } = useCreateContact({
    cacheKeys: [ContactsCache.indexCacheKey(), ContactsCache.showCacheKey()]
  });

  const { updateContact } = useUpdateContact({
    contactNanoId: contact?.nanoId || ('0' as ContactNanoId),
    cacheKeys: [ContactsCache.indexCacheKey(), ContactsCache.showCacheKey()]
  });

  const [contactLocation, setContactLocation] =
    useState<SelectLocationFormFieldFieldValue>(
      {} as SelectLocationFormFieldFieldValue
    );

  const { name } = contact || {};

  const splittedName = split(name, ' ');
  const firstNameFromName = first(splittedName) as ContactFirstName;
  const lastNameFromName = last(splittedName) as ContactLastName;

  const {
    control,
    watch,
    validationErrors,
    addEditContactFormIsLoading,
    setAddEditContactFormValue,
    addEditContactFormErrorMessage,
    registerFields,
    handleAddEditContact
  } = useAddEditContactForm({
    activeContactLink,
    defaultValues: contact
      ? {
          firstName: contact.firstName || firstNameFromName,
          lastName: contact.lastName || lastNameFromName,
          zipCode: contact.zipCode,
          source: contact.source,
          linkedinUrl: contact.linkedinUrl,
          otherDetails: contact.otherDetails,
          imageId: contact.imageId,
          countryId: contact.countryId,
          stateId: contact.stateId,
          cityId: contact.cityId,
          experience: contact.experience,
          contactEmails: contact.contactEmails?.map(({ id, email, label }) => ({
            id,
            email,
            label
          })),
          contactPhoneNumbers: contact.contactPhoneNumbers?.map(
            ({ id, phoneNumber, label }) => ({ id, phoneNumber, label })
          ),
          profileEmployments: contact.profileEmployments?.map(
            ({
              id,
              startDate,
              endDate,
              cityId,
              companyId,
              jobFunctionIds,
              jobTitleId,
              currently
            }) => ({
              id,
              startDate: DateUtils.toMonthInput(startDate),
              endDate: DateUtils.toMonthInput(endDate),
              cityId,
              companyId,
              jobFunctionIds,
              jobTitleId,
              currently
            })
          ) as ContactEmployments,
          profileEducations: contact.profileEducations?.map(
            ({ id, degree, schoolName, studyField, startDate, endDate }) => ({
              id,
              degree,
              schoolName,
              studyField,
              startDate: DateUtils.toMonthInput(startDate),
              endDate: DateUtils.toMonthInput(endDate)
            })
          ) as ContactEducations,
          profileCertifications: contact.profileCertifications?.map(
            ({ id, name, issueDate, credentialsId, companyId }) => ({
              id,
              name,
              issueDate: DateUtils.toMonthInput(issueDate),
              credentialsId,
              companyId
            })
          ) as ContactCertifications
        }
      : {},
    onAddEditContact: async (data) => {
      if (contact) {
        await updateContact(data);
        onSave();
      } else {
        const payload = {
          ...data,
          nanoId: generateNanoId<ContactNanoId>(),
          userId: currentUser.id as ContactUserId
        };
        await createContact(payload);
        onSave();
      }
    }
  });

  const currentCountryId = watch(ContactFields.COUNTRY_ID);
  const currentStateId = watch(ContactFields.STATE_ID);
  const currentCityId = watch(ContactFields.CITY_ID);
  const linkedinUrl = watch(ContactFields.LINKEDIN_URL);
  const profileEmployments = watch(ContactFields.EMPLOYMENTS);

  const handleSelectContactLocation = useCallback<
    (location: SelectLocationFormFieldFieldValue) => void
  >(
    (location) => {
      const { city, state, country } = location;

      setAddEditContactFormValue(
        ContactFields.CITY_ID,
        city.id as ContactCityId
      );
      setAddEditContactFormValue(
        ContactFields.STATE_ID,
        state.id as ContactStateId
      );
      setAddEditContactFormValue(
        ContactFields.COUNTRY_ID,
        country.id as ContactCountryId
      );

      setContactLocation(location);
    },
    [setAddEditContactFormValue]
  );

  useEffect(() => {
    setContactLocation({
      city: contact?.city,
      state: contact?.state,
      country: contact?.country
    } as SelectLocationFormFieldFieldValue);
  }, [contact]);

  return (
    <Stack>
      <Text textAlign="right" color="red.500">
        * indicates required
      </Text>

      <Stack spacing={6} sx={{ marginBottom: '40px !important' }}>
        <Form onSubmit={handleAddEditContact}>
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={6}>
            <InputField
              label="First Name"
              placeholder="First Name"
              autoComplete="off"
              name={ContactFields.FIRST_NAME}
              errorMessage={validationErrors.firstNameValidationError}
              ref={registerFields.registerFirstName.ref}
              onChange={registerFields.registerFirstName.onChange}
            />

            <InputField
              label="Last Name"
              placeholder="Last Name"
              autoComplete="off"
              name={ContactFields.LAST_NAME}
              errorMessage={validationErrors.lastNameValidationError}
              ref={registerFields.registerLastName.ref}
              onChange={registerFields.registerLastName.onChange}
            />
          </SimpleGrid>

          <LinkedinInputFormField
            label="LinkedIn profile URL"
            placeholder="Linkedin Link"
            name={ContactFields.LINKEDIN_URL}
            linkedinUrl={linkedinUrl as string}
            ref={registerFields.registerLinkedinUrl.ref}
            onChange={registerFields.registerLinkedinUrl.onChange}
            errorMessage={validationErrors.linkedinUrlValidationError}
          />

          <AddEditContactFormAddPhoneFields
            control={control}
            errorMessages={validationErrors.contactPhoneNumbersValidationErrors}
          />

          <AddEditContactFormAddEmailFields
            control={control}
            errorMessages={validationErrors.contactEmailsValidationErrors}
          />

          <UploadImageField
            label="Avatar"
            placeholder="Upload Avatar"
            size={20}
            control={control}
            name={ContactFields.IMAGE_ID}
            errorMessage={validationErrors.imageIdValidationError}
            defaultImage={contact?.image}
          />

          <SelectField
            control={control}
            label="Experience"
            placeholder="Experience"
            name={ContactFields.EXPERIENCE}
            options={experienceOptions}
            defaultValue={experienceOptions.find(
              (experience) => contact?.experience === experience.value
            )}
          />

          <FormControl>
            <FormLabel display="flex" flex={1}>
              Location
            </FormLabel>

            <Stack
              spacing={0}
              border="1px solid"
              borderRadius="base"
              borderColor="gray.200"
              divider={<StackDivider />}
            >
              <SelectLocationFormField
                showLabel={false}
                item={contact}
                errorMessage={validationErrors.cityIdValidationError}
                handleSelectLocation={handleSelectContactLocation}
                currentyCityId={currentCityId}
                isBorderless
              />

              <Stack spacing={0} direction="row" divider={<StackDivider />}>
                <SelectCountryFormField
                  control={control}
                  showLabel={false}
                  placeholder="Country"
                  item={contactLocation}
                  errorMessage={validationErrors.countryIdValidationError}
                  isBorderless
                />
                <SelectStateFormField
                  control={control}
                  showLabel={false}
                  placeholder="State"
                  currentCountryId={currentCountryId}
                  item={contactLocation}
                  errorMessage={validationErrors.stateIdValidationError}
                  isBorderless
                />
                <SelectCityFormField
                  control={control}
                  showLabel={false}
                  placeholder="City"
                  currentCountryId={currentCountryId}
                  currentStateId={currentStateId}
                  item={contactLocation}
                  errorMessage={validationErrors.cityIdValidationError}
                  isBorderless
                />

                <InputField
                  placeholder="Zip code"
                  autoComplete="off"
                  border="none"
                  errorMessage={validationErrors.zipCodeValidationError}
                  name={ContactFields.ZIP_CODE}
                  ref={registerFields.registerZipCode.ref}
                  onChange={registerFields.registerZipCode.onChange}
                />
              </Stack>
            </Stack>
          </FormControl>

          <EditUserFormAddExperienceFields
            label="Work experience"
            control={control as unknown as Control<AddEditUserFormData>}
            setAddEditUserFormValue={
              setAddEditContactFormValue as unknown as UseFormSetValue<AddEditUserFormData>
            }
            user={{
              profileEmployments:
                contact?.profileEmployments as unknown as UserEmployments
            }}
            currentEmployments={profileEmployments}
            errorMessages={validationErrors.profileEmploymentsValidationErrors}
          />

          <EditUserFormAddEducationFields
            label="Education"
            control={control as unknown as Control<AddEditUserFormData>}
            errorMessages={validationErrors.profileEducationsValidationErrors}
          />

          <EditUserFormAddCertificationFields
            label="Licenses/Certifications"
            control={control as unknown as Control<AddEditUserFormData>}
            user={{
              profileCertifications:
                contact?.profileCertifications as unknown as UserCertifications
            }}
            errorMessages={
              validationErrors.profileCertificationsValidationErrors
            }
          />

          <InputField
            label="Source"
            placeholder="Source"
            name={ContactFields.SOURCE}
            ref={registerFields.registerSource.ref}
            onChange={registerFields.registerSource.onChange}
            errorMessage={validationErrors.sourceValidationError}
          />

          {contact ? (
            <>
              <CommonSkillsBox
                label="Computer skills"
                actionLabel={EmploymentSkillActionLabelFields.COMPUTER_SKILLS}
                skillKind={SkillKinds.COMPUTER}
                contact={contact}
                skillBadgesCacheKey={SkillBadgesCache.contactCacheKey(
                  contact.id as SkillBadgeContactId
                )}
                skillsCacheKey={SkillsCache.computerCacheKey()}
                newSkillsCacheKey={SkillsCache.computerNewSkillsCacheKey()}
                createOnly
                showSkillBadgeSkillNameOnly
              />

              <CommonSkillsBox
                label="Industry Knowledge"
                actionLabel={
                  EmploymentSkillActionLabelFields.INDUSTRY_KNOWLEDGE
                }
                skillKind={SkillKinds.INDUSTRY}
                contact={contact}
                skillBadgesCacheKey={SkillBadgesCache.contactCacheKey(
                  contact.id as SkillBadgeContactId
                )}
                skillsCacheKey={SkillsCache.industryCacheKey()}
                newSkillsCacheKey={SkillsCache.industryNewSkillsCacheKey()}
                createOnly
                showSkillBadgeSkillNameOnly
              />
            </>
          ) : null}

          <Box bg="green.700">
            <FormLabel>Any other details</FormLabel>
            <Box h="300px">
              <WysiwygField
                control={control}
                name={ContactFields.OTHER_DETAILS}
                errorMessage={validationErrors.otherDetailsValidationError}
                defaultValue={contact?.otherDetails}
              />
            </Box>
          </Box>

          <AlertMessage message={addEditContactFormErrorMessage} />

          <ButtonGroup
            spacing="6"
            justifyContent="flex-end"
            pos="fixed"
            bottom={0}
            right={0}
            zIndex={1000}
            width="100%"
            py={4}
            px={8}
            background="white"
          >
            <Button hierarchy="tertiary" onClick={closeModal}>
              Cancel
            </Button>
            <PureSubmitButtonHelper
              isLoading={addEditContactFormIsLoading}
              i18nText="Save"
            />
          </ButtonGroup>
        </Form>
      </Stack>
    </Stack>
  );
}

export default AddEditContactForm;
