import { Flex, HStack, useDisclosure } from '@chakra-ui/react';

import { Button } from '../../../../../../../../helpers/Button';
import { ScheduleHelperModal } from '../../../../../../../../helpers/ScheduleHelper/modal/ScheduleHelperModal';
import { Text } from '../../../../../../../../helpers/Text';
import { ThumbDownIcon } from '../../../../../../../../icons/ThumbDownIcon';
import { ThumbUpIcon } from '../../../../../../../../icons/ThumbUpIcon';
import {
  JobCandidateFirstName,
  JobCandidateId,
  JobCandidateJobTitle,
  JobCandidateLastName,
  JobCandidateName,
  JobCandidateNanoId,
  JobCandidateSearchTypeLabel,
  MayBeJobCandidateCompany,
  MayBeJobCandidateImage
} from '../../../../../../../jobCandidates/jobCandidatesTypes';
import {
  JobId,
  JobJobTitle,
  JobName,
  JobNanoId,
  MayBeJobCities,
  MayBeJobCity
} from '../../../../../../jobsTypes';
import { JobCandidateDetailsPageWorkflowStage } from '../../../../ViewJobCandidateDetailsPage.types';
import { useViewJobCandidateDetailsStageTasksWidgetContext } from '../../../../contexts/ViewJobCandidateDetailsStageTasksWidgetContext';
import { ViewJobCandidateDetailsDisqualifyCandidate } from '../ViewJobCandidateDetailsDisqualifyCandidate';

interface ViewJobCandidateDetailsAdvanceDiscontinueProps {
  job: {
    id: JobId;
    nanoId: JobNanoId;
    name: JobName;
    jobTitle: JobJobTitle;
    cities: MayBeJobCities;
    city: MayBeJobCity;
  };
  jobCandidate: {
    id: JobCandidateId;
    nanoId: JobCandidateNanoId;
    name: JobCandidateName;
    firstName?: JobCandidateFirstName;
    lastName?: JobCandidateLastName;
    jobTitle: JobCandidateJobTitle;
    company: MayBeJobCandidateCompany;
    image: MayBeJobCandidateImage;
    searchTypeLabel: JobCandidateSearchTypeLabel;
    workflowStage: JobCandidateDetailsPageWorkflowStage;
  };
}

export default function ViewJobCandidateDetailsAdvanceDiscontinue({
  job,
  jobCandidate
}: ViewJobCandidateDetailsAdvanceDiscontinueProps) {
  const {
    disqualifyCandidate,
    isOpenDisqualificationPopup,
    onOpenDisqualificationPopup,
    onCloseDisqualificationPopup
  } = useViewJobCandidateDetailsStageTasksWidgetContext();

  const {
    isOpen: isOpenSchedulePopup,
    onOpen: onOpenSchedulePopup,
    onClose: onCloseSchedulePopup
  } = useDisclosure();

  return (
    <>
      <Flex pos="relative">
        <HStack
          w="lg"
          pos="relative"
          borderRadius="base"
          transitionDuration="slow"
          justifyContent="flex-start"
          spacing={5}
        >
          <Button
            p={4}
            w="50%"
            h="auto"
            bg="gray.50"
            display="flex"
            color="red.500"
            flexDir="column"
            border="1px solid"
            borderColor="gray.200"
            onClick={onOpenDisqualificationPopup}
            justifyContent="stretch"
            hierarchy="tertiary"
            alignItems="center"
            boxShadow="none"
            gap={1}
            _hover={{
              bg: 'gray.100',
              borderColor: 'gray.300'
            }}
          >
            <ThumbDownIcon w={5} h={5} />
            <Text lineHeight="base">Discontinue</Text>
          </Button>

          <Button
            p={4}
            w="50%"
            h="auto"
            bg="gray.50"
            display="flex"
            color="green.600"
            flexDir="column"
            border="1px solid"
            hierarchy="tertiary"
            borderColor="gray.200"
            justifyContent="stretch"
            onClick={onOpenSchedulePopup}
            alignItems="center"
            boxShadow="none"
            gap={1}
            _hover={{
              bg: 'gray.100',
              borderColor: 'gray.300'
            }}
          >
            <ThumbUpIcon w={5} h={5} />
            <Text lineHeight="base">Advance</Text>
          </Button>
        </HStack>
      </Flex>

      <ScheduleHelperModal
        minSlots={1}
        jobNanoId={job.nanoId}
        isOpen={isOpenSchedulePopup}
        onCancel={onCloseSchedulePopup}
        onSubmit={onCloseSchedulePopup}
        selectDateLabel="Interview Availability"
        title={`Interview for ${job.name} role`}
      />

      <ViewJobCandidateDetailsDisqualifyCandidate
        isOpen={isOpenDisqualificationPopup}
        onClose={onCloseDisqualificationPopup}
        disqualifyCandidate={disqualifyCandidate}
        jobCandidate={jobCandidate}
        job={job}
      />
    </>
  );
}
