import React from 'react';

import { ErrorMessage, I18nText } from '../../../../../types';

import { SkillBadgeJobId } from '../../../../skillBadges/skillBadgesTypes';

import {
  FetchSkillsCacheKey,
  SkillKinds
} from '../../../../skills/skillsTypes';

import { SkillBadgesCache } from '../../../../skillBadges/SkillBadgesCache';

import { CommonSkillsBox } from '../../../../skills/components/boxes/CommonSkillsBox';

import { JobSelectSkillBadgesFieldJob } from './JobSelectSkillBadgesField.types';

interface JobSelectSkillBadgesFieldProps {
  label: I18nText;
  actionLabel?: I18nText;
  job: JobSelectSkillBadgesFieldJob;
  skillsCacheKey: FetchSkillsCacheKey;
  newSkillsCacheKey: FetchSkillsCacheKey;
  skillKind: SkillKinds;
  errorMessage?: ErrorMessage;
}

function JobSelectSkillBadgesField({
  label,
  actionLabel,
  job,
  skillKind,
  skillsCacheKey,
  newSkillsCacheKey,
  errorMessage
}: JobSelectSkillBadgesFieldProps) {
  return (
    <CommonSkillsBox
      label={label}
      actionLabel={actionLabel}
      errorMessage={errorMessage}
      skillKind={skillKind}
      job={job}
      skillBadgesCacheKey={SkillBadgesCache.postJobCacheKey(
        job.id as SkillBadgeJobId
      )}
      skillsCacheKey={skillsCacheKey}
      newSkillsCacheKey={newSkillsCacheKey}
      isIntakeForm
    />
  );
}

export default JobSelectSkillBadgesField;
