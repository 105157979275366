import { useCallback, useState } from 'react';
import { Flex, Stack, StackDivider } from '@chakra-ui/react';

import {
  CertificationFields,
  FetchCertificationsFilters,
  FetchCertificationsSort
} from '../../../certificationsTypes';

import { JobCandidateId } from '../../../../jobCandidates/jobCandidatesTypes';

import { JobResumeRecruiterId } from '../../../../jobResumeRecruiters/jobResumeRecruitersTypes';

import {
  fetchCertificationsQuery,
  FetchCertificationsResponse
} from '../../../queries/fetchCertifications.query';

import { usePaginatedCertifications } from '../../../../certifications/hooks/usePaginatedCertifications';

import { CertificationsCache } from '../../../CertificationsCache';

import { PlusIcon } from '../../../../../icons/PlusIcon';

import { EditCertificationListItem } from '../../../../certifications/components/lists/EditCertificationListItem';
import { AddEditCertificationForm } from '../../../../certifications/components/forms/AddEditCertificationForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { Heading } from '../../../../../helpers/Heading';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';

interface CertificationsListFormJobCandidateProps {
  jobCandidateId: JobCandidateId;
  jobResumeRecruiterId?: never;
}

interface CertificationsListFormJobResumeRecruiterProps {
  jobCandidateId?: never;
  jobResumeRecruiterId: JobResumeRecruiterId;
}

interface CertificationsListFormRequiredProps {
  withoutHeading?: boolean;
  withStackDivider?: boolean;
  alignButtonToRight?: boolean;
}

type CertificationsListFormProps = CertificationsListFormRequiredProps &
  (
    | CertificationsListFormJobCandidateProps
    | CertificationsListFormJobResumeRecruiterProps
  );

function CertificationsListForm({
  jobCandidateId,
  jobResumeRecruiterId,
  withoutHeading,
  withStackDivider,
  alignButtonToRight
}: CertificationsListFormProps) {
  const certificationsCacheKey =
    (jobCandidateId
      ? CertificationsCache.jobCandidateListCacheKey(jobCandidateId)
      : null) ||
    (jobResumeRecruiterId
      ? CertificationsCache.jobResumeRecruiterListCacheKey(jobResumeRecruiterId)
      : null) ||
    CertificationsCache.indexCacheKey();

  const {
    certifications,
    certificationsIsFetched,
    certificationsErrorMessage
  } = usePaginatedCertifications<FetchCertificationsResponse>({
    query: fetchCertificationsQuery,
    cacheKey: certificationsCacheKey,
    initialFilters: {
      ...(jobCandidateId
        ? {
            [CertificationFields.JOB_CANDIDATE_ID]: {
              operator: 'eq',
              value: jobCandidateId
            }
          }
        : {}),
      ...(jobResumeRecruiterId
        ? {
            [CertificationFields.JOB_RESUME_RECRUITER_ID]: {
              operator: 'eq',
              value: jobResumeRecruiterId
            }
          }
        : {})
    } as unknown as FetchCertificationsFilters,
    initialSort: {
      [CertificationFields.ID]: { ascending: false }
    } as unknown as FetchCertificationsSort
  });

  const [addCertificationFormIsOpen, setAddCertificationFormIsOpen] =
    useState<boolean>(false);

  const handleAddCertificationFormOpen = useCallback(
    () => setAddCertificationFormIsOpen(true),
    [setAddCertificationFormIsOpen]
  );

  const handleAddCertificationFormClose = useCallback(
    () => setAddCertificationFormIsOpen(false),
    [setAddCertificationFormIsOpen]
  );

  return (
    <Stack
      spacing={8}
      divider={withStackDivider ? <StackDivider /> : undefined}
    >
      {withoutHeading ? null : (
        <Heading level="h3" color="#10002E" textAlign="center">
          Licenses and Certifications
        </Heading>
      )}

      <LoadingSkeleton loaded={certificationsIsFetched}>
        <AlertMessage message={certificationsErrorMessage} />
        <Stack
          spacing={6}
          divider={withStackDivider ? <StackDivider /> : undefined}
        >
          {certifications.map((certification, index) => (
            <EditCertificationListItem
              key={certification.nanoId}
              certification={certification}
              certificationsCacheKey={certificationsCacheKey}
              stackSpacing={8}
              index={index}
            />
          ))}
        </Stack>
      </LoadingSkeleton>
      {addCertificationFormIsOpen ? (
        <AddEditCertificationForm
          type="new"
          jobCandidateId={jobCandidateId}
          jobResumeRecruiterId={jobResumeRecruiterId}
          certificationsCacheKey={certificationsCacheKey}
          isOpen={addCertificationFormIsOpen}
          onClose={handleAddCertificationFormClose}
          onSave={handleAddCertificationFormClose}
        />
      ) : (
        <Flex>
          <PureButtonHelper
            hierarchy="tertiary"
            size="medium"
            leftIcon={<PlusIcon color="gray.500" />}
            i18nText="Add Certification"
            flexGrow={alignButtonToRight ? 0 : 1}
            ml={alignButtonToRight ? 'auto' : undefined}
            onClick={handleAddCertificationFormOpen}
          />
        </Flex>
      )}
    </Stack>
  );
}

export default CertificationsListForm;
