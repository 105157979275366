import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { Fragment, ReactNode, useEffect, useRef } from 'react';
import { useScrollPosition } from '../../../../../../common/hooks/utils/useScrollPosition';
import { AlertMessage } from '../../../../../../helpers/AlertMessage';
import { FullPageLoader } from '../../../../../../helpers/FullPageLoader';
import { LogoIconLoop } from '../../../../../../helpers/Lotties/LogoIconLoop';
import { HiringPortalRoutes } from '../../../../../hiringPortal/HiringPortalRoutes';
import { JobCandidatesCache } from '../../../../../jobCandidates/JobCandidatesCache';
import { useFetchJobCandidateByNanoId } from '../../../../../jobCandidates/hooks/useFetchJobCandidateByNanoId';
import {
  FetchJobCandidateEnabled,
  JobCandidateNanoId
} from '../../../../../jobCandidates/jobCandidatesTypes';
import {
  FetchJobCandidateResponse,
  fetchJobCandidateQuery
} from '../../../../../jobCandidates/queries/fetchJobCandidate.query';
import { PipelineKanbanRoutes } from '../../../../../pipeline/PipelineKanbanRoutes';
import { RecruitRoutes } from '../../../../../recruit/RecruitRoutes';
import { UpdatesRoutes } from '../../../../../updates/UpdatesRoutes';
import { JobsCache } from '../../../../JobsCache';
import { useFetchJobByNanoId } from '../../../../hooks/useFetchJobByNanoId';
import { JobNanoId } from '../../../../jobsTypes';
import {
  FetchJobResponse,
  fetchJobQuery
} from '../../../../queries/fetchJob.query';
import {
  JobCandidateDetailsPageHandleGoBack,
  JobCandidateDetailsPageReferrer
} from '../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsCandidateDetails } from '../../components/ViewJobCandidateDetailsCandidateDetails';
import { ViewJobCandidateDetailsFloatingStageTasksManager } from '../../components/ViewJobCandidateDetailsFloatingStageTasksManager';
import { ViewJobCandidateDetailsHeaderMenu } from '../../components/ViewJobCandidateDetailsHeaderMenu';
import { ViewJobCandidateDetailsJobDetails } from '../../components/ViewJobCandidateDetailsJobDetails';
import { ViewJobCandidateDetailsRightSidebar } from '../../components/ViewJobCandidateDetailsRightSidebar';
import { ViewJobCandidateDetailsSendAssessmentSidebar } from '../../components/ViewJobCandidateDetailsSendAssessmentSidebar';
import { ViewJobCandidateDetailsSubmissionSidebar } from '../../components/ViewJobCandidateDetailsSubmissionSidebar';
import { useViewJobCandidateDetailsSendAssessmentContext } from '../../contexts/ViewJobCandidateDetailsSendAssessmentContext';
import { useViewJobCandidateDetailsSubmissionContext } from '../../contexts/ViewJobCandidateDetailsSubmissionContext';
import { IsDisabled } from '../../../../../../types';
import { HiringPortalJobCandidateDetailsRoutes } from '../../../../../hiringPortal/HiringPortalJobCandidateDetailsRoutes';
import ViewJobCandidateDetailsEvaluationSidebar from '../../components/ViewJobCandidateDetailsSubmissionSidebar/ViewJobCandidateEvaluationSidebar';
import { Heading } from '../../../../../../helpers/Heading';

interface ViewJobCandidateDetailsPageLayoutContentProps {
  jobNanoId: JobNanoId;
  children: ReactNode;
  isBasePath: boolean;
  isHiringPortalPath?: boolean;
  jobCandidateNanoId: JobCandidateNanoId;
  handleRouteToJob: (jobNanoId: JobNanoId) => void;
  isJobDetailsPage?: boolean;
}

export default function ViewJobCandidateDetailsPageLayoutContent({
  children,
  jobNanoId,
  isBasePath,
  jobCandidateNanoId,
  isHiringPortalPath = false,
  handleRouteToJob,
  isJobDetailsPage = false
}: ViewJobCandidateDetailsPageLayoutContentProps) {
  const router = useRouter();
  const topScrollRef = useRef<HTMLDivElement>(null);

  const handleGoBack: JobCandidateDetailsPageHandleGoBack = () => {
    if (isHiringPortalPath) {
      return {
        label: 'pipeline',
        url: HiringPortalRoutes.jobCandidates(jobNanoId)
      };
    }
    const { referrer } = router.query as {
      referrer: JobCandidateDetailsPageReferrer | undefined;
    };

    switch (referrer) {
      case 'recruit':
        return {
          label: 'recruit',
          url: RecruitRoutes.viewRecruitDetails(jobNanoId)
        };
      case 'updates':
        return { label: 'updates', url: UpdatesRoutes.index() };
      default:
        return {
          label: 'kanban',
          url: PipelineKanbanRoutes.viewCandidates(jobNanoId)
        };
    }
  };

  const { job, jobIsLoading, jobIsFetched, jobErrorMessage } =
    useFetchJobByNanoId<FetchJobResponse>({
      jobNanoId,
      query: fetchJobQuery,
      cacheKey: JobsCache.showCacheKey()
    });

  const { jobCandidate, jobCandidateIsLoading } =
    useFetchJobCandidateByNanoId<FetchJobCandidateResponse>({
      jobCandidateNanoId: jobCandidateNanoId as JobCandidateNanoId,
      enabled: !!jobCandidateNanoId as FetchJobCandidateEnabled,
      cacheKey: JobCandidatesCache.showCacheKey(),
      query: fetchJobCandidateQuery
    });

  const {
    isOpen: isBioOutsideViewport,
    onOpen: setBioOutsideViewport,
    onClose: setBioInsideViewport
  } = useDisclosure();

  const bioContainerRef = useScrollPosition({
    setIsInsideViewport: setBioInsideViewport,
    setIsOutsideViewport: setBioOutsideViewport
  });

  const {
    isCandidateSubmissionOpen,
    isCandidateEvaluationOpen,
    isCandidateScorecardPath
  } = useViewJobCandidateDetailsSubmissionContext();

  const { isSendEmailAssessmentOpen } =
    useViewJobCandidateDetailsSendAssessmentContext();

  const showCandidateEvaluation =
    isCandidateEvaluationOpen && isCandidateScorecardPath;

  const isRightSidebarOpen =
    isCandidateSubmissionOpen ||
    isSendEmailAssessmentOpen ||
    showCandidateEvaluation;

  useEffect(() => {
    if (isBasePath) {
      setBioInsideViewport();

      if (topScrollRef.current) {
        topScrollRef.current.scrollIntoView({
          block: 'start'
        });
      }
    }
  }, [isBasePath, jobCandidateNanoId, setBioInsideViewport]);

  useEffect(() => {
    isRightSidebarOpen &&
      topScrollRef.current &&
      topScrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
  }, [isRightSidebarOpen]);

  const candidateHeaderMenu = (
    jobCandidate: FetchJobCandidateResponse,
    job: FetchJobResponse
  ) => (
    <ViewJobCandidateDetailsHeaderMenu
      hasScrolled={isJobDetailsPage || isBioOutsideViewport}
      requiresCredit={false}
      jobCandidate={jobCandidate}
      job={job}
      jobIsFetched={jobIsFetched}
      isHiringPortalPath={isHiringPortalPath}
      jobRoute={(jobNanoId) =>
        isHiringPortalPath
          ? HiringPortalJobCandidateDetailsRoutes.profile(
              jobNanoId,
              jobCandidateNanoId
            )
          : PipelineKanbanRoutes.viewCandidateDetailsProfile(
              jobNanoId,
              jobCandidateNanoId
            )
      }
    />
  );

  return (
    <Fragment>
      {jobIsLoading && <FullPageLoader />}

      {jobErrorMessage && (
        <Box>
          <AlertMessage message={jobErrorMessage} />
        </Box>
      )}

      {job && (
        <Box
          minH="100%"
          pos="relative"
          transitionDuration="fast"
          w={isRightSidebarOpen ? 'calc(100% - 400px)' : 'calc(100% - 300px)'}
        >
          {jobCandidateIsLoading && (
            <FullPageLoader loader={<LogoIconLoop />} />
          )}

          {jobCandidate && (
            <>
              <Box
                h={10}
                w="full"
                zIndex={99}
                opacity={0}
                pos="absolute"
                ref={topScrollRef}
                pointerEvents="none"
                cursor="not-allowed"
              />

              <Flex minH="100%" flexDir="column">
                {(showCandidateEvaluation || isRightSidebarOpen) && (
                  <Flex h={14} pl={12} alignItems="center">
                    <Heading level="h3">
                      {showCandidateEvaluation ? (
                        'Scorecard Review'
                      ) : (
                        <>
                          {isSendEmailAssessmentOpen &&
                            'Talent Overview Request'}
                          {isCandidateSubmissionOpen && 'Candidate Submission'}
                        </>
                      )}
                    </Heading>
                  </Flex>
                )}

                {!(showCandidateEvaluation || isRightSidebarOpen) &&
                  candidateHeaderMenu(jobCandidate, job)}

                <Box
                  pl={{ base: 4, xl: 12 }}
                  pr={isRightSidebarOpen ? { base: 4, xl: 6 } : undefined}
                  pb={isRightSidebarOpen ? 24 : 4}
                  pos="relative"
                  transform="auto"
                >
                  <Box
                    pos="relative"
                    transform="auto"
                    transitionDuration="fast"
                    bg={isRightSidebarOpen ? 'primary.50' : 'transparent'}
                    borderRadius={isRightSidebarOpen ? 'base' : undefined}
                    border={isRightSidebarOpen ? '1px solid' : undefined}
                    borderColor={
                      isRightSidebarOpen ? 'primary.200' : 'transparent'
                    }
                  >
                    {(showCandidateEvaluation || isRightSidebarOpen) &&
                      candidateHeaderMenu(jobCandidate, job)}
                    <Box
                      transitionDuration="fast"
                      px={isRightSidebarOpen ? 6 : 0}
                      pb={isRightSidebarOpen ? 6 : 0}
                      pos="relative"
                      transform="auto"
                    >
                      {isJobDetailsPage ||
                      showCandidateEvaluation ||
                      isRightSidebarOpen ? null : (
                        <Box pt={4}>
                          <ViewJobCandidateDetailsJobDetails
                            jobCandidate={jobCandidate}
                            handleGoBack={handleGoBack}
                            isDisabled={isRightSidebarOpen as IsDisabled}
                            handleRouteToJob={handleRouteToJob}
                            isHiringPortalPath={isHiringPortalPath}
                            jobNanoId={jobNanoId}
                          />
                        </Box>
                      )}

                      <Box
                        h={6}
                        top={20}
                        zIndex={99}
                        opacity={0}
                        pos="absolute"
                        pointerEvents="none"
                        ref={bioContainerRef}
                        w="full"
                      />

                      <ViewJobCandidateDetailsCandidateDetails
                        requiresCredit={false}
                        isDisabled={isRightSidebarOpen as IsDisabled}
                        isHiringPortalPath={isHiringPortalPath}
                        jobCandidate={jobCandidate}
                        isBasePath={isBasePath}
                        job={job}
                        isJobDetailsPage={isJobDetailsPage}
                      >
                        {children}
                      </ViewJobCandidateDetailsCandidateDetails>
                    </Box>
                  </Box>
                </Box>
              </Flex>

              {isJobDetailsPage ? null : (
                <ViewJobCandidateDetailsFloatingStageTasksManager
                  hasScrolled={isBioOutsideViewport}
                  jobCandidateNanoId={jobCandidateNanoId}
                  jobCandidate={jobCandidate}
                  job={job}
                />
              )}
            </>
          )}
        </Box>
      )}

      {job && (
        <>
          {isRightSidebarOpen ? (
            <>
              {isCandidateSubmissionOpen && (
                <ViewJobCandidateDetailsSubmissionSidebar
                  job={job}
                  jobCandidateNanoId={jobCandidateNanoId}
                />
              )}

              {showCandidateEvaluation && (
                <ViewJobCandidateDetailsEvaluationSidebar />
              )}

              {isSendEmailAssessmentOpen && (
                <ViewJobCandidateDetailsSendAssessmentSidebar
                  job={job}
                  jobCandidateNanoId={jobCandidateNanoId}
                />
              )}
            </>
          ) : (
            <ViewJobCandidateDetailsRightSidebar
              job={job}
              jobCandidateNanoId={jobCandidateNanoId}
              isHiringPortalPage={isHiringPortalPath}
            />
          )}
        </>
      )}
    </Fragment>
  );
}
