import { FieldBrand, ItemRecordID } from '../../../types';

import { UserUuid } from '../../users/usersTypes';

export type JobCandidateWorkflowStageRecordId = FieldBrand<
  ItemRecordID,
  'JobCandidateWorkflowStageRecordId'
>;

export type JobCandidateWorkflowStageUserUuid = FieldBrand<
  UserUuid,
  'JobCandidateWorkflowStageUserUuid'
>;

export const enum JobCandidateWorkflowStageIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  CREATED_AT = 'createdAt',
  USER_UUID = 'userUuid'
}
