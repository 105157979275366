import { Flex, HStack, Tag } from '@chakra-ui/react';
import { MouseEvent } from 'react';
import { Text } from '../../../../../../../../helpers/Text';
import { ClockIcon } from '../../../../../../../../icons/ClockIcon';
import { JobCandidateDetailsPageWorkflowStage } from '../../../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsStagesDropdown } from '../ViewJobCandidateDetailsStagesDropdown';

interface ViewJobCandidateDetailsStageSectionProps {
  jobCandidate: {
    workflowStage: JobCandidateDetailsPageWorkflowStage;
  };
  workflowStages: JobCandidateDetailsPageWorkflowStage[];
  changeWorkflowStage: (e: MouseEvent<HTMLButtonElement>) => void;
}

export default function ViewJobCandidateDetailsStageSection({
  jobCandidate,
  workflowStages,
  changeWorkflowStage
}: ViewJobCandidateDetailsStageSectionProps) {
  return (
    <Flex p={3} w="full" gap={2} flexDir="column">
      <Flex
        h={8}
        w="full"
        pl={2.5}
        pr={1.5}
        bg="white"
        color="gray.700"
        border="1px solid"
        borderColor="gray.300"
        borderRadius="base"
        fontWeight="normal"
      >
        <ViewJobCandidateDetailsStagesDropdown
          workflowStageNanoId={jobCandidate.workflowStage.nanoId}
          workflowStages={workflowStages}
          onClick={changeWorkflowStage}
        />
      </Flex>

      <Flex h={6} alignItems="center">
        <HStack>
          <ClockIcon w={4} h={4} />
          <Text textStyle="body2Regular">
            {jobCandidate.workflowStage.name}
          </Text>

          <Tag bg="red.500" color="white" size="sm" borderRadius="base">
            Stagnant
          </Tag>
        </HStack>
      </Flex>
    </Flex>
  );
}
