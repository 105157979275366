import { useCallback, useState } from 'react';
import { Flex, Stack, StackDivider } from '@chakra-ui/react';

import { JobCandidateId } from '../../../../jobCandidates/jobCandidatesTypes';

import { JobResumeId } from '../../../../jobResumes/jobResumesTypes';

import { JobResumeRecruiterId } from '../../../../jobResumeRecruiters/jobResumeRecruitersTypes';

import {
  EmploymentFields,
  FetchEmploymentsFilters,
  FetchEmploymentsSort
} from '../../../employmentsTypes';

import {
  fetchEmploymentsQuery,
  FetchEmploymentsResponse
} from '../../../queries/fetchEmployments.query';

import { usePaginatedEmployments } from '../../../../employments/hooks/usePaginatedEmployments';

import { EmploymentsCache } from '../../../EmploymentsCache';

import { PlusIcon } from '../../../../../icons/PlusIcon';

import { EditEmploymentListItem } from '../../../../employments/components/lists/EditEmploymentListItem';
import { AddEditEmploymentForm } from '../../../../employments/components/forms/AddEditEmploymentForm';

import { Heading } from '../../../../../helpers/Heading';
import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';

interface WorkExperienceEmploymentsFormWithJobCandidateProps {
  jobCandidateId: JobCandidateId;
  jobResumeId?: never;
  jobResumeRecruiterId?: never;
}

interface WorkExperienceEmploymentsFormWithJobResumeProps {
  jobCandidateId?: never;
  jobResumeId: JobResumeId;
  jobResumeRecruiterId?: never;
}

interface WorkExperienceEmploymentsFormWithJobResumeRecruiterProps {
  jobCandidateId?: never;
  jobResumeId?: never;
  jobResumeRecruiterId: JobResumeRecruiterId;
}

interface WorkExperienceEmploymentsFormRequiredProps {
  withoutHeading?: boolean;
  withStackDivider?: boolean;
  alignButtonToRight?: boolean;
  isEditing?: boolean;
}

type WorkExperienceEmploymentsFormProps =
  WorkExperienceEmploymentsFormRequiredProps &
    (
      | WorkExperienceEmploymentsFormWithJobCandidateProps
      | WorkExperienceEmploymentsFormWithJobResumeProps
      | WorkExperienceEmploymentsFormWithJobResumeRecruiterProps
    );

function EmploymentsListForm({
  jobCandidateId,
  jobResumeId,
  jobResumeRecruiterId,
  withoutHeading,
  withStackDivider,
  alignButtonToRight,
  isEditing
}: WorkExperienceEmploymentsFormProps) {
  const employmentsCacheKey =
    (jobCandidateId
      ? EmploymentsCache.jobCandidateListCacheKey(jobCandidateId)
      : null) ||
    (jobResumeId
      ? EmploymentsCache.jobResumeListCacheKey(jobResumeId)
      : null) ||
    (jobResumeRecruiterId
      ? EmploymentsCache.jobResumeRecruiterListCacheKey(jobResumeRecruiterId)
      : null) ||
    EmploymentsCache.indexCacheKey();

  const { employments, employmentsIsFetched, employmentsErrorMessage } =
    usePaginatedEmployments<FetchEmploymentsResponse>({
      query: fetchEmploymentsQuery,
      cacheKey: employmentsCacheKey,
      initialFilters: {
        ...(jobCandidateId
          ? {
              [EmploymentFields.JOB_CANDIDATE_ID]: {
                operator: 'eq',
                value: jobCandidateId
              }
            }
          : {}),
        ...(jobResumeId
          ? {
              [EmploymentFields.JOB_RESUME_ID]: {
                operator: 'eq',
                value: jobResumeId
              }
            }
          : {}),
        ...(jobResumeRecruiterId
          ? {
              [EmploymentFields.JOB_RESUME_RECRUITER_ID]: {
                operator: 'eq',
                value: jobResumeRecruiterId
              }
            }
          : {})
      } as unknown as FetchEmploymentsFilters,
      initialSort: {
        [EmploymentFields.START_DATE]: { ascending: false },
        [EmploymentFields.CREATED_AT]: { ascending: true }
      } as unknown as FetchEmploymentsSort
    });

  const [addEmploymentFormIsOpen, setAddEmploymentFormIsOpen] =
    useState<boolean>(false);

  const handleAddEmploymentFormOpen = useCallback<() => void>(
    () => setAddEmploymentFormIsOpen(true),
    [setAddEmploymentFormIsOpen]
  );

  const handleAddEmploymentFormClose = useCallback<() => void>(
    () => setAddEmploymentFormIsOpen(false),
    [setAddEmploymentFormIsOpen]
  );

  return (
    <Stack
      spacing={8}
      divider={withStackDivider ? <StackDivider /> : undefined}
    >
      {withoutHeading ? null : (
        <Heading level="h3" color="#10002E" textAlign="center">
          Work Experience
        </Heading>
      )}

      <LoadingSkeleton loaded={employmentsIsFetched}>
        <AlertMessage message={employmentsErrorMessage} />
        <Stack
          spacing={6}
          divider={withStackDivider ? <StackDivider /> : undefined}
        >
          {employments.map((employment, index) => (
            <EditEmploymentListItem
              key={employment.nanoId}
              employment={employment}
              employmentsCacheKey={employmentsCacheKey}
              stackSpacing={8}
              isEditing={isEditing}
              index={index}
            />
          ))}
        </Stack>
      </LoadingSkeleton>
      {addEmploymentFormIsOpen ? (
        <AddEditEmploymentForm
          type="new"
          jobCandidateId={jobCandidateId}
          jobResumeId={jobResumeId}
          jobResumeRecruiterId={jobResumeRecruiterId}
          employmentsCacheKey={employmentsCacheKey}
          isOpen={addEmploymentFormIsOpen}
          onClose={handleAddEmploymentFormClose}
          onSave={handleAddEmploymentFormClose}
        />
      ) : (
        <Flex>
          <PureButtonHelper
            hierarchy="tertiary"
            size="medium"
            leftIcon={<PlusIcon color="gray.500" />}
            i18nText="Add Experience"
            flexGrow={alignButtonToRight ? 0 : 1}
            ml={alignButtonToRight ? 'auto' : undefined}
            onClick={handleAddEmploymentFormOpen}
          />
        </Flex>
      )}
    </Stack>
  );
}

export default EmploymentsListForm;
