import { Stack } from '@chakra-ui/react';
import { useState } from 'react';
import { Heading } from '../../../../../../helpers/Heading';
import { useViewJobCandidateDetailsSubmissionContext } from '../../contexts/ViewJobCandidateDetailsSubmissionContext';
import {
  JobScorecardComments,
  JobScorecardOverallEvaluation
} from '../../../../../jobScorecards/jobScorecardsTypes';
import {
  CandidateOverallEvaluation,
  CandidateOverallEvaluationType
} from '../../../../../../helpers/CandidateOverallEvaluation';
import { TextareaField } from '../../../../../../helpers/forms/formFields/TextareaField';
import { CustomiseCandidateEvaluationItem } from './components/CustomiseCandidateEvaluationItem';

interface QuestionEvaluation {
  name: string;
  rating: number;
  comment: string;
}

export default function CustomiseCandidateEvaluation() {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const { assessmentScorecard } = useViewJobCandidateDetailsSubmissionContext();
  const [overallEvaluation, setOverallEvaluation] = useState(
    assessmentScorecard?.overallEvaluation
  );
  const [comment, setComment] = useState(assessmentScorecard?.comments || '');
  const [_, setEvaluations] = useState<QuestionEvaluation[]>(
    () =>
      assessmentScorecard?.jobScorecardQuestions.map((question) => ({
        name: question.name,
        rating: 0,
        comment: ''
      })) || []
  );

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleEvaluationUpdate = (
    name: string,
    rating: number,
    comment: string
  ) => {
    setEvaluations((prev) =>
      prev.map((e) => (e.name === name ? { ...e, rating, comment } : e))
    );
  };

  return (
    <Stack spacing={5}>
      <Heading level="h5" textStyle="body1Medium" fontWeight="semibold">
        Recruiter Assessment Review
      </Heading>

      <Stack spacing={2}>
        {assessmentScorecard?.jobScorecardQuestions.map((question, index) => (
          <CustomiseCandidateEvaluationItem
            key={index}
            question={question}
            isOpen={openIndex === index}
            onToggle={() => handleToggle(index)}
            onUpdate={(rating, comment) =>
              handleEvaluationUpdate(question.name, rating, comment)
            }
          />
        ))}
      </Stack>

      <CandidateOverallEvaluation
        showCommentLabel={false}
        showComment="never"
        overallEvaluation={
          overallEvaluation as unknown as CandidateOverallEvaluationType
        }
        setOverallEvaluation={(overallEval: CandidateOverallEvaluationType) =>
          setOverallEvaluation(
            overallEval as unknown as JobScorecardOverallEvaluation
          )
        }
        isDisabled={false}
      />

      <TextareaField
        bg="white"
        height="180px"
        label="Add a comment"
        value={comment}
        isReadOnly={false}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setComment(e.target.value as JobScorecardComments);
        }}
      />
    </Stack>
  );
}
