import { useEffect } from 'react';
import { Flex, Spinner, Stack } from '@chakra-ui/react';
import map from 'lodash/map';
import compact from 'lodash/compact';

import { useUpdateEmploymentSkillBadge } from '../../hooks/useUpdateEmploymentSkillBadge';
import { useAddEditEmploymentSkillBadgeForm } from './hooks/useAddEditEmploymentSkillBadgeForm';

import { AddEditEmploymentSkillBadgeFormEmploymentSkillBadge } from './AddEditEmploymentSkillBadgeForm.types';
import {
  EmploymentSkillBadgeFields,
  EmploymentSkillBadgeUsage,
  EmploymentSkillBadgeUsageTypes,
  FetchEmploymentSkillBadgesCacheKey,
  employmentSkillBadgeUsageTypes
} from '../../employmentSkillBadgesTypes';

import { InputField } from '../../../../helpers/forms/formFields/InputField';
import { SelectField } from '../../../../helpers/forms/formFields/SelectField';
import { AlertMessage } from '../../../../helpers/AlertMessage';

import { renderEmploymentSkillBadgeUsage } from '../../utils/renderEmploymentSkillBadgeUsage';

import { DateUtils } from '../../../../utils/DateUtils';
import { FetchSkillBadgesCacheKey } from '../../../skillBadges/skillBadgesTypes';
import { useDeleteEmploymentSkillBadge } from '../../hooks/useDeleteEmploymentSkillBadge';
import { IconButton } from '../../../../helpers/buttons/IconButton';
import { DeleteIcon } from '../../../../icons/DeleteIcon';

interface AddEditEmploymentSkillBadgeFormProps {
  employmentSkillBadgeCacheKey: FetchEmploymentSkillBadgesCacheKey;
  skillBadgesCacheKey: FetchSkillBadgesCacheKey;
  employmentSkillBadge: AddEditEmploymentSkillBadgeFormEmploymentSkillBadge;
}

function AddEditEmploymentSkillBadgeForm({
  employmentSkillBadge,
  skillBadgesCacheKey,
  employmentSkillBadgeCacheKey
}: AddEditEmploymentSkillBadgeFormProps) {
  const {
    updateEmploymentSkillBadge,
    updateEmploymentSkillBadgeErrorMessage,
    updateEmploymentSkillBadgeIsLoading
  } = useUpdateEmploymentSkillBadge({
    employmentSkillBadgeId: employmentSkillBadge.id,
    cacheKeys: [
      employmentSkillBadgeCacheKey,
      skillBadgesCacheKey as unknown as FetchEmploymentSkillBadgesCacheKey
    ]
  });

  const {
    deleteEmploymentSkillBadge,
    deleteEmploymentSkillBadgeErrorMessage,
    deleteEmploymentSkillBadgeIsLoading
  } = useDeleteEmploymentSkillBadge({
    employmentSkillBadgeId: employmentSkillBadge.id,
    cacheKeys: [
      employmentSkillBadgeCacheKey,
      skillBadgesCacheKey as unknown as FetchEmploymentSkillBadgesCacheKey
    ]
  });

  const {
    control,
    validationErrors,
    addEditEmploymentSkillBadgeFormErrorMessage,
    addEditEmploymentSkillBadgeFormIsLoading,
    setAddEditEmploymentSkillBadgeFormValue,
    handleAddEditEmploymentSkillBadgeForm
  } = useAddEditEmploymentSkillBadgeForm({
    defaultValues: {
      usage: employmentSkillBadge.usage
    },
    onAddEditEmploymentSkillBadgeForm: async (data) => {
      await updateEmploymentSkillBadge(data);
    }
  });

  useEffect(() => {
    if (employmentSkillBadge) {
      setAddEditEmploymentSkillBadgeFormValue(
        EmploymentSkillBadgeFields.USAGE as 'usage',
        (employmentSkillBadge.usage === EmploymentSkillBadgeUsageTypes.NONE
          ? ''
          : employmentSkillBadge.usage) as EmploymentSkillBadgeUsage
      );
    }
  }, [employmentSkillBadge, setAddEditEmploymentSkillBadgeFormValue]);

  const defaultSkillUsage =
    employmentSkillBadge.usage === EmploymentSkillBadgeUsageTypes.NONE ||
    !employmentSkillBadge.usage
      ? undefined
      : {
          value: employmentSkillBadge.usage,
          label: renderEmploymentSkillBadgeUsage(employmentSkillBadge.usage)
        };

  return (
    <Stack
      as="form"
      spacing={4}
      onSubmit={handleAddEditEmploymentSkillBadgeForm}
    >
      <Flex gap={4} alignItems="center" flexWrap="wrap">
        <Flex alignItems="center" flex={1}>
          <InputField
            value={`${
              employmentSkillBadge.employment?.jobTitle?.name ||
              employmentSkillBadge.employment?.name
            } (${compact([
              DateUtils.formatMonth(employmentSkillBadge.employment?.startDate),
              employmentSkillBadge.employment?.currently
                ? 'PRESENT'
                : DateUtils.formatMonth(
                    employmentSkillBadge.employment?.endDate
                  )
            ]).join(' - ')})`}
            isReadOnly
          />
        </Flex>

        <Flex alignItems="center" width="150px">
          <SelectField
            control={control}
            placeholder="Skill Usage"
            name={EmploymentSkillBadgeFields.USAGE}
            options={map(employmentSkillBadgeUsageTypes, (usageType) => ({
              value: usageType,
              label: renderEmploymentSkillBadgeUsage(usageType)
            }))}
            defaultValue={defaultSkillUsage}
            errorMessage={validationErrors.usageValidationError}
            onChangeValue={() => {
              handleAddEditEmploymentSkillBadgeForm();
            }}
            unsetValue
          />
        </Flex>

        <IconButton
          size="small"
          aria-label={'remove employment'}
          hierarchy="link"
          onClick={deleteEmploymentSkillBadge}
          pointerEvents="auto"
          icon={
            deleteEmploymentSkillBadgeIsLoading ||
            updateEmploymentSkillBadgeIsLoading ||
            addEditEmploymentSkillBadgeFormIsLoading ? (
              <Spinner size="sm" />
            ) : (
              <DeleteIcon fill="red.400" w={5} h={5} />
            )
          }
          isLoading={
            deleteEmploymentSkillBadgeIsLoading ||
            updateEmploymentSkillBadgeIsLoading ||
            addEditEmploymentSkillBadgeFormIsLoading
          }
        />
      </Flex>

      <AlertMessage
        message={
          addEditEmploymentSkillBadgeFormErrorMessage ||
          updateEmploymentSkillBadgeErrorMessage ||
          deleteEmploymentSkillBadgeErrorMessage
        }
      />
    </Stack>
  );
}

export default AddEditEmploymentSkillBadgeForm;
