import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  StackDivider,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react';
import { Button } from '../../../../../../helpers/Button';
import { Heading } from '../../../../../../helpers/Heading';
import { LoadingSkeleton } from '../../../../../../helpers/LoadingSkeleton';
import { NextLinkHelper } from '../../../../../../helpers/NextLinkHelper';
import { RelativeDateHelper } from '../../../../../../helpers/RelativeDateHelper';
import { Text } from '../../../../../../helpers/Text';
import { IconButton } from '../../../../../../helpers/buttons/IconButton';
import { PureButtonWithRefHelper } from '../../../../../../helpers/buttons/PureButtonWithRefHelper';
import { ChevronDownIcon } from '../../../../../../icons/ChevronDownIcon';
import { ClockIcon } from '../../../../../../icons/ClockIcon';
import { EmailIcon } from '../../../../../../icons/EmailIcon';
import { JobsIcon } from '../../../../../../icons/JobsIcon';
import { LinkedInIcon } from '../../../../../../icons/LinkedInIcon';
import { MessageIcon } from '../../../../../../icons/MessageIcon';
import { PhoneIcon } from '../../../../../../icons/PhoneIcon';
import { IsFetched } from '../../../../../../types';
import { DepartmentNanoId } from '../../../../../departments/departmentsTypes';
import { HiringPortalSourcingRoutes } from '../../../../../hiringPortal/HirngPortalSourcingRoutes';
import { ViewJobCandidateModalPhoneSidebarHelper } from '../../../../../jobs/components/modals/ViewJobCandidateModal/helpers/ViewJobCandidateModalPhoneSidebarHelper';
import { JobLocationHelper } from '../../../../../jobs/helpers/JobLocationHelper';
import { JobNanoId } from '../../../../../jobs/jobsTypes';
import { JobCandidateDetailsJobCandidate } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsAddComment } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsAddComment';
import { ViewJobCandidateDetailsBio } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsBio';
import { ViewJobCandidateDetailsComposeEmail } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsComposeEmail';
import { ViewJobCandidateDetailsHeaderMenuJobs } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsHeaderMenuJobs';
import { ViewJobCandidateDetailsJobDetailsJob } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsJobDetails';
import { ViewJobCandidateDetailsJobHiringTeam } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsJobHiringTeam';
import { useViewJobCandidateDetailsSendAssessmentContext } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/contexts/ViewJobCandidateDetailsSendAssessmentContext';
import { useViewJobCandidateDetailsSubmissionContext } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/contexts/ViewJobCandidateDetailsSubmissionContext';
import { renderJobEmployment } from '../../../../../jobs/utils/renderJobEmployment';
import { JobPriorityDropdown } from '../../../../../updates/components/JobPriorityDropdown';
import { SourcingRoutes } from '../../../../SourcingRoutes';

interface SourcingTalentProfileHeaderMenuProps {
  jobCandidate: JobCandidateDetailsJobCandidate;
  hasScrolled: boolean;
  requiresCredit: boolean;
  job: ViewJobCandidateDetailsJobDetailsJob;
  departmentNanoId: DepartmentNanoId;
  jobIsFetched: IsFetched;
  isHiringPortalPage?: boolean;
  totalProspects?: number;
}

export default function SourcingTalentProfileHeaderMenu({
  jobCandidate,
  hasScrolled,
  requiresCredit,
  job,
  jobIsFetched,
  totalProspects,
  isHiringPortalPage,
  departmentNanoId
}: SourcingTalentProfileHeaderMenuProps) {
  const { isCandidateSubmissionOpen, isCandidateEvaluationOpen } =
    useViewJobCandidateDetailsSubmissionContext();

  const { isSendEmailAssessmentOpen } =
    useViewJobCandidateDetailsSendAssessmentContext();

  const isRightSidebarOpen =
    isCandidateSubmissionOpen ||
    isSendEmailAssessmentOpen ||
    isCandidateEvaluationOpen;

  const {
    isOpen: isOpenPhoneCallActivity,
    onOpen: onOpenPhoneCallActivity,
    onToggle: onTogglePhoneCallActivity
  } = useDisclosure();

  const jobDetailsRoute = (jobNanoId: JobNanoId) => {
    return isHiringPortalPage
      ? HiringPortalSourcingRoutes.viewJobDetails(
          departmentNanoId,
          jobCandidate?.nanoId,
          jobNanoId
        )
      : SourcingRoutes.viewJobDetails(
          departmentNanoId,
          jobCandidate?.nanoId,
          jobNanoId
        );
  };

  return (
    <>
      <Flex
        h={14}
        top={0}
        gap={6}
        zIndex={89}
        overflow="hidden"
        position={isRightSidebarOpen ? 'relative' : 'sticky'}
        bg={isRightSidebarOpen ? 'transparent' : 'white'}
        pl={isRightSidebarOpen ? { base: 4, xl: 12 } : 4}
        pr={4}
      >
        {!isRightSidebarOpen && (
          <Flex
            top={0}
            flexDir="column"
            position="relative"
            transitionDuration="slow"
            w={{ base: '274px', xl: '304px' }}
          >
            <Flex
              flex={1}
              alignItems="center"
              borderRight="1px solid"
              borderColor="gray.200"
              gap={2}
              pr={3}
            >
              <Heading level="h2" color="gray.900" noOfLines={1}>
                Prospects
              </Heading>
              <Heading level="h4" color="gray.900">
                {totalProspects}
              </Heading>
            </Flex>
          </Flex>
        )}

        <Flex
          h={28}
          top={0}
          flex={1}
          flexDir="column"
          position="relative"
          transitionDuration="slow"
          transform={hasScrolled ? 'translate(0, -50%)' : undefined}
        >
          <Flex
            gap={4}
            opacity={hasScrolled ? 0 : 1}
            w="100%"
            flex={1}
            alignItems="center"
            flexWrap="nowrap"
          >
            <LoadingSkeleton count={1} loaded={jobIsFetched}>
              {job ? (
                <HStack
                  spacing={4}
                  divider={<StackDivider />}
                  flexWrap="nowrap"
                >
                  <ViewJobCandidateDetailsHeaderMenuJobs
                    jobCandidate={jobCandidate}
                    job={job}
                    jobRoute={(jobNanoId) =>
                      isHiringPortalPage
                        ? HiringPortalSourcingRoutes.viewPerson(
                            departmentNanoId,
                            jobCandidate?.nanoId,
                            jobNanoId
                          )
                        : SourcingRoutes.viewPerson(
                            departmentNanoId,
                            jobCandidate?.nanoId,
                            jobNanoId
                          )
                    }
                  />

                  <Flex gap={2} alignItems="center" shrink={0}>
                    <Text>Job Priority</Text>
                    <JobPriorityDropdown priority="high" />
                  </Flex>

                  <JobLocationHelper job={job} withIcon />

                  <HStack spacing={2} sx={{ flexShrink: '0' }}>
                    <JobsIcon w="20px" h="20px" color="gray.500" />

                    <Text>{renderJobEmployment(job.employment) || '-'}</Text>
                  </HStack>

                  <HStack spacing={2} sx={{ flexShrink: '0' }}>
                    <ClockIcon w="20px" h="20px" color="gray.500" />

                    <Text>
                      <RelativeDateHelper date={job.createdAt as string} />
                    </Text>
                  </HStack>

                  <NextLinkHelper href={jobDetailsRoute(job.nanoId)} passHref>
                    <PureButtonWithRefHelper
                      as="a"
                      size="medium"
                      hierarchy="link"
                      i18nText="Job Details"
                      _hover={{ textDecoration: 'none' }}
                      sx={{ flexShrink: '0' }}
                    />
                  </NextLinkHelper>

                  <ViewJobCandidateDetailsJobHiringTeam job={job} />
                </HStack>
              ) : null}
            </LoadingSkeleton>
          </Flex>

          <Flex
            h={14}
            gap={2}
            alignItems="center"
            top={hasScrolled ? 0 : '100%'}
            transitionDuration="slow"
            zIndex={999}
          >
            <HStack spacing={4} divider={<StackDivider />}>
              <HStack spacing={2}>
                {jobCandidate && job ? (
                  <ViewJobCandidateDetailsBio
                    requiresCredit={requiresCredit}
                    jobCandidate={jobCandidate}
                    isCollapsed
                    job={job}
                    isHiringPortalPath={isHiringPortalPage}
                  />
                ) : null}

                <Flex
                  w={6}
                  h={6}
                  bg="#069"
                  alignItems="center"
                  borderRadius="full"
                  justifyContent="center"
                >
                  <LinkedInIcon borderRadius="full" w={3.5} h={3.5} />
                </Flex>
              </HStack>

              {job ? (
                <HStack spacing={4}>
                  <ViewJobCandidateDetailsHeaderMenuJobs
                    jobCandidate={jobCandidate}
                    job={job}
                    jobRoute={(jobNanoId) =>
                      isHiringPortalPage
                        ? HiringPortalSourcingRoutes.viewPerson(
                            departmentNanoId,
                            jobCandidate?.nanoId,
                            jobNanoId
                          )
                        : SourcingRoutes.viewPerson(
                            departmentNanoId,
                            jobCandidate?.nanoId,
                            jobNanoId
                          )
                    }
                  />

                  <JobLocationHelper job={job} withIcon />
                </HStack>
              ) : null}
            </HStack>

            <Flex gap={3} alignItems="center" ml="auto">
              <ViewJobCandidateDetailsComposeEmail
                trigger={
                  <Tooltip label="Email" textTransform="capitalize" hasArrow>
                    <IconButton
                      h={6}
                      w={6}
                      minW={0}
                      minH={0}
                      value="email"
                      display="flex"
                      aria-label=""
                      alignItems="center"
                      borderRadius="full"
                      justifyContent="center"
                      icon={<EmailIcon w={3.5} h={3.5} />}
                      hierarchy="tertiary"
                    />
                  </Tooltip>
                }
              />

              <ViewJobCandidateDetailsAddComment
                trigger={
                  <Tooltip label="Comment" textTransform="capitalize" hasArrow>
                    <IconButton
                      h={6}
                      w={6}
                      minW={0}
                      minH={0}
                      value="comment"
                      display="flex"
                      aria-label=""
                      alignItems="center"
                      borderRadius="full"
                      justifyContent="center"
                      icon={<MessageIcon w={3.5} h={3.5} />}
                      hierarchy="tertiary"
                    />
                  </Tooltip>
                }
              />

              <HStack
                spacing={0}
                overflow="hidden"
                border="1px solid"
                borderColor="gray.300"
                borderRadius="base"
                divider={<StackDivider borderColor="gray.200" />}
              >
                <Button
                  h={7}
                  px={4}
                  hierarchy="tertiary"
                  size="extra-small"
                  borderRadius="none"
                  border="none"
                  onClick={onOpenPhoneCallActivity}
                  leftIcon={<PhoneIcon w={3} h={3} fill="gray.500" />}
                >
                  Call
                </Button>

                <IconButton
                  h={7}
                  border="none"
                  display="flex"
                  aria-label=""
                  size="extra-small"
                  alignItems="center"
                  borderRadius="none"
                  hierarchy="tertiary"
                  justifyContent="center"
                  icon={<ChevronDownIcon w={3.5} h={3.5} fill="gray.500" />}
                />
              </HStack>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Drawer
        size="md"
        isOpen={isOpenPhoneCallActivity}
        onClose={onTogglePhoneCallActivity}
      >
        <DrawerOverlay />
        <DrawerContent p={0}>
          <DrawerBody p={0}>
            {jobCandidate ? (
              <ViewJobCandidateModalPhoneSidebarHelper
                fullWidth
                withoutBorder
                jobCandidate={jobCandidate}
                toggleFollowUpEmail={console.log}
                isSidebarOpen={isOpenPhoneCallActivity}
                sidebarToggle={onTogglePhoneCallActivity}
                personalPhoneNumber={jobCandidate.phone}
                companyPhoneNumber={jobCandidate.companyPhone}
              />
            ) : null}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
