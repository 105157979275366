import filter from 'lodash/filter';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

import { DateTimeType, DateUtils } from '../../../../utils/DateUtils';
import { EmploymentTypes } from '../../../employments/employmentsTypes';
import { AddEditEmploymentSkillBadgeFormEmploymentSkillBadge } from '../../components/AddEditEmploymentSkillBadgeForm';
import {
  EmploymentSkillBadgeUsage,
  EmploymentSkillBadgeUsageTypes
} from '../../employmentSkillBadgesTypes';

const getUsagePriority = (usage: EmploymentSkillBadgeUsage): number => {
  switch (usage) {
    case EmploymentSkillBadgeUsageTypes.DAILY_USER:
      return 3;
    case EmploymentSkillBadgeUsageTypes.WEEKLY_USER:
      return 2;
    case EmploymentSkillBadgeUsageTypes.MONTHLY_USER:
      return 1;
    default:
      return 0;
  }
};

const experienceFromUsage = (
  noOfYears: number,
  usage: EmploymentSkillBadgeUsage
) => {
  switch (usage) {
    case EmploymentSkillBadgeUsageTypes.DAILY_USER:
      return noOfYears / 1;
    case EmploymentSkillBadgeUsageTypes.WEEKLY_USER:
      return noOfYears / 2;
    case EmploymentSkillBadgeUsageTypes.MONTHLY_USER:
      return noOfYears / 5;
    default:
      return noOfYears;
  }
};

interface TimeSegment {
  startDate: DateTimeType;
  endDate: DateTimeType;
  isPartTime: boolean;
  usage: EmploymentSkillBadgeUsage;
}

const mergeOverlappingSegments = (segments: TimeSegment[]): TimeSegment[] => {
  if (segments.length <= 1) return segments;

  // Sort segments by start date
  const sortedSegments = segments.sort(
    (a, b) => a.startDate.toMillis() - b.startDate.toMillis()
  );

  const merged: TimeSegment[] = [];
  let current = sortedSegments[0];

  for (let i = 1; i < sortedSegments.length; i++) {
    const next = sortedSegments[i];

    // Check for overlap
    if (current.endDate >= next.startDate) {
      // For overlapping periods, choose the one with better priority
      const currentPriority =
        (!current.isPartTime ? 1 : 0) + getUsagePriority(current.usage);
      const nextPriority =
        (!next.isPartTime ? 1 : 0) + getUsagePriority(next.usage);

      if (nextPriority > currentPriority) {
        // Split into three segments if necessary
        if (current.startDate < next.startDate) {
          merged.push({
            ...current,
            endDate: next.startDate
          });
        }

        merged.push({
          ...next,
          startDate: next.startDate,
          endDate:
            current.endDate < next.endDate ? current.endDate : next.endDate
        });

        if (next.endDate > current.endDate) {
          current = next;
        }
      } else {
        // Keep current for the overlapping period
        if (next.endDate > current.endDate) {
          current = {
            ...current,
            endDate: next.endDate
          };
        }
      }
    } else {
      merged.push(current);
      current = next;
    }
  }

  merged.push(current);
  return merged;
};

export default async function calculateSkillExperience(
  employmentSkillBadges: AddEditEmploymentSkillBadgeFormEmploymentSkillBadge[]
) {
  const today = DateUtils.startOfToday();

  const skillBadgesWithUsageAndStartDate = filter(
    employmentSkillBadges,
    (badge) => badge.usage && badge.employment?.startDate
  ) as AddEditEmploymentSkillBadgeFormEmploymentSkillBadge[];

  // Create time segments for each employment period
  const timeSegments: TimeSegment[] = map(
    skillBadgesWithUsageAndStartDate,
    (badge) => {
      const startDate = DateUtils.calendarDay(
        badge.employment?.startDate
      ) as DateTimeType;
      const endDate = badge.employment?.currently
        ? today
        : (DateUtils.calendarDay(badge.employment?.endDate) as DateTimeType);

      return {
        startDate,
        endDate,
        isPartTime:
          badge.employment?.employmentType === EmploymentTypes.PART_TIME,
        usage: badge.usage as EmploymentSkillBadgeUsage
      };
    }
  );

  // Merge overlapping segments
  const mergedSegments = mergeOverlappingSegments(timeSegments);

  // Calculate experience for each non-overlapping segment
  const experience = reduce(
    mergedSegments,
    (acc, segment) => {
      const yearDifference = (segment.endDate
        ?.diff(segment.startDate, 'years')
        ?.toObject() || {}) as { years: number };

      const noOfYears = segment.isPartTime
        ? yearDifference.years / 2
        : yearDifference.years;

      const byUsage = experienceFromUsage(noOfYears, segment.usage);
      return acc + byUsage;
    },
    0
  );

  return experience;
}
