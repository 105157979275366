import { useDisclosure } from '@chakra-ui/hooks';
import { Flex, Stack } from '@chakra-ui/layout';
import { Popover, PopoverAnchor } from '@chakra-ui/popover';
import { Portal } from '@chakra-ui/react';
import every from 'lodash/every';
import size from 'lodash/size';
import truncate from 'lodash/truncate';
import { Fragment, useCallback } from 'react';

import {
  MayBeSkillBadgeEmploymentSkillBadges,
  MayBeSkillBadgeSkillLicense,
  SkillBadgeCertified,
  SkillBadgeExperience,
  SkillBadgeExperienceType,
  SkillBadgeRatingType,
  SkillBadgeRequirements,
  SkillBadgeSkill
} from '../../skillBadgesTypes';

import { CertifiedIcon } from '../../../../icons/CertifiedIcon';

import { EditDeleteButtonGroup } from '../../../../helpers/EditDeleteButtonGroup';
import { Text } from '../../../../helpers/Text';

import { renderSkillBadgeExperience } from '../../utils/renderSkillBadgeExperience';
import { renderSkillBadgeRatingBackground } from '../../utils/renderSkillBadgeRatingBackground';
import renderMostRecentJob from '../../../jobs/utils/renderMostRecentJob/renderMostRecentJob';

import { SkillBadgePopoverContent } from '../../components/SkillBadgePopoverContent';

interface SkillBadgeProps {
  withoutEditButton?: boolean;
  withoutDeleteButton?: boolean;
  withoutPopover?: boolean;
  name: SkillBadgeSkill['name'] | null;
  rating: SkillBadgeRatingType;
  experience: SkillBadgeExperience;
  experienceType: SkillBadgeExperienceType;
  certified: SkillBadgeCertified;
  requirements: SkillBadgeRequirements;
  skillLicense: MayBeSkillBadgeSkillLicense;
  employmentSkillBadges?: MayBeSkillBadgeEmploymentSkillBadges;
  onEdit: () => void;
  onDelete: () => void;
  isEditing?: boolean;
  allowEditing?: boolean;
  withoutPortal?: boolean;
  isApplicantWorkflow?: boolean;
  maxSkillBadgesToShow?: number;
  isJobCandidate?: boolean;
}

function SkillBadge({
  withoutEditButton,
  withoutDeleteButton,
  withoutPopover,
  name,
  experience,
  experienceType,
  rating,
  certified,
  skillLicense,
  employmentSkillBadges,
  onEdit,
  onDelete,
  isEditing,
  allowEditing = true,
  withoutPortal,
  isApplicantWorkflow,
  maxSkillBadgesToShow,
  isJobCandidate
}: SkillBadgeProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleEdit = useCallback(() => {
    return allowEditing ? onEdit() : null;
  }, [onEdit, allowEditing]);

  const experienceLabel = renderSkillBadgeExperience(experience);

  const showApplicantSkillBadge = isJobCandidate
    ? true
    : !!size(employmentSkillBadges) &&
      every(
        employmentSkillBadges,
        (employmentSkillBadge) => !!employmentSkillBadge.usage
        // && employmentSkillBadge.usage !== '' //commented this out to show all skills in the assessment skills page
      );

  const recentUsage = renderMostRecentJob(employmentSkillBadges)?.usage;

  return isApplicantWorkflow && !showApplicantSkillBadge ? null : (
    <Fragment>
      <Popover
        returnFocusOnClose={false}
        onClose={onClose}
        isOpen={isOpen}
        placement="top-start"
        offset={[0, 8]}
        isLazy
      >
        <PopoverAnchor>
          <Stack spacing={0.5} direction="row">
            <Flex
              as="button"
              bg={
                isEditing
                  ? 'primary.500'
                  : renderSkillBadgeRatingBackground(rating)?.main
              }
              borderRadius={4}
              alignItems="center"
              overflow="hidden"
              type="button"
              color="white"
              onClick={handleEdit}
              onMouseEnter={onOpen}
              onMouseLeave={onClose}
            >
              <Flex px={2} alignItems="center">
                {(certified || skillLicense) && <CertifiedIcon mr={1} />}
                <Text textStyle="body1Medium" textTransform="capitalize">
                  {maxSkillBadgesToShow
                    ? truncate(name || '', {
                        length: 15,
                        omission: '...'
                      })
                    : name}
                </Text>
              </Flex>
              {experienceLabel ? (
                <Flex
                  bg={
                    isEditing
                      ? 'primary.500'
                      : renderSkillBadgeRatingBackground(rating)?.experience
                  }
                  borderLeft="1px solid"
                  borderLeftColor={
                    isEditing
                      ? 'white'
                      : renderSkillBadgeRatingBackground(rating)?.experience
                  }
                  px={2}
                >
                  <Text textStyle="body1Medium">{experienceLabel}</Text>
                </Flex>
              ) : null}
            </Flex>

            {allowEditing && withoutEditButton && !withoutDeleteButton ? (
              <EditDeleteButtonGroup
                withoutEditButton={withoutEditButton}
                isDisabled={isEditing}
                onDelete={onDelete}
              />
            ) : null}
            {allowEditing && !withoutEditButton && !withoutDeleteButton ? (
              <EditDeleteButtonGroup
                isDisabled={isEditing}
                onDelete={onDelete}
                onEdit={onEdit}
              />
            ) : null}
            {allowEditing && withoutDeleteButton && !withoutEditButton ? (
              <EditDeleteButtonGroup
                isDisabled={isEditing}
                onEdit={onEdit}
                withoutDeleteButton={withoutDeleteButton}
              />
            ) : null}
          </Stack>
        </PopoverAnchor>

        {withoutPopover ? null : (
          <>
            {withoutPortal ? (
              <SkillBadgePopoverContent
                name={name}
                rating={rating}
                experience={experience}
                skillLicense={skillLicense}
                experienceType={experienceType}
                recentUsage={recentUsage}
                certified={certified}
              />
            ) : (
              <Portal>
                <SkillBadgePopoverContent
                  name={name}
                  rating={rating}
                  experience={experience}
                  skillLicense={skillLicense}
                  experienceType={experienceType}
                  recentUsage={recentUsage}
                  certified={certified}
                />
              </Portal>
            )}
          </>
        )}
      </Popover>
    </Fragment>
  );
}

export default SkillBadge;
