import { HStack, StackDivider } from '@chakra-ui/react';
import { PureButtonWithRefHelper } from '../../../../../../../../helpers/buttons/PureButtonWithRefHelper';
import { Heading } from '../../../../../../../../helpers/Heading';
import { NextLinkHelper } from '../../../../../../../../helpers/NextLinkHelper';
import { RelativeDateHelper } from '../../../../../../../../helpers/RelativeDateHelper';
import { Text } from '../../../../../../../../helpers/Text';
import { ClockIcon } from '../../../../../../../../icons/ClockIcon';
import { JobsIcon } from '../../../../../../../../icons/JobsIcon';
import { JobResumeRecruiterNanoId } from '../../../../../../../jobResumeRecruiters/jobResumeRecruitersTypes';
import { JobLocationHelper } from '../../../../../../../jobs/helpers/JobLocationHelper';
import { ViewJobCandidateDetailsJobHiringTeam } from '../../../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsJobHiringTeam';
import { renderJobEmployment } from '../../../../../../../jobs/utils/renderJobEmployment';
import { renderJobTitle } from '../../../../../../../jobs/utils/renderJobTitle';
import { CareerSiteRoutes } from '../../../../../../CareerSiteRoutes';
import { CareerSiteApplyProfilePageJob } from '../../../../components/CareerSiteApplyProfilePage/CareerSiteApplyProfilePage.types';

interface CareerSiteApplyProfilePageJobHeaderProps {
  job: CareerSiteApplyProfilePageJob;
  jobResumeRecruiterNanoId: JobResumeRecruiterNanoId;
}

export default function CareerSiteApplyProfilePageJobHeader({
  job,
  jobResumeRecruiterNanoId
}: CareerSiteApplyProfilePageJobHeaderProps) {
  return (
    <HStack
      pb={4}
      pt={{ base: 4, lg: 0 }}
      spacing={0}
      gap={{ base: 2, md: 4 }}
      divider={<StackDivider />}
      flexWrap="wrap"
    >
      {renderJobTitle(job) && (
        <Heading
          level="h2"
          fontSize="md"
          fontWeight="semibold"
          lineHeight={1.5}
        >
          {renderJobTitle(job)}
        </Heading>
      )}

      <JobLocationHelper job={job} withIcon />

      {job.employment && (
        <HStack spacing={2}>
          <JobsIcon w="20px" h="20px" color="gray.500" />

          <Text>{renderJobEmployment(job.employment) || '-'}</Text>
        </HStack>
      )}

      <HStack spacing={2}>
        <ClockIcon w="20px" h="20px" color="gray.500" />

        <Text>
          <RelativeDateHelper date={job.createdAt as string} />
        </Text>
      </HStack>

      <NextLinkHelper
        href={CareerSiteRoutes.jobDetailsActual(
          job.nanoId,
          jobResumeRecruiterNanoId
        )}
        passHref
      >
        <PureButtonWithRefHelper
          as="a"
          size="medium"
          hierarchy="link"
          i18nText="Job Details"
          _hover={{ textDecoration: 'none' }}
        />
      </NextLinkHelper>

      <ViewJobCandidateDetailsJobHiringTeam job={job} />
    </HStack>
  );
}
