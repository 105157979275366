import {
  ReactHookFormFieldError,
  useReactHookForm
} from '../../../../../../../common/hooks/base/useReactHookForm';

import {
  WorkflowStageTaskApplication,
  WorkflowStageTaskAutomation,
  WorkflowStageTaskAutomations,
  WorkflowStageTaskDueDate,
  WorkflowStageTaskFields,
  WorkflowStageTaskName,
  WorkflowStageTaskReminder
} from '../../../../../workflowStageTasksTypes';

import { useAddWorkflowStageTaskFormValidationRules } from '../useAddWorkflowStageTaskFormValidationRules';

import {
  AddWorkflowStageTaskFormData,
  OnAddWorkflowStageTaskForm
} from '../../AddWorkflowStageTaskForm.types';

const defaultAddWorkflowStageTaskFormValues: AddWorkflowStageTaskFormData = {
  name: '' as WorkflowStageTaskName,
  application: '' as WorkflowStageTaskApplication,
  dueDate: '' as WorkflowStageTaskDueDate,
  automation: WorkflowStageTaskAutomations.NONE as WorkflowStageTaskAutomation,
  reminder: '' as WorkflowStageTaskReminder
};

interface AddWorkflowStageTaskFormOptions {
  defaultValues?: Partial<AddWorkflowStageTaskFormData>;
  onAddWorkflowStageTaskForm: OnAddWorkflowStageTaskForm;
}

function useAddWorkflowStageTaskForm({
  defaultValues = {},
  onAddWorkflowStageTaskForm
}: AddWorkflowStageTaskFormOptions) {
  const {
    control,
    watch,
    errors,
    isLoading,
    isValid,
    isSubmitted,
    isSubmitSuccessful,
    errorMessage,
    register,
    resetForm,
    handleSubmitReactHookForm,
    setValue
  } = useReactHookForm<AddWorkflowStageTaskFormData>({
    defaultValues: {
      ...defaultAddWorkflowStageTaskFormValues,
      ...defaultValues
    }
  });

  const { nameRules, applicationRules, dueDateRules, automationRules } =
    useAddWorkflowStageTaskFormValidationRules();

  return {
    control,
    watch,
    validationErrors: {
      nameValidationError: (errors.name as ReactHookFormFieldError)?.message,
      applicationValidationError: (
        errors.application as ReactHookFormFieldError
      )?.message,
      dueDateValidationError: (errors.dueDate as ReactHookFormFieldError)
        ?.message,
      reminderValidationError: (errors.reminder as ReactHookFormFieldError)
        ?.message,
      automationValidationError: (errors.automation as ReactHookFormFieldError)
        ?.message
    },
    addWorkflowStageTaskFormIsLoading: isLoading,
    addWorkflowStageTaskFormIsValid: isValid,
    addWorkflowStageTaskFormIsSubmitted: isSubmitted,
    addWorkflowStageTaskFormIsSubmitSuccessful: isSubmitSuccessful,
    addWorkflowStageTaskFormErrorMessage: errorMessage,
    resetAddWorkflowStageTaskForm: resetForm,
    setAddWorkflowStageTaskFormValue: setValue,
    registerFields: {
      registerName: register(WorkflowStageTaskFields.NAME, nameRules),
      registerApplication: register(
        WorkflowStageTaskFields.APPLICATION,
        applicationRules
      ),
      registerDueDate: register(WorkflowStageTaskFields.DUE_DATE, dueDateRules),
      registerAutomation: register(
        WorkflowStageTaskFields.AUTOMATION,
        automationRules
      )
    },
    handleAddWorkflowStageTaskForm: handleSubmitReactHookForm({
      onSubmit: (data) =>
        onAddWorkflowStageTaskForm?.({
          name: data.name as WorkflowStageTaskName,
          application: data.application as WorkflowStageTaskApplication,
          dueDate: data.dueDate as WorkflowStageTaskDueDate,
          reminder: data.reminder as WorkflowStageTaskReminder,
          automation: data.automation as WorkflowStageTaskAutomation
        })
    })
  };
}

export default useAddWorkflowStageTaskForm;
