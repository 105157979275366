import { Box } from '@chakra-ui/react';
import { ReferencesIcon } from '../../../../../../../../icons/ReferencesIcon';
import { ViewJobCandidateDetailsAssessmentListItem } from '../ViewJobCandidateDetailsAssessmentListItem';
import { ViewJobCandidateDetailsAssessmentMyNxmoovForm } from './components/ViewJobCandidateDetailsAssessmentMyNxmoovForm';
import { ViewJobCandidateDetailsAssessmentMyNxmoovFormJobCandidateType } from './ViewJobCandidateDetailsAssessmentMyNxmoov.types';

interface ViewJobCandidateDetailsAssessmentMyNxmoovProps {
  disabled?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
  isOpenDetails: boolean;
  defaultChecked?: boolean;
  handleToggle?: () => void;
  toggleDetails?: () => void;
  jobCandidate: ViewJobCandidateDetailsAssessmentMyNxmoovFormJobCandidateType;
  markCompleted?: () => void;
  addButtonLabel?: string;
  isCompleted?: boolean;
}

export default function ViewJobCandidateDetailsAssessmentMyNxmoov({
  onSave,
  onCancel,
  disabled,
  isCompleted,
  handleToggle,
  jobCandidate,
  isOpenDetails,
  toggleDetails,
  addButtonLabel,
  defaultChecked
}: ViewJobCandidateDetailsAssessmentMyNxmoovProps) {
  return (
    <ViewJobCandidateDetailsAssessmentListItem
      icon={ReferencesIcon}
      isOpenDetails={isOpenDetails}
      toggleDetails={toggleDetails}
      handleToggle={handleToggle}
      defaultChecked={defaultChecked}
      title="My Nxmoov"
      addButtonLabel={addButtonLabel}
      isCompleted={isCompleted}
    >
      <ViewJobCandidateDetailsAssessmentMyNxmoovForm
        disableEdit={disabled}
        onSave={onSave}
        onCancel={onCancel}
        buttonSize="medium"
        jobCandidate={jobCandidate}
        alignButtonsToRight
        withoutHeading
      />

      {disabled && (
        <Box
          w="full"
          h="full"
          top={0}
          left={0}
          pos="absolute"
          bg="transparent"
          cursor="not-allowed"
        />
      )}
    </ViewJobCandidateDetailsAssessmentListItem>
  );
}
