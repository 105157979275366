const paidTimeOffRules = {
  required: 'Paid time off are required'
};

const minimumDesiredPayRules = {
  required: 'Minimum desired pay is required'
};

const maximumDesiredPayRules = {
  required: 'Maximum desired pay is required'
};

const newJobStartAvailabilityRules = {
  required: 'New job start availability is required'
};

const activelyInterviewingRules = {
  required: 'Actively interviewing is required'
};

const workAuthorizationRules = {
  required: 'Work authorization is required'
};

function useEditJobCandidateMyNxmoovFormValidationRules() {
  return {
    paidTimeOffRules,
    minimumDesiredPayRules,
    maximumDesiredPayRules,
    workAuthorizationRules,
    activelyInterviewingRules,
    newJobStartAvailabilityRules
  };
}

export default useEditJobCandidateMyNxmoovFormValidationRules;
