import { Icon, IconProps } from '@chakra-ui/icon';

const AddPersonIcon = ({ w = '16px', h = '16px', ...props }: IconProps) => (
  <Icon viewBox="0 0 20 20" w={w} h={h} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4v1.333c0 2.206 1.794 4 4 4s4-1.794 4-4V4c0-2.206-1.794-4-4-4S4 1.794 4 4ZM13.333 14.672c-1.102 0-2-.898-2-2-1.102 0-2-.898-2-2H4.667A4.672 4.672 0 0 0 0 15.338c0 .369.299.667.667.667h14.666A.667.667 0 0 0 16 15.34c0-.892-.255-1.722-.69-2.431a1.995 1.995 0 0 1-1.977 1.764Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.33 10h-1.333V8.667a.667.667 0 0 0-1.333 0V10h-1.333a.667.667 0 0 0 0 1.333h1.333v1.334a.667.667 0 0 0 1.333 0v-1.334h1.334a.667.667 0 0 0 0-1.333Z"
      fill="currentColor"
    />
  </Icon>
);

export default AddPersonIcon;
