import {
  Box,
  Flex,
  HStack,
  StackDivider,
  useDisclosure
} from '@chakra-ui/react';
import startCase from 'lodash/startCase';
import { MouseEvent } from 'react';
import { useChakraToast } from '../../../../../../../../helpers/useChakraToast';
import { JobCandidateWorkflowStagesCache } from '../../../../../../../jobCandidateWorkflowStages/JobCandidateWorkflowStagesCache';
import usePaginatedJobCandidateWorkflowStage from '../../../../../../../jobCandidateWorkflowStages/hooks/usePaginatedJobCandidateWorkflowStage/usePaginatedJobCandidateWorkflowStage';
import {
  FetchJobCandidateWorkflowStagesCacheKey,
  FetchJobCandidateWorkflowStagesEnabled,
  FetchJobCandidateWorkflowStagesFilters,
  JobCandidateWorkflowStageFields
} from '../../../../../../../jobCandidateWorkflowStages/jobCandidateWorkflowStagesTypes';
import {
  fetchJobCandidateWorkflowStagesQuery,
  FetchJobCandidateWorkflowStagesResponse
} from '../../../../../../../jobCandidateWorkflowStages/queries/fetchJobCandidateWorkflowStages.query';
import {
  JobCandidateFirstName,
  JobCandidateId,
  JobCandidateJobTitle,
  JobCandidateLastName,
  JobCandidateName,
  JobCandidateNanoId,
  JobCandidateSearchTypeLabel,
  MayBeJobCandidateCompany,
  MayBeJobCandidateImage
} from '../../../../../../../jobCandidates/jobCandidatesTypes';
import { WorkflowStagesCache } from '../../../../../../../workflowStages/WorkflowStagesCache';
import { usePaginatedWorkflowStages } from '../../../../../../../workflowStages/hooks/usePaginatedWorkflowStages';
import {
  fetchWorkflowStagesQuery,
  FetchWorkflowStagesResponse
} from '../../../../../../../workflowStages/queries/fetchWorkflowStages.query';
import {
  FetchWorkflowStagesFilters,
  FetchWorkflowStagesSort,
  WorkflowStageFields
} from '../../../../../../../workflowStages/workflowStagesTypes';
import {
  JobId,
  JobJobTitle,
  JobName,
  JobNanoId,
  MayBeJobCities,
  MayBeJobCity
} from '../../../../../../jobsTypes';
import {
  JobCandidateDetailsJobCandidate,
  JobCandidateDetailsPageWorkflowStage
} from '../../../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsStageSection } from '../.././components/ViewJobCandidateDetailsStageSection';
import { ViewJobCandidateDetailsTasksSection } from '../.././components/ViewJobCandidateDetailsTasksSection';
import { ViewJobCandidateDetailsTaskRequireAttention } from '../../components/ViewJobCandidateDetailsTaskRequireAttention';
import { ViewJobCandidateInterviewScoreCardModal } from '../../components/ViewJobCandidateInterviewScoreCardModal';
import { ViewJobCandidateDetailsAdvanceDiscontinue } from '../ViewJobCandidateDetailsAdvanceDiscontinue';

interface ViewJobCandidateDetailsStageTasksManagerWrapperProps {
  jobCandidateNanoId: JobCandidateNanoId;
  requiresCredit?: boolean;
  job: {
    id: JobId;
    nanoId: JobNanoId;
    name: JobName;
    jobTitle: JobJobTitle;
    cities: MayBeJobCities;
    city: MayBeJobCity;
  };
  jobCandidate: {
    id: JobCandidateId;
    nanoId: JobCandidateNanoId;
    name: JobCandidateName;
    firstName?: JobCandidateFirstName;
    lastName?: JobCandidateLastName;
    jobTitle: JobCandidateJobTitle;
    company: MayBeJobCandidateCompany;
    image: MayBeJobCandidateImage;
    searchTypeLabel: JobCandidateSearchTypeLabel;
    workflowStage: JobCandidateDetailsPageWorkflowStage;
  };
  jobCandidateDetails?: JobCandidateDetailsJobCandidate;
  isHiringPortalPath?: boolean;
  isCentered?: boolean;
}

export default function ViewJobCandidateDetailsStageTasksManagerWrapper({
  job,
  isCentered,
  jobCandidate,
  isHiringPortalPath,
  jobCandidateNanoId,
  requiresCredit,
  jobCandidateDetails
}: ViewJobCandidateDetailsStageTasksManagerWrapperProps) {
  const toast = useChakraToast();

  const {
    isOpen: isOpenScoreCard,
    onOpen: openScoreCard,
    onClose: closeScoreCard
  } = useDisclosure();

  const { workflowStages } =
    usePaginatedWorkflowStages<FetchWorkflowStagesResponse>({
      query: fetchWorkflowStagesQuery,
      cacheKey: WorkflowStagesCache.jobIndexCacheKey(job.nanoId),
      initialFilters: {
        [WorkflowStageFields.JOB_ID]: { operator: 'eq', value: job.id }
      } as unknown as FetchWorkflowStagesFilters,
      initialSort: {
        [WorkflowStageFields.ARRANGEMENT]: { ascending: true }
      } as unknown as FetchWorkflowStagesSort
    });

  const { jobCandidateWorkflowStages } =
    usePaginatedJobCandidateWorkflowStage<FetchJobCandidateWorkflowStagesResponse>(
      {
        query: fetchJobCandidateWorkflowStagesQuery,
        enabled: (!!jobCandidate?.id &&
          !!job?.id) as FetchJobCandidateWorkflowStagesEnabled,
        cacheKey: JobCandidateWorkflowStagesCache.jobCandidateJobIndexCacheKey(
          jobCandidate?.id as JobCandidateId,
          job?.id as JobId
        ) as unknown as FetchJobCandidateWorkflowStagesCacheKey,
        initialFilters: {
          [JobCandidateWorkflowStageFields.JOB_CANDIDATE_ID]: {
            operator: 'eq',
            value: jobCandidate?.id
          },
          [JobCandidateWorkflowStageFields.JOB_ID]: {
            operator: 'eq',
            value: job?.id
          }
        } as unknown as FetchJobCandidateWorkflowStagesFilters
      }
    );

  const changeWorkflowStage = (e: MouseEvent<HTMLButtonElement>) => {
    const selectedStage = workflowStages.find(
      (stage) => stage.nanoId === e.currentTarget.value
    );

    if (selectedStage?.name === 'Interview') {
      openScoreCard();
    }

    toast({
      title: `${startCase(selectedStage?.name)} stage selected`,
      status: 'success',
      position: 'top-right',
      duration: 2000,
      isClosable: true
    });
  };

  const { isOpen: isOpenRequireAttention, onClose: onCloseRequireAttention } =
    useDisclosure({
      defaultIsOpen: true
    });

  return (
    <Flex pos="relative">
      {isHiringPortalPath ? (
        <ViewJobCandidateDetailsAdvanceDiscontinue
          job={job}
          jobCandidate={jobCandidate}
        />
      ) : (
        <Flex pos="relative">
          <HStack
            w="lg"
            spacing={0}
            bg="gray.50"
            pos="relative"
            border="1px solid"
            borderRadius="base"
            borderColor="gray.200"
            transitionDuration="slow"
            justifyContent="flex-start"
            visibility={isOpenRequireAttention ? 'hidden' : 'visible'}
            opacity={isOpenRequireAttention ? 0 : 1}
            divider={<StackDivider />}
          >
            <Flex justifyContent="stretch" w="50%">
              <ViewJobCandidateDetailsStageSection
                jobCandidate={jobCandidate}
                workflowStages={workflowStages}
                changeWorkflowStage={changeWorkflowStage}
              />
            </Flex>

            <Flex justifyContent="stretch" w="50%">
              <ViewJobCandidateDetailsTasksSection />
            </Flex>
          </HStack>

          <Flex
            w="lg"
            h="full"
            zIndex={9}
            pos="absolute"
            display={isOpenRequireAttention ? 'flex' : 'none'}
            alignItems={isCentered ? 'center' : 'flex-start'}
            justifyContent={isCentered ? 'center' : 'flex-end'}
          >
            <ViewJobCandidateDetailsTaskRequireAttention
              reopenTask={onCloseRequireAttention}
              jobCandidate={jobCandidate}
              status="overdue"
            />
          </Flex>
        </Flex>
      )}

      <ViewJobCandidateInterviewScoreCardModal
        jobCandidateNanoId={jobCandidateNanoId}
        requiresCredit={requiresCredit}
        jobCandidateDetails={jobCandidateDetails}
        isOpenScoreCard={isOpenScoreCard}
        closeScoreCard={closeScoreCard}
        job={job}
      />
    </Flex>
  );
}
