import {
  DateTime,
  FieldBrand,
  ItemCreatedAt,
  ItemID,
  ItemNanoId,
  ItemUpdatedAt,
  ItemUUID,
  MayBe
} from '../../../types';
import {
  JobCandidateId,
  JobCandidateName
} from '../../jobCandidates/jobCandidatesTypes';

import {
  UserEmail,
  UserFullName,
  UserId,
  UserImage,
  UserName,
  UserNanoId,
  UserRoles
} from '../../users/usersTypes';

export type JobCandidateWorkflowStageId = FieldBrand<
  ItemID,
  'JobCandidateWorkflowStageId'
>;
export type JobCandidateWorkflowStageUuid = FieldBrand<
  ItemUUID,
  'JobCandidateWorkflowStageUuid'
>;
export type MayBeJobCandidateWorkflowStageUuid =
  MayBe<JobCandidateWorkflowStageUuid>;
export type JobCandidateWorkflowStageNanoId = FieldBrand<
  ItemNanoId,
  'JobCandidateWorkflowStageNanoId'
>;
export type MayBeJobCandidateWorkflowStageNanoId =
  MayBe<JobCandidateWorkflowStageNanoId>;
export type JobCandidateWorkflowStageCreatedAt = FieldBrand<
  ItemCreatedAt,
  'JobCandidateWorkflowStageCreatedAt'
>;
export type JobCandidateWorkflowStageUpdatedAt = FieldBrand<
  ItemUpdatedAt,
  'JobCandidateWorkflowStageUpdatedAt'
>;
export type JobCandidateWorkflowStageSentAt = FieldBrand<
  DateTime,
  'JobCandidateWorkflowStageSentAt'
>;
export type MayBeJobCandidateWorkflowStageSentAt =
  MayBe<JobCandidateWorkflowStageSentAt>;
export type JobCandidateWorkflowStageUserId = FieldBrand<
  UserId,
  'JobCandidateWorkflowStageUserId'
>;
export type JobCandidateWorkflowStageUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    fullName: UserFullName;
    email: UserEmail;
    image: UserImage;
    roles: UserRoles;
  },
  'JobCandidateWorkflowStageUser'
>;
export type JobCandidateWorkflowStageStatus = FieldBrand<
  JobCandidateWorkflowStageStatuses,
  'JobCandidateWorkflowStageStatus'
>;
export type JobCandidateWorkflowStageJobCandidateId = FieldBrand<
  JobCandidateId,
  'JobCandidateWorkflowStageJobCandidateId'
>;
export type JobCandidateWorkflowStageJobCandidate = FieldBrand<
  { id: JobCandidateId; name: JobCandidateName },
  'JobCandidateWorkflowStageJobCandidateIds'
>;
export type JobCandidateWorkflowStageMessage = FieldBrand<
  string,
  'JobCandidateWorkflowStageMessage'
>;
export type JobCandidateWorkflowStagePages = FieldBrand<
  string[],
  'JobCandidateWorkflowStagePages'
>;
export type JobCandidateWorkflowStageSettingsPages = FieldBrand<
  {
    [K in JobCandidateWorkflowStagePageEnums]: { required: boolean };
  },
  'JobCandidateWorkflowStageSettingsPages'
>;

export type JobCandidateWorkflowStageSettings = FieldBrand<
  {
    pages: JobCandidateWorkflowStageSettingsPages;
    message: JobCandidateWorkflowStageMessage;
  },
  'JobCandidateWorkflowStageSettings'
>;

export const enum JobCandidateWorkflowStagePageEnums {
  SKILLS = 'skills',
  PROJECTS = 'projects',
  QUESTIONS = 'questions',
  REFERENCES = 'references',
  PREFERENCES = 'preferences',
  QUALIFICATIONS = 'qualifications',
  UPDATED_RESUME = 'updatedResume'
}

export const enum JobCandidateWorkflowStageStatuses {
  SENT = 'sent',
  OPENED = 'opened',
  CANCELED = 'canceled',
  COMPLETED = 'completed'
}

export const enum JobCandidateWorkflowStageFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  USER_ID = 'userId',
  USER = 'user',
  STATUS = 'status',
  JOB_CANDIDATE_ID = 'jobCandidateId',
  JOB_ID = 'jobId',
  JOB_CANDIDATE = 'jobCandidate',
  MESSAGE = 'message',
  PAGES = 'pages',
  SETTINGS = 'settings',
  SENT_AT = 'sentAt',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt'
}
