import {
  FetchEmploymentSkillBadgesCacheKey,
  FetchEmploymentSkillBadgeCacheKey,
  DeleteEmploymentSkillBadgeErrorMessage,
  DeleteEmploymentSkillBadgeIsLoading,
  DeleteEmploymentSkillBadgeID
} from '../../employmentSkillBadgesTypes';

import { useDeleteItem } from '../../../common/hooks/useDeleteItem';

import {
  EmploymentSkillBadgesBffRequests,
  DeleteEmploymentSkillBadgeErrorResponse
} from '../../EmploymentSkillBadgesBffRequests';

interface DeleteEmploymentSkillBadgeOptions {
  employmentSkillBadgeId: DeleteEmploymentSkillBadgeID;
  cacheKeys?: (
    | FetchEmploymentSkillBadgesCacheKey
    | FetchEmploymentSkillBadgeCacheKey
  )[];
}

function useDeleteEmploymentSkillBadge({
  employmentSkillBadgeId,
  cacheKeys = []
}: DeleteEmploymentSkillBadgeOptions) {
  const {
    deleteItemData,
    deleteItemError,
    deleteItemErrorMessage,
    deleteItemIsLoading,
    deleteItem,
    deleteItemReset
  } = useDeleteItem<unknown, DeleteEmploymentSkillBadgeErrorResponse>({
    mutationFn: () =>
      EmploymentSkillBadgesBffRequests.deleteEmploymentSkillBadge(
        employmentSkillBadgeId
      ),
    // EmploymentSkillBadgesRequests.deleteEmploymentSkillBadge(employmentSkillBadgeId),
    cacheKeys
  });

  return {
    deleteEmploymentSkillBadgeData: deleteItemData,
    deleteEmploymentSkillBadgeError: deleteItemError,
    deleteEmploymentSkillBadgeErrorMessage:
      deleteItemErrorMessage as DeleteEmploymentSkillBadgeErrorMessage,
    deleteEmploymentSkillBadgeIsLoading:
      deleteItemIsLoading as DeleteEmploymentSkillBadgeIsLoading,
    deleteEmploymentSkillBadge: deleteItem,
    deleteEmploymentSkillBadgeReset: deleteItemReset
  };
}

export default useDeleteEmploymentSkillBadge;
