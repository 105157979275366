import {
  ReactHookFormFieldError,
  useReactHookForm
} from '../../../../../../../common/hooks/base/useReactHookForm';

import {
  WorkflowStageTaskApplication,
  WorkflowStageTaskAutomation,
  WorkflowStageTaskAutomations,
  WorkflowStageTaskDueDate,
  WorkflowStageTaskFields,
  WorkflowStageTaskName,
  WorkflowStageTaskReminder
} from '../../../../../workflowStageTasksTypes';

import { useEditWorkflowStageTaskFormValidationRules } from '../useEditWorkflowStageTaskFormValidationRules';

import {
  EditWorkflowStageTaskFormData,
  OnEditWorkflowStageTaskForm
} from '../../EditWorkflowStageTaskForm.types';

const defaultEditWorkflowStageTaskFormValues: EditWorkflowStageTaskFormData = {
  name: '' as WorkflowStageTaskName,
  application: '' as WorkflowStageTaskApplication,
  dueDate: '' as WorkflowStageTaskDueDate,
  automation: WorkflowStageTaskAutomations.NONE as WorkflowStageTaskAutomation,
  reminder: '' as WorkflowStageTaskReminder
};

interface EditWorkflowStageTaskFormOptions {
  defaultValues?: Partial<EditWorkflowStageTaskFormData>;
  onEditWorkflowStageTaskForm: OnEditWorkflowStageTaskForm;
}

function useEditWorkflowStageTaskForm({
  defaultValues = {},
  onEditWorkflowStageTaskForm
}: EditWorkflowStageTaskFormOptions) {
  const {
    control,
    watch,
    errors,
    isLoading,
    isValid,
    isSubmitted,
    isSubmitSuccessful,
    errorMessage,
    register,
    resetForm,
    handleSubmitReactHookForm,
    setValue
  } = useReactHookForm<EditWorkflowStageTaskFormData>({
    defaultValues: {
      ...defaultEditWorkflowStageTaskFormValues,
      ...defaultValues
    }
  });

  const { nameRules, applicationRules, dueDateRules, automationRules } =
    useEditWorkflowStageTaskFormValidationRules();

  return {
    control,
    watch,
    validationErrors: {
      nameValidationError: (errors.name as ReactHookFormFieldError)?.message,
      applicationValidationError: (
        errors.application as ReactHookFormFieldError
      )?.message,
      dueDateValidationError: (errors.dueDate as ReactHookFormFieldError)
        ?.message,
      automationValidationError: (errors.automation as ReactHookFormFieldError)
        ?.message
    },
    editWorkflowStageTaskFormIsLoading: isLoading,
    editWorkflowStageTaskFormIsValid: isValid,
    editWorkflowStageTaskFormIsSubmitted: isSubmitted,
    editWorkflowStageTaskFormIsSubmitSuccessful: isSubmitSuccessful,
    editWorkflowStageTaskFormErrorMessage: errorMessage,
    resetEditWorkflowStageTaskForm: resetForm,
    setEditWorkflowStageTaskFormValue: setValue,
    registerFields: {
      registerName: register(WorkflowStageTaskFields.NAME, nameRules),
      registerApplication: register(
        WorkflowStageTaskFields.APPLICATION,
        applicationRules
      ),
      registerDueDate: register(WorkflowStageTaskFields.DUE_DATE, dueDateRules),
      registerAutomation: register(
        WorkflowStageTaskFields.AUTOMATION,
        automationRules
      )
    },
    handleEditWorkflowStageTaskForm: handleSubmitReactHookForm({
      onSubmit: (data) =>
        onEditWorkflowStageTaskForm?.({
          name: data.name as WorkflowStageTaskName,
          application: data.application as WorkflowStageTaskApplication,
          dueDate: data.dueDate as WorkflowStageTaskDueDate,
          reminder: data.reminder as WorkflowStageTaskReminder,
          automation: data.automation as WorkflowStageTaskAutomation
        })
    })
  };
}

export default useEditWorkflowStageTaskForm;
