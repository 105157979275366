import { GridItem } from '@chakra-ui/react';
import uniqBy from 'lodash/uniqBy';
import size from 'lodash/size';
import map from 'lodash/map';

import { JobCandidateNanoId } from '../../../jobCandidates/jobCandidatesTypes';
import { JobId, JobNanoId } from '../../../jobs/jobsTypes';
import { UserId } from '../../../users/usersTypes';
import {
  DepartmentId,
  DepartmentNanoId
} from '../../../departments/departmentsTypes';

import { TalentPoolCandidatesList } from '../../../sourcing/components/lists/TalentPoolCandidatesList';
import { usePaginatedCandidateSubmissionJobCandidates } from '../../../candidateSubmissionJobCandidates/hooks/usePaginatedCandidateSubmissionJobCandidates';
import {
  fetchCandidateSubmissionJobCandidatesQuery,
  FetchCandidateSubmissionJobCandidatesResponse
} from '../../../candidateSubmissionJobCandidates/queries/fetchCandidateSubmissionJobCandidates.query';
import { CandidateSubmissionJobCandidatesCache } from '../../../candidateSubmissionJobCandidates/CandidateSubmissionJobCandidatesCache';
import {
  CandidateSubmissionJobCandidateFields,
  FetchCandidateSubmissionJobCandidatesCountType,
  FetchCandidateSubmissionJobCandidatesFilters
} from '../../../candidateSubmissionJobCandidates/candidateSubmissionJobCandidatesTypes';
import { useCallback } from 'react';

interface HiringPortalTalentPoolCandidatesListContainerProps {
  currentUser: {
    id: UserId;
  };
  personNanoId?: JobCandidateNanoId;
  jobCandidateRoute: (
    departmentNanoId: DepartmentNanoId,
    personNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) => string;
  isDisabled?: boolean;
  fullHeight?: boolean;
  department?: {
    nanoId: DepartmentNanoId;
    id: DepartmentId;
  };
  job?: {
    nanoId: JobNanoId;
    id: JobId;
    department: {
      nanoId: DepartmentNanoId;
      id: DepartmentId;
    };
  };
  updateProspectsCount?: (count: number) => void;
}

function HiringPortalTalentPoolCandidatesListContainer({
  job,
  department,
  personNanoId,
  currentUser,
  jobCandidateRoute,
  isDisabled,
  fullHeight,
  updateProspectsCount
}: HiringPortalTalentPoolCandidatesListContainerProps) {
  const {
    candidateSubmissionJobCandidates,
    candidateSubmissionJobCandidatesErrorMessage,
    candidateSubmissionJobCandidatesIsFetched,
    candidateSubmissionJobCandidatesIsLoading,
    changeCandidateSubmissionJobCandidatesFilters,
    filterCandidateSubmissionJobCandidates
  } = usePaginatedCandidateSubmissionJobCandidates<FetchCandidateSubmissionJobCandidatesResponse>(
    {
      query: fetchCandidateSubmissionJobCandidatesQuery,
      cacheKey: CandidateSubmissionJobCandidatesCache.indexCacheKey(),
      countType: 'exact' as FetchCandidateSubmissionJobCandidatesCountType,
      initialFilters: {
        [CandidateSubmissionJobCandidateFields.RECIPIENT_IDS]: {
          operator: 'eq',
          value: [currentUser.id]
        },
        ...(department || job?.department?.id
          ? {
              [CandidateSubmissionJobCandidateFields.DEPARTMENT_ID]: {
                operator: 'eq',
                value: department?.id || job?.department?.id
              }
            }
          : {})
      } as unknown as FetchCandidateSubmissionJobCandidatesFilters,
      onSuccess(data) {
        const allJobCandidates = map(data.data, (cjc) => cjc.jobCandidate);

        const jobCandidates = uniqBy(allJobCandidates, 'id');
        const count = size(jobCandidates);
        updateProspectsCount?.(count);
      }
    }
  );

  const filterCandidatesByDepartment = useCallback<
    (departmentId: DepartmentId) => void
  >(
    (departmentId) => {
      if (departmentId === 'all') {
        filterCandidateSubmissionJobCandidates({
          [CandidateSubmissionJobCandidateFields.RECIPIENT_IDS]: {
            operator: 'eq',
            value: [currentUser.id]
          }
        });
      } else {
        changeCandidateSubmissionJobCandidatesFilters({
          [CandidateSubmissionJobCandidateFields.DEPARTMENT_ID]: {
            operator: 'eq',
            value: departmentId
          }
        });
      }
    },
    [
      changeCandidateSubmissionJobCandidatesFilters,
      currentUser.id,
      filterCandidateSubmissionJobCandidates
    ]
  );

  const allJobCandidates = map(
    candidateSubmissionJobCandidates,
    (cjc) => cjc.jobCandidate
  );

  const jobCandidates = uniqBy(allJobCandidates, 'id');

  return (
    <GridItem w="full" h="full" pos="relative" overflow="hidden">
      <TalentPoolCandidatesList
        jobCandidates={jobCandidates}
        jobCandidatesTotalCount={size(jobCandidates)}
        jobCandidatesIsLoading={candidateSubmissionJobCandidatesIsLoading}
        jobCandidatesIsFetched={candidateSubmissionJobCandidatesIsFetched}
        jobCandidatesErrorMessage={candidateSubmissionJobCandidatesErrorMessage}
        department={department || job?.department}
        personNanoId={personNanoId}
        currentUser={currentUser}
        jobCandidateRoute={jobCandidateRoute}
        filterCandidatesByDepartment={filterCandidatesByDepartment}
        isDisabled={isDisabled}
        fullHeight={fullHeight}
        withoutProfileShare
        isHiringPortal
      />
    </GridItem>
  );
}

export default HiringPortalTalentPoolCandidatesListContainer;
