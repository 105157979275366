import { useCallback, useEffect, useReducer } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import omit from 'lodash/omit';

import {
  FetchItemsCacheKey,
  FetchItemsCacheTime,
  FetchItemsEnabled,
  FetchItemsPrefetchNextPage,
  FetchItemsRefetchInterval,
  FetchItemsTotalCount,
  FetchItemsPage,
  FetchItemsPageSize,
  FetchItemsFilters,
  FetchItemsSort,
  FetchItemsError,
  FetchItemsErrorMessage,
  FetchItemsIsLoading,
  FetchItemsIsFetched,
  FetchItemsIsPlaceholderData,
  FetchItemCacheKey,
  ItemID,
  FilterOperator,
  ItemNanoId
} from '../../../../types';

import {
  INITIAL_PAGE,
  INITIAL_PAGE_SIZE,
  INITIAL_FILTERS,
  INITIAL_SORT
} from '../../../../constants';

import { paginateItemsAction } from './actions/paginateItemsAction';
import { changeItemsPageSizeAction } from './actions/changeItemsPageSizeAction';
import { filterItemsAction } from './actions/filterItemsAction';
import { changeItemsFiltersAction } from './actions/changeItemsFiltersAction';
import { clearItemsFiltersAction } from './actions/clearItemsFiltersAction';
import { sortItemsAction } from './actions/sortItemsAction';

import {
  paginatedItemsReducer,
  PaginatedItemsReducerType
} from './reducers/paginatedItemsReducer';

import { parseRequestError } from '../../../../utils/parseRequestError';

interface PaginatedItemsOptions<PaginatedItemsResponseType> {
  cacheKey: FetchItemsCacheKey;
  cacheTime?: FetchItemsCacheTime;
  initialPage?: FetchItemsPage;
  initialPageSize?: FetchItemsPageSize;
  initialFilters?: FetchItemsFilters;
  initialSort?: FetchItemsSort;
  enabled?: FetchItemsEnabled;
  prefetchNextPage?: FetchItemsPrefetchNextPage;
  refetchInterval?: FetchItemsRefetchInterval;
  placeholderItems?: PaginatedItemsResponseType[];
  placeholderItemsCount?: FetchItemsTotalCount;
  placeholderData?: () => PaginatedItemsResponse<PaginatedItemsResponseType>;
  queryFn: (params: {
    page: FetchItemsPage;
    pageSize: FetchItemsPageSize;
    filters: FetchItemsFilters;
    sort: FetchItemsSort;
  }) => Promise<PaginatedItemsResponse<PaginatedItemsResponseType>>;
  fetchItemCacheKey?: FetchItemCacheKey;
  queryItemFn?: (itemId: ItemID | ItemNanoId) => Promise<void | null>;
  onSuccess?: (
    data: PaginatedItemsResponse<PaginatedItemsResponseType>
  ) => void;
}

interface PaginatedItemsResponse<PaginatedItemsResponseType> {
  data: PaginatedItemsResponseType[];
  totalCount?: FetchItemsTotalCount;
  meta?: { totalCount?: FetchItemsTotalCount };
}

function usePaginatedItems<PaginatedItemsResponseType>({
  cacheKey,
  cacheTime,
  initialPage = INITIAL_PAGE,
  initialPageSize = INITIAL_PAGE_SIZE,
  initialFilters = INITIAL_FILTERS,
  initialSort = INITIAL_SORT,
  enabled,
  prefetchNextPage,
  placeholderItems,
  placeholderItemsCount,
  placeholderData,
  queryFn,
  fetchItemCacheKey,
  queryItemFn,
  refetchInterval,
  onSuccess
}: PaginatedItemsOptions<PaginatedItemsResponseType>) {
  const [
    { currentPage, currentPageSize, currentFilters, currentSort },
    dispatch
  ] = useReducer<PaginatedItemsReducerType>(paginatedItemsReducer, {
    currentPage: initialPage,
    currentPageSize: initialPageSize,
    currentFilters: initialFilters,
    currentSort: initialSort
  });

  const queryClient = useQueryClient();

  const currentParams = {
    page: currentPage,
    pageSize: currentPageSize,
    filters: currentFilters,
    sort: currentSort
  };

  const {
    data: itemsResponse,
    error,
    isFetched,
    isLoading,
    isPlaceholderData
  } = useQuery<
    PaginatedItemsResponse<PaginatedItemsResponseType>,
    FetchItemsError
  >([cacheKey, currentParams], () => queryFn(currentParams), {
    enabled,
    cacheTime,
    onSuccess,
    refetchInterval,
    placeholderData:
      placeholderData ||
      (placeholderItems
        ? () => ({
            data: placeholderItems,
            totalCount: (placeholderItemsCount || null) as FetchItemsTotalCount
          })
        : undefined)
  });

  useEffect(() => {
    if (prefetchNextPage) {
      const params = {
        page: (currentPage + 1) as FetchItemsPage,
        pageSize: currentPageSize,
        filters: currentFilters,
        sort: currentSort
      };

      queryClient.prefetchQuery([cacheKey, params], () => queryFn(params));
    }
  }, [
    prefetchNextPage,
    queryClient,
    cacheKey,
    queryFn,
    currentPage,
    currentPageSize,
    currentFilters,
    currentSort
  ]);

  return {
    items: itemsResponse?.data || [],
    itemsTotalCount:
      itemsResponse?.totalCount || itemsResponse?.meta?.totalCount,
    itemsMeta: itemsResponse?.meta,
    itemsError: error as FetchItemsError,
    itemsErrorMessage: parseRequestError(error) as FetchItemsErrorMessage,
    itemsIsLoading: isLoading as FetchItemsIsLoading,
    itemsIsFetched: isFetched as FetchItemsIsFetched,
    itemsIsPlaceholderData: isPlaceholderData as FetchItemsIsPlaceholderData,
    itemsCurrentPage: currentPage as FetchItemsPage,
    itemsCurrentPageSize: currentPageSize as FetchItemsPageSize,
    itemsCurrentFilters: currentFilters as FetchItemsFilters,
    itemsCurrentSort: currentSort as FetchItemsSort,
    fetchItems: useCallback<
      ({
        nextFilters,
        removeFilters
      }: {
        nextFilters: {
          [field: string]: {
            operator: FilterOperator;
            value: string | null | number | string[] | number[];
          };
        };
        removeFilters?: string[];
      }) => Promise<PaginatedItemsResponse<PaginatedItemsResponseType>>
    >(
      async ({ nextFilters, removeFilters }) => {
        const params = {
          page: currentPage,
          pageSize: currentPageSize,
          filters: currentFilters,
          sort: currentSort
        };
        return await queryFn({
          ...params,
          filters: omit(
            {
              ...params.filters,
              ...nextFilters
            },
            removeFilters || []
          ) as FetchItemsFilters
        });
      },
      [currentPage, currentPageSize, currentFilters, currentSort, queryFn]
    ),
    paginateItems: useCallback<(page: number) => void>(
      (page) => dispatch(paginateItemsAction(page as FetchItemsPage)),
      [dispatch]
    ),
    changeItemsPageSize: useCallback<(pageSize: number) => void>(
      (pageSize) =>
        dispatch(changeItemsPageSizeAction(pageSize as FetchItemsPageSize)),
      [dispatch]
    ),
    filterItems: useCallback<
      (nextFilters: {
        [field: string]: {
          operator: FilterOperator;
          value: string | number | null | string[] | number[];
        };
      }) => void
    >(
      (nextFilters) =>
        dispatch(filterItemsAction(nextFilters as FetchItemsFilters)),
      [dispatch]
    ),
    changeItemsFilters: useCallback<
      (
        changedFilters: {
          [field: string]: {
            operator: FilterOperator;
            value: string | string[];
          };
        },
        removeFilters?: string[]
      ) => void
    >(
      (changedFilters, removeFilters) =>
        dispatch(
          changeItemsFiltersAction(
            changedFilters,
            removeFilters || ([] as string[])
          )
        ),
      [dispatch]
    ),
    clearItemsFilters: useCallback<() => void>(
      () => dispatch(clearItemsFiltersAction()),
      [dispatch]
    ),
    sortItems: useCallback<
      (nextSort: {
        [field: string]: { ascending: boolean; order?: number };
      }) => void
    >(
      (nextSort) => dispatch(sortItemsAction(nextSort as FetchItemsSort)),
      [dispatch]
    ),
    prefetchItems: useCallback<
      ({
        nextPage,
        nextPageSize,
        nextFilters,
        nextSort
      }: {
        nextPage?: FetchItemsPage;
        nextPageSize?: FetchItemsPageSize;
        nextFilters?: FetchItemsFilters;
        nextSort?: FetchItemsSort;
      }) => void
    >(
      ({ nextPage, nextPageSize, nextFilters, nextSort }) => {
        const params = {
          page: nextPage || currentPage,
          pageSize: nextPageSize || currentPageSize,
          filters: nextFilters || currentFilters,
          sort: nextSort || currentSort
        };

        return queryClient.prefetchQuery([cacheKey, params], () =>
          queryFn(params)
        );
      },
      [
        queryClient,
        cacheKey,
        queryFn,
        currentPage,
        currentPageSize,
        currentFilters,
        currentSort
      ]
    ),
    prefetchItem: useCallback<(itemId: ItemID | ItemNanoId) => void>(
      (itemId) =>
        queryClient.prefetchQuery(
          [fetchItemCacheKey, itemId],
          () => queryItemFn?.(itemId),
          { staleTime: 5000 }
        ),
      [queryClient, fetchItemCacheKey, queryItemFn]
    )
  };
}

export default usePaginatedItems;
