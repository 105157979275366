import {
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react';
import { Button } from '../../../../../../../../../../helpers/Button';
import { ViewJobCandidateDetailsAssessmentMyNxmoov } from '../../../../../../pages/ViewJobCandidateDetailsAssessmentPage/components/ViewJobCandidateDetailsAssessmentMyNxmoov';
import { ViewJobCandidateDetailsAssessmentMyNxmoovFormJobCandidateType } from '../../../../../../pages/ViewJobCandidateDetailsAssessmentPage/components/ViewJobCandidateDetailsAssessmentMyNxmoov/ViewJobCandidateDetailsAssessmentMyNxmoov.types';

interface EditJobCandidateMyNxmoovModalProps {
  jobCandidate: ViewJobCandidateDetailsAssessmentMyNxmoovFormJobCandidateType;
}

export default function EditJobCandidateMyNxmoovModal({
  jobCandidate
}: EditJobCandidateMyNxmoovModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button size="small" hierarchy="link" ml="auto" onClick={onOpen}>
        Edit
      </Button>

      <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <ViewJobCandidateDetailsAssessmentMyNxmoov
            isOpenDetails
            onSave={onClose}
            onCancel={onClose}
            jobCandidate={jobCandidate}
            addButtonLabel="Add My Nxmoov"
          />
        </ModalContent>
      </Modal>
    </>
  );
}
