import { Icon, IconProps } from '@chakra-ui/icon';
import { generateNanoId } from '../../utils/generateNanoId';

interface StarIconProps extends IconProps {
  filled?: boolean;
  rating?: number;
}

const StarIcon = ({
  w = '12px',
  h = '12px',
  width,
  height,
  filled,
  rating,
  stroke = '#EEA401',
  ...props
}: StarIconProps) => {
  if (typeof rating === 'number') {
    const stop = rating * 100;

    const id = generateNanoId();

    return (
      <Icon
        viewBox="0 0 24 24"
        w={w || width}
        h={h || height}
        fill="none"
        {...props}
      >
        <defs>
          <linearGradient id={id}>
            {stop === 0 ? (
              <>
                <stop offset="0%" stopColor="white" />
                <stop offset="100%" stopColor="white" />
              </>
            ) : (
              <>
                <stop offset="0%" stopColor="#EEA401" />
                <stop offset={`${stop}%`} stopColor="#EEA401" />
              </>
            )}
            {stop < 100 && stop > 0 ? (
              <>
                <stop offset={`${stop}%`} stopColor="white" />
                <stop offset="100%" stopColor="white" />
              </>
            ) : null}
          </linearGradient>
        </defs>
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          fill={`url(#${id})`}
          stroke={stroke as string}
          strokeWidth="1.5"
          d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </Icon>
    );
  }

  return (
    <Icon
      viewBox="0 0 12 12"
      w={w || width}
      h={h || height}
      fill="none"
      {...props}
    >
      <path
        d="M6.254 9.387 6 9.237l-.254.15-2.758 1.623.623-3.3.05-.264-.192-.187-2.374-2.308 3.187-.397.282-.036.114-.26L6 1.245l1.322 3.013.114.26.282.036 3.187.397L8.531 7.26l-.192.187.05.264.623 3.3-2.758-1.623Z"
        stroke={stroke as string}
        fill={filled ? '#EEA401' : ('' as string)}
      />
    </Icon>
  );
};

export default StarIcon;
