import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchJobCandidateWorkflowStagesQuery,
  FetchJobCandidateWorkflowStagesCacheKey,
  FetchJobCandidateWorkflowStagesTotalCount,
  FetchJobCandidateWorkflowStagesPage,
  FetchJobCandidateWorkflowStagesPageSize,
  FetchJobCandidateWorkflowStagesError,
  FetchJobCandidateWorkflowStagesErrorMessage,
  FetchJobCandidateWorkflowStagesIsLoading,
  FetchJobCandidateWorkflowStagesIsFetched,
  FetchJobCandidateWorkflowStagesIsPlaceholderData,
  FetchJobCandidateWorkflowStagesEnabled,
  FetchJobCandidateWorkflowStagesPrefetchNextPage,
  FetchJobCandidateWorkflowStagesRefetchInterval,
  FetchJobCandidateWorkflowStagesCountType,
  FetchJobCandidateWorkflowStagesFilters,
  FetchJobCandidateWorkflowStagesSort,
  FetchJobCandidateWorkflowStageID,
  FetchJobCandidateWorkflowStageQuery,
  FetchJobCandidateWorkflowStageCacheKey
} from '../../jobCandidateWorkflowStagesTypes';

import { JobCandidateWorkflowStagesBffRequests } from '../../JobCandidateWorkflowStagesBffRequests';

interface PaginatedJobCandidateWorkflowStagesBaseOptions<
  PaginatedJobCandidateWorkflowStagesResponseType
> {
  cacheKey: FetchJobCandidateWorkflowStagesCacheKey;
  query: FetchJobCandidateWorkflowStagesQuery;
  countType?: FetchJobCandidateWorkflowStagesCountType;
  initialPage?: FetchJobCandidateWorkflowStagesPage;
  initialPageSize?: FetchJobCandidateWorkflowStagesPageSize;
  initialFilters?: FetchJobCandidateWorkflowStagesFilters;
  initialSort?: FetchJobCandidateWorkflowStagesSort;
  enabled?: FetchJobCandidateWorkflowStagesEnabled;
  prefetchNextPage?: FetchJobCandidateWorkflowStagesPrefetchNextPage;
  refetchInterval?: FetchJobCandidateWorkflowStagesRefetchInterval;
  placeholderJobCandidateWorkflowStages?: PaginatedJobCandidateWorkflowStagesResponseType[];
  placeholderJobCandidateWorkflowStagesCount?: FetchJobCandidateWorkflowStagesTotalCount;
  placeholderData?: () => PaginatedJobCandidateWorkflowStagesResponse<PaginatedJobCandidateWorkflowStagesResponseType>;
}

interface PaginatedJobCandidateWorkflowStagesOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedJobCandidateWorkflowStagesOptionsWithItem {
  fetchItemCacheKey: FetchJobCandidateWorkflowStageCacheKey;
  itemQuery: FetchJobCandidateWorkflowStageQuery;
}

type PaginatedJobCandidateWorkflowStagesOptions<
  PaginatedJobCandidateWorkflowStagesResponseType
> = PaginatedJobCandidateWorkflowStagesBaseOptions<PaginatedJobCandidateWorkflowStagesResponseType> &
  (
    | PaginatedJobCandidateWorkflowStagesOptionsWithoutItem
    | PaginatedJobCandidateWorkflowStagesOptionsWithItem
  );

interface PaginatedJobCandidateWorkflowStagesResponse<
  PaginatedJobCandidateWorkflowStagesResponseType
> {
  data: PaginatedJobCandidateWorkflowStagesResponseType[];
  totalCount: FetchJobCandidateWorkflowStagesTotalCount;
}

function usePaginatedJobCandidateWorkflowStages<
  PaginatedJobCandidateWorkflowStagesResponseType
>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  refetchInterval,
  placeholderJobCandidateWorkflowStages,
  placeholderJobCandidateWorkflowStagesCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedJobCandidateWorkflowStagesOptions<PaginatedJobCandidateWorkflowStagesResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedJobCandidateWorkflowStagesResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    refetchInterval,
    placeholderItems: placeholderJobCandidateWorkflowStages,
    placeholderItemsCount: placeholderJobCandidateWorkflowStagesCount,
    placeholderData,
    queryFn: (params) =>
      JobCandidateWorkflowStagesBffRequests.fetchJobCandidateWorkflowStages<PaginatedJobCandidateWorkflowStagesResponseType>(
        {
          query,
          countType,
          page: params.page as FetchJobCandidateWorkflowStagesPage,
          pageSize: params.pageSize as FetchJobCandidateWorkflowStagesPageSize,
          filters: params.filters as FetchJobCandidateWorkflowStagesFilters,
          sort: params.sort as FetchJobCandidateWorkflowStagesSort
        }
      ),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            JobCandidateWorkflowStagesBffRequests.fetchJobCandidateWorkflowStage(
              itemId as FetchJobCandidateWorkflowStageID,
              {
                query: itemQuery
              }
            )
        }
      : {})
  });

  return {
    jobCandidateWorkflowStages:
      items as PaginatedJobCandidateWorkflowStagesResponseType[],
    jobCandidateWorkflowStagesTotalCount:
      itemsTotalCount as FetchJobCandidateWorkflowStagesTotalCount,
    jobCandidateWorkflowStagesError:
      itemsError as FetchJobCandidateWorkflowStagesError,
    jobCandidateWorkflowStagesErrorMessage:
      itemsErrorMessage as FetchJobCandidateWorkflowStagesErrorMessage,
    jobCandidateWorkflowStagesIsLoading:
      itemsIsLoading as FetchJobCandidateWorkflowStagesIsLoading,
    jobCandidateWorkflowStagesIsFetched:
      itemsIsFetched as FetchJobCandidateWorkflowStagesIsFetched,
    jobCandidateWorkflowStagesIsPlaceholderData:
      itemsIsPlaceholderData as FetchJobCandidateWorkflowStagesIsPlaceholderData,
    jobCandidateWorkflowStagesCurrentPage:
      itemsCurrentPage as FetchJobCandidateWorkflowStagesPage,
    jobCandidateWorkflowStagesCurrentPageSize:
      itemsCurrentPageSize as FetchJobCandidateWorkflowStagesPageSize,
    jobCandidateWorkflowStagesCurrentFilters:
      itemsCurrentFilters as FetchJobCandidateWorkflowStagesFilters,
    jobCandidateWorkflowStagesCurrentSort:
      itemsCurrentSort as FetchJobCandidateWorkflowStagesSort,
    fetchJobCandidateWorkflowStages: fetchItems,
    paginateJobCandidateWorkflowStages: paginateItems,
    changeJobCandidateWorkflowStagesPageSize: changeItemsPageSize,
    filterJobCandidateWorkflowStages: filterItems,
    changeJobCandidateWorkflowStagesFilters: changeItemsFilters,
    clearJobCandidateWorkflowStagesFilters: clearItemsFilters,
    sortJobCandidateWorkflowStages: sortItems,
    prefetchJobCandidateWorkflowStages: prefetchItems,
    prefetchJobCandidateWorkflowStage: prefetchItem
  };
}

export default usePaginatedJobCandidateWorkflowStages;
