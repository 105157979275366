import { Collapse, Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { NumericRatingFieldControl } from '../../../../../../../../helpers/forms/formFields/NumericRatingField/components/NumericRatingFieldControl';
import { TextareaField } from '../../../../../../../../helpers/forms/formFields/TextareaField';
import { Text } from '../../../../../../../../helpers/Text';
import { JobScorecardJobScorecardQuestion } from '../../../../../../../jobScorecards/jobScorecardsTypes';

interface CustomiseCandidateEvaluationItemProps {
  question: JobScorecardJobScorecardQuestion;
  isOpen: boolean;
  onToggle: () => void;
  onUpdate: (rating: number, comment: string) => void;
}

export default function CustomiseCandidateEvaluationItem({
  question,
  isOpen,
  onToggle,
  onUpdate
}: CustomiseCandidateEvaluationItemProps) {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  const handleRatingChange = (value: number) => {
    setRating(value);
    onUpdate(value, comment);
    !isOpen && onToggle();
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newComment = e.target.value;
    setComment(newComment);
    onUpdate(rating, newComment);
  };

  return (
    <Flex flexDir="column" gap={2}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text textStyle="body1Regular" lineHeight="20px">
          {question.name}
        </Text>

        <NumericRatingFieldControl
          onChange={(value) => handleRatingChange(Number(value))}
          value={rating.toString()}
          size="sm"
        />
      </Flex>

      <Collapse in={isOpen}>
        <Flex p={0.5} gap={2} flexDir="column">
          <TextareaField
            rows={3}
            placeholder="Add a comment"
            fontSize="sm"
            value={comment}
            onChange={handleCommentChange}
          />
        </Flex>
      </Collapse>
    </Flex>
  );
}
