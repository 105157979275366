import { Box, ButtonGroup, Flex, Stack } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import filter from 'lodash/filter';
import first from 'lodash/first';
import take from 'lodash/take';
import includes from 'lodash/includes';
import size from 'lodash/size';

import { JobResumeRecruiterId } from '../../../jobResumeRecruiters/jobResumeRecruitersTypes';

import { SkillId, SkillName } from '../../../skills/skillsTypes';

import {
  FetchSkillBadgesCacheKey,
  MayBeSkillBadgeEmploymentIds,
  SkillBadgeEmploymentIds,
  SkillBadgeExperience,
  SkillBadgeExperienceType,
  SkillBadgeExperienceTypes,
  SkillBadgeFields,
  SkillBadgeId,
  SkillBadgeJobCandidateId,
  SkillBadgeJobResumeRecruiterId,
  SkillBadgeNanoId,
  SkillBadgeRating,
  SkillBadgeRatingType,
  SkillBadgeRequirements,
  SkillBadgeRequirementsTypes,
  SkillBadgeSkillId,
  SkillBadgeUserId
} from '../../skillBadgesTypes';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';
import { useCreateSkillBadge } from '../../hooks/useCreateSkillBadge';
import { useUpdateSkillBadge } from '../../hooks/useUpdateSkillBadge';
import { useAddEditSkillBadgeForm } from '../AddEditSkillBadgeForm/hooks/useAddEditSkillBadgeForm';

import { AlertMessage } from '../../../../helpers/AlertMessage';
import { PureButtonHelper } from '../../../../helpers/buttons/PureButtonHelper';
import { SelectField } from '../../../../helpers/forms/formFields/SelectField';
import { LoadingSkeleton } from '../../../../helpers/LoadingSkeleton';
import { Text } from '../../../../helpers/Text';

import { generateNanoId } from '../../../../utils/generateNanoId';

import { ratingOptions } from '../AddEditSkillBadgeForm/AddEditSkillBadgeForm.data';

import { AddEditSkillBadgeFormSkillBadge } from '../AddEditSkillBadgeForm/AddEditSkillBadgeForm.types';
import { usePaginatedEmploymentSkillBadges } from '../../../employmentSkillBadges/hooks/usePaginatedEmploymentSkillBadges';
import {
  fetchEmploymentSkillBadgesQuery,
  FetchEmploymentSkillBadgesResponse
} from '../../../employmentSkillBadges/queries/fetchEmploymentSkillBadges.query';
import { EmploymentSkillBadgesCache } from '../../../employmentSkillBadges/EmploymentSkillBadgesCache';
import {
  EmploymentSkillBadgeEmploymentId,
  EmploymentSkillBadgeFields,
  EmploymentSkillBadgeSkillBadgeId,
  FetchEmploymentSkillBadgesEnabled,
  FetchEmploymentSkillBadgesFilters,
  FetchEmploymentSkillBadgesSort
} from '../../../employmentSkillBadges/employmentSkillBadgesTypes';
import { AddEditEmploymentSkillBadgeForm } from '../../../employmentSkillBadges/components/AddEditEmploymentSkillBadgeForm';
import { useDeleteSkillBadge } from '../../hooks/useDeleteSkillBadge';
import { useCreateEmploymentSkillBadge } from '../../../employmentSkillBadges/hooks/useCreateEmploymentSkillBadge';
import { EmploymentSkillBadgesBffRequests } from '../../../employmentSkillBadges/EmploymentSkillBadgesBffRequests';
import { RadioGroupField } from '../../../../helpers/forms/formFields/RadioGroupField';
import { renderSkillBadgeExperienceType } from '../../utils/renderSkillBadgeExperienceType';
import { usePaginatedEmployments } from '../../../employments/hooks/usePaginatedEmployments';
import {
  fetchEmploymentsQuery,
  FetchEmploymentsResponse
} from '../../../employments/queries/fetchEmployments.query';
import { EmploymentsCache } from '../../../employments/EmploymentsCache';
import {
  EmploymentFields,
  FetchEmploymentsFilters,
  FetchEmploymentsPageSize,
  FetchEmploymentsSort
} from '../../../employments/employmentsTypes';
import { JobCandidateId } from '../../../jobCandidates/jobCandidatesTypes';
import { IconButton } from '../../../../helpers/buttons/IconButton';
import { CancelIcon } from '../../../../icons/CancelIcon';
import { calculateSkillExperience } from '../../../employmentSkillBadges/utils/calculateSkillExperience';

interface AddEditSkillBadgeFormDefaultProps {
  jobResumeRecruiterId?: JobResumeRecruiterId;
  jobCandidateId?: JobCandidateId;
  skillBadgesCacheKey: FetchSkillBadgesCacheKey;
  onAdd: () => void;
  onDiscard: () => void;
}

interface AddEditSkillBadgeFormWithSkillBadgeProps {
  skillBadge?: AddEditSkillBadgeFormSkillBadge;
  skill?: never;
}

interface AddEditSkillBadgeFormWithSkillProps {
  skillBadge?: never;
  skill?: {
    id: SkillId;
    name: SkillName;
  };
}

const enum ScreensEnum {
  WORK_HISTROY = 'WORK_HISTORY',
  RATING = 'RATING',
  EMPLOYMENT_DETAILS = 'EMPLOYMENT_DETAILS',
  EXPERIENCE_TYPE = 'EXPERIENCE_TYPE'
}

type AddEditSkillBadgeFormProps = AddEditSkillBadgeFormDefaultProps &
  (
    | AddEditSkillBadgeFormWithSkillBadgeProps
    | AddEditSkillBadgeFormWithSkillProps
  );

function AddEditApplicantSkillBadgeForm({
  jobResumeRecruiterId,
  jobCandidateId,
  skillBadgesCacheKey,
  skillBadge,
  skill,
  onAdd,
  onDiscard
}: AddEditSkillBadgeFormProps) {
  const currentUser = useCurrentUser();

  const [currentSkillBadgeId, setCurrentSkillBadgeId] =
    useState<SkillBadgeId | null>(skillBadge?.id || null);
  const [currentSkillBadgeNanoId, setCurrentSkillBadgeNanoId] =
    useState<SkillBadgeNanoId | null>(skillBadge?.nanoId || null);
  const [currentScreen, setCurrentScreen] = useState(
    ScreensEnum.EXPERIENCE_TYPE
  );
  const [newEmploymentSkillBadges, setNewEmploymentSkillBadges] = useState<
    FetchEmploymentSkillBadgesResponse[]
  >([]);

  const [
    newEmploymentSkillBadgeIsFetched,
    setNewEmploymentSkillBadgeIsFetched
  ] = useState<boolean>(!!skillBadge?.id);

  const {
    createSkillBadgeIsLoading,
    createSkillBadgeErrorMessage,
    createSkillBadge
  } = useCreateSkillBadge({
    cacheKeys: [skillBadgesCacheKey]
  });

  const {
    updateSkillBadgeIsLoading,
    updateSkillBadgeErrorMessage,
    updateSkillBadge
  } = useUpdateSkillBadge({
    skillBadgeNanoId:
      skillBadge?.nanoId ||
      currentSkillBadgeNanoId ||
      ('0' as SkillBadgeNanoId), // Hack, need to remove it
    cacheKeys: [skillBadgesCacheKey]
  });

  const {
    deleteSkillBadge,
    deleteSkillBadgeIsLoading,
    deleteSkillBadgeErrorMessage
  } = useDeleteSkillBadge({
    skillBadgeNanoId:
      skillBadge?.nanoId ||
      currentSkillBadgeNanoId ||
      ('0' as SkillBadgeNanoId), // Hack, need to remove it
    cacheKeys: [skillBadgesCacheKey]
  });

  const {
    employmentSkillBadges,
    employmentSkillBadgesErrorMessage,
    fetchEmploymentSkillBadges,
    changeEmploymentSkillBadgesFilters
  } = usePaginatedEmploymentSkillBadges<FetchEmploymentSkillBadgesResponse>({
    query: fetchEmploymentSkillBadgesQuery,
    cacheKey: EmploymentSkillBadgesCache.skillBadgeIndexCacheKey(
      (skillBadge?.id ||
        currentSkillBadgeId) as EmploymentSkillBadgeSkillBadgeId
    ),
    enabled: Boolean(skillBadge?.id) as FetchEmploymentSkillBadgesEnabled,
    initialFilters: {
      ...(skillBadge
        ? {
            [EmploymentSkillBadgeFields.SKILL_BADGE_ID]: {
              operator: 'eq',
              value: skillBadge.id
            }
          }
        : {})
    } as unknown as FetchEmploymentSkillBadgesFilters,
    initialSort: {
      'employments.startDate': {
        ascending: false
      }
    } as unknown as FetchEmploymentSkillBadgesSort
  });

  const {
    createEmploymentSkillBadge,
    createEmploymentSkillBadgeErrorMessage,
    createEmploymentSkillBadgeIsLoading
  } = useCreateEmploymentSkillBadge({
    cacheKeys: [
      EmploymentSkillBadgesCache.skillBadgeIndexCacheKey(
        (skillBadge?.id ||
          currentSkillBadgeId) as EmploymentSkillBadgeSkillBadgeId
      )
    ]
  });

  const { employments, employmentsErrorMessage, employmentsIsFetched } =
    usePaginatedEmployments<FetchEmploymentsResponse>({
      query: fetchEmploymentsQuery,
      cacheKey: EmploymentsCache.indexCacheKey(),
      initialPageSize: 5 as FetchEmploymentsPageSize,
      initialFilters: {
        ...(jobResumeRecruiterId
          ? {
              [EmploymentFields.JOB_RESUME_RECRUITER_ID]: {
                operator: 'eq',
                value: jobResumeRecruiterId
              }
            }
          : {}),
        ...(jobCandidateId
          ? {
              [EmploymentFields.JOB_CANDIDATE_ID]: {
                operator: 'eq',
                value: jobCandidateId
              }
            }
          : {})
      } as unknown as FetchEmploymentsFilters,
      initialSort: {
        [EmploymentFields.START_DATE]: {
          ascending: false
        }
      } as unknown as FetchEmploymentsSort
    });

  const employmentSkillBadgesToUse = useMemo(
    () =>
      skillBadge?.id !== currentSkillBadgeId && size(newEmploymentSkillBadges)
        ? newEmploymentSkillBadges
        : employmentSkillBadges || [],
    [
      newEmploymentSkillBadges,
      employmentSkillBadges,
      currentSkillBadgeId,
      skillBadge?.id
    ]
  );

  const {
    control,
    watch,
    validationErrors,
    addEditSkillBadgeFormIsLoading,
    addEditSkillBadgeFormErrorMessage,
    handleAddEditSkillBadgeForm,
    setAddEditSkillBadgeFormValue
  } = useAddEditSkillBadgeForm({
    ...(skillBadge
      ? {
          defaultValues: {
            rating: skillBadge.rating,
            employmentIds: (employmentSkillBadges.map(
              (employmentSkillBadge) => employmentSkillBadge.employmentId
            ) || []) as unknown as MayBeSkillBadgeEmploymentIds,
            experienceType: skillBadge.experienceType
          }
        }
      : {}),

    onAddEditSkillBadgeForm: async (data) => {
      if (currentScreen === ScreensEnum.EMPLOYMENT_DETAILS) {
        const noOfYears = await calculateSkillExperience(
          employmentSkillBadgesToUse
        );

        const months = Math.ceil(noOfYears * 12);

        const exp =
          typeof noOfYears === 'number' &&
          !Number.isNaN(noOfYears) &&
          typeof months === 'number' &&
          !Number.isNaN(months)
            ? noOfYears < 1
              ? `${months} mth${months === 1 ? '' : 's'}`
              : `${Math.ceil(noOfYears)} yr${
                  Math.ceil(noOfYears) === 1 ? '' : 's'
                }`
            : 'none';

        const payload = { rating: data.rating };
        if (skillBadge?.id || currentSkillBadgeId) {
          const updatePayload = {
            ...payload,
            experience: (data.rating === 'no_experience'
              ? 'none'
              : exp) as SkillBadgeExperience
          };
          await updateSkillBadge(updatePayload);
        }

        onAdd();
      } else {
        let newSkillBadgeId: SkillBadgeId | null =
          skillBadge?.id || currentSkillBadgeId;
        if (skill?.id && !currentSkillBadgeId) {
          const newSkillBadge = await createSkillBadge({
            rating: 'no_experience' as SkillBadgeRating,
            experience: 'none' as SkillBadgeExperience,
            skillId: skill.id as SkillBadgeSkillId,
            jobResumeRecruiterId:
              jobResumeRecruiterId as SkillBadgeJobResumeRecruiterId,
            jobCandidateId: jobCandidateId as SkillBadgeJobCandidateId,
            userId: currentUser.id as SkillBadgeUserId,
            nanoId: generateNanoId<SkillBadgeNanoId>(),
            requirements:
              SkillBadgeRequirementsTypes.NONE as SkillBadgeRequirements,
            experienceType: data.experienceType
          });

          if (newSkillBadge.id) {
            setCurrentSkillBadgeNanoId(newSkillBadge.nanoId);
            setCurrentSkillBadgeId(newSkillBadge.id);
            newSkillBadgeId = newSkillBadge.id;
          }
        } else {
          await updateSkillBadge({
            experienceType: data.experienceType
          });
        }
        const employmentIds = data.employmentIds as SkillBadgeEmploymentIds;
        const currentEmploymentIds = employmentSkillBadges.map(
          (employmentSkillBadge) => employmentSkillBadge.employmentId
        );
        if (!size(employmentIds) && !currentEmploymentIds) {
          return;
        }

        const removedEmploymentSkillBadges = !size(employmentIds)
          ? []
          : filter(
              employmentSkillBadges,
              (employmentSkillBadge) =>
                !includes(employmentIds, employmentSkillBadge.employmentId)
            );
        const newEmploymentIds = !size(employmentIds)
          ? []
          : filter(
              employmentIds,
              (employmentId) => !includes(currentEmploymentIds, employmentId)
            );

        const requests1 = newEmploymentIds.map((employmentId) =>
          createEmploymentSkillBadge({
            employmentId: employmentId as EmploymentSkillBadgeEmploymentId,
            skillBadgeId: newSkillBadgeId as EmploymentSkillBadgeSkillBadgeId
          })
        );

        const requests2 = removedEmploymentSkillBadges.map(
          (removedEmploymentSkillBadge) =>
            EmploymentSkillBadgesBffRequests.deleteEmploymentSkillBadge(
              removedEmploymentSkillBadge.id
            )
        );

        await Promise.all([...requests1, ...requests2]);

        setCurrentScreen(ScreensEnum.EMPLOYMENT_DETAILS);
        const newEmploymentSkillBadgesData = await fetchEmploymentSkillBadges({
          nextFilters: {
            [EmploymentSkillBadgeFields.SKILL_BADGE_ID]: {
              operator: 'eq',
              value: newSkillBadgeId as SkillBadgeId
            }
          }
        });

        setNewEmploymentSkillBadges(newEmploymentSkillBadgesData.data);
        setNewEmploymentSkillBadgeIsFetched(true);
      }
    },
    isApplicantSkillBadgeForm: true,
    isEmploymentDetailsScreen: currentScreen === ScreensEnum.EMPLOYMENT_DETAILS,
    isExperienceTypeScreen: currentScreen === ScreensEnum.EXPERIENCE_TYPE
  });

  const handleFormSubmit = useCallback<() => void>(() => {
    handleAddEditSkillBadgeForm();
  }, [handleAddEditSkillBadgeForm]);

  const handleDiscard = useCallback(async () => {
    if (currentSkillBadgeNanoId || skillBadge?.nanoId) {
      await deleteSkillBadge({});
      onDiscard();
    } else {
      onDiscard();
    }
  }, [
    onDiscard,
    currentSkillBadgeNanoId,
    skillBadge?.nanoId,
    deleteSkillBadge
  ]);

  const currentExperienceType = watch(
    SkillBadgeFields.EXPERIENCE_TYPE as 'experienceType'
  );
  const currentRating = watch(SkillBadgeFields.RATING) as SkillBadgeRatingType;

  // Update filters when skillBadge.id changes
  useEffect(() => {
    if (skillBadge?.id) {
      changeEmploymentSkillBadgesFilters({
        [EmploymentSkillBadgeFields.SKILL_BADGE_ID]: {
          operator: 'eq',
          value: skillBadge.id
        }
      });
    }
  }, [skillBadge?.id, changeEmploymentSkillBadgesFilters]);

  useEffect(() => {
    if (skillBadge?.id && skillBadge?.id !== currentSkillBadgeId) {
      setAddEditSkillBadgeFormValue(
        SkillBadgeFields.RATING as 'rating',
        skillBadge.rating
      );

      setCurrentSkillBadgeId(skillBadge.id);
    }

    if (skillBadge?.id && skillBadge?.id === currentSkillBadgeId) {
      setAddEditSkillBadgeFormValue(
        SkillBadgeFields.EMPLOYMENT_IDS as 'employmentIds',
        (employmentSkillBadges.map(
          (employmentSkillBadge) => employmentSkillBadge.employmentId
        ) || []) as unknown as MayBeSkillBadgeEmploymentIds
      );
    }
  }, [
    currentSkillBadgeId,
    skillBadge?.id,
    skillBadge?.rating,
    setCurrentSkillBadgeId,
    setAddEditSkillBadgeFormValue,
    employmentSkillBadges
  ]);

  return (
    <Stack
      p={4}
      spacing={4}
      flexDirection="column"
      bg="gray.50"
      borderRadius={4}
      border="1px solid"
      borderColor="gray.200"
    >
      <Flex alignItems="center" gap={2}>
        <Text textStyle="body1Medium">
          {skill?.name || skillBadge?.skill?.name}
        </Text>
        {currentExperienceType ? (
          <Flex alignItems="center" gap={2}>
            <Text textStyle="body1Medium" color="primary.500">
              {renderSkillBadgeExperienceType(currentExperienceType)}
            </Text>

            {currentScreen === ScreensEnum.EXPERIENCE_TYPE ? null : (
              <IconButton
                icon={<CancelIcon h={4} color="gray.500" />}
                onClick={() => {
                  setAddEditSkillBadgeFormValue(
                    SkillBadgeFields.EXPERIENCE_TYPE as 'experienceType',
                    undefined as unknown as SkillBadgeExperienceType
                  );
                  setCurrentScreen(ScreensEnum.EXPERIENCE_TYPE);
                }}
                hierarchy="ghost"
                aria-label="Remove Experience Type"
                size="extra-small"
              />
            )}
          </Flex>
        ) : null}
      </Flex>

      {currentScreen === ScreensEnum.EXPERIENCE_TYPE ? (
        <LoadingSkeleton loaded={employmentsIsFetched} count={2}>
          <AlertMessage message={employmentsErrorMessage} />
          {size(employments) > 0 ? (
            <>
              <Flex alignItems="center" flex={1}>
                <Box
                  sx={{
                    '& .chakra-radio__control': {
                      borderColor: 'gray.400',
                      bg: 'white',
                      _checked: {
                        borderColor: 'primary.500'
                      }
                    }
                  }}
                >
                  <RadioGroupField
                    control={control}
                    name={SkillBadgeFields.EXPERIENCE_TYPE}
                    options={[
                      SkillBadgeExperienceTypes.CURRENT_JOB as SkillBadgeExperienceType,
                      SkillBadgeExperienceTypes.ALL_JOBS as SkillBadgeExperienceType
                    ].map((experience) => ({
                      value: experience,
                      label: renderSkillBadgeExperienceType(experience)
                    }))}
                    onChange={(value) => {
                      if (value === SkillBadgeExperienceTypes.CURRENT_JOB) {
                        const currentJob = first(employments);
                        if (currentJob) {
                          setAddEditSkillBadgeFormValue(
                            SkillBadgeFields.EMPLOYMENT_IDS as 'employmentIds',
                            [
                              currentJob.id
                            ] as unknown as MayBeSkillBadgeEmploymentIds
                          );
                        }
                      } else {
                        const last5 = take(employments, 5);
                        setAddEditSkillBadgeFormValue(
                          SkillBadgeFields.EMPLOYMENT_IDS as 'employmentIds',
                          last5.map(
                            (employment) => employment.id
                          ) as unknown as MayBeSkillBadgeEmploymentIds
                        );
                      }
                      handleFormSubmit();
                    }}
                    errorMessage={
                      validationErrors.experienceTypeValidationError ||
                      validationErrors.employmentIdsValidationError
                    }
                  />
                </Box>
              </Flex>

              <AlertMessage
                message={
                  addEditSkillBadgeFormErrorMessage ||
                  createSkillBadgeErrorMessage ||
                  updateSkillBadgeErrorMessage ||
                  deleteSkillBadgeErrorMessage ||
                  createEmploymentSkillBadgeErrorMessage
                }
              />

              <Flex>
                <ButtonGroup ml="auto" gap={4}>
                  {skillBadge ? (
                    <PureButtonHelper
                      size="medium"
                      hierarchy="unstyled"
                      color="grey.900"
                      i18nText="Cancel"
                      onClick={onDiscard}
                    />
                  ) : (
                    <PureButtonHelper
                      size="medium"
                      hierarchy="unstyled"
                      color="red.900"
                      i18nText="Discard"
                      isLoading={deleteSkillBadgeIsLoading}
                      onClick={handleDiscard}
                    />
                  )}
                  {skillBadge ? (
                    <PureButtonHelper
                      size="medium"
                      i18nText="Update"
                      isLoading={
                        addEditSkillBadgeFormIsLoading ||
                        createSkillBadgeIsLoading ||
                        updateSkillBadgeIsLoading ||
                        createEmploymentSkillBadgeIsLoading
                      }
                      onClick={handleFormSubmit}
                    />
                  ) : null}
                </ButtonGroup>
              </Flex>
            </>
          ) : (
            <AlertMessage message="Candidate has no work history" />
          )}
        </LoadingSkeleton>
      ) : (
        <LoadingSkeleton
          loaded={newEmploymentSkillBadgeIsFetched}
          count={
            currentExperienceType === SkillBadgeExperienceTypes.CURRENT_JOB
              ? 2
              : 6
          }
        >
          <AlertMessage message={employmentSkillBadgesErrorMessage} />
          <Flex alignItems="center" flex={1}>
            <SelectField
              control={control}
              placeholder="Rate yourself in this skill"
              name={SkillBadgeFields.RATING}
              options={ratingOptions}
              errorMessage={validationErrors.ratingValidationError}
              autoFocus={true}
            />
          </Flex>
          {size(employmentSkillBadgesToUse) > 0 &&
          currentRating !== 'no_experience'
            ? employmentSkillBadgesToUse.map((employmentSkillBadge) => (
                <AddEditEmploymentSkillBadgeForm
                  key={employmentSkillBadge.nanoId}
                  employmentSkillBadgeCacheKey={EmploymentSkillBadgesCache.skillBadgeIndexCacheKey(
                    currentSkillBadgeId as EmploymentSkillBadgeSkillBadgeId
                  )}
                  employmentSkillBadge={employmentSkillBadge}
                  skillBadgesCacheKey={skillBadgesCacheKey}
                />
              ))
            : null}

          <AlertMessage
            message={
              addEditSkillBadgeFormErrorMessage ||
              createSkillBadgeErrorMessage ||
              updateSkillBadgeErrorMessage ||
              deleteSkillBadgeErrorMessage ||
              createEmploymentSkillBadgeErrorMessage
            }
          />
          <Flex>
            <ButtonGroup ml="auto" gap={4}>
              {skillBadge ? (
                <PureButtonHelper
                  size="medium"
                  hierarchy="unstyled"
                  color="grey.900"
                  i18nText="Cancel"
                  onClick={onDiscard}
                />
              ) : (
                <PureButtonHelper
                  size="medium"
                  hierarchy="unstyled"
                  color="red.900"
                  i18nText="Discard"
                  isLoading={deleteSkillBadgeIsLoading}
                  onClick={handleDiscard}
                />
              )}
              <PureButtonHelper
                size="medium"
                i18nText={skillBadge ? 'Update' : 'Add'}
                isLoading={
                  addEditSkillBadgeFormIsLoading ||
                  createSkillBadgeIsLoading ||
                  updateSkillBadgeIsLoading ||
                  createEmploymentSkillBadgeIsLoading
                }
                onClick={handleFormSubmit}
              />
            </ButtonGroup>
          </Flex>
        </LoadingSkeleton>
      )}
    </Stack>
  );
}

export default AddEditApplicantSkillBadgeForm;
