import size from 'lodash/size';
import {
  MayBeSkillBadgeEmploymentIds,
  SkillBadgeRequirements
} from '../../../../skillBadgesTypes';

const ratingRules = {
  required: 'Rating is required'
};

const experienceRules = {
  required: 'Experience is required'
};

const experienceTypeRules = {
  required: 'Experience type is required'
};

const requirementsRules = {
  required: 'Requirements are required',
  validate: {
    isValid: (value?: SkillBadgeRequirements) =>
      value ? true : 'Please select skill necessity requirements'
  }
};

const employmentIdsRules = {
  required: 'Candidate has no work history',
  validate: {
    isValid: (value?: MayBeSkillBadgeEmploymentIds) =>
      size(value) ? true : 'Candidate has no work history'
  }
};

function useAddEditSkillBadgeFormValidationRules() {
  return {
    ratingRules,
    experienceRules,
    requirementsRules,
    experienceTypeRules,
    employmentIdsRules
  };
}

export default useAddEditSkillBadgeFormValidationRules;
