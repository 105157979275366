import { useEditJobCandidatePreferencesFormValidationRules } from '../useEditJobCandidatePreferencesFormValidationRules';

import {
  ReactHookFormFieldError,
  useReactHookForm
} from '../../../../../../../../../../../../common/hooks/base/useReactHookForm';
import {
  JobCandidateCityIds,
  JobCandidateEmploymentStatus,
  JobCandidateEmploymentTypes,
  JobCandidateFields,
  JobCandidatePhoneCallAvailability,
  JobCandidateStatus,
  JobCandidateStatuses,
  JobCandidateWorkplaceTypes
} from '../../../../../../../../../../../jobCandidates/jobCandidatesTypes';
import {
  EditJobCandidatePreferencesFormData,
  OnEditJobCandidatePreferencesForm
} from '../../ViewJobCandidateDetailsAssessmentPreferencesForm.types';

const defaultEmploymentTypes: string[] = [];
const defaultWorkplaceTypes: string[] = [];

const defaultEditJobCandidatePreferencesFormValues: EditJobCandidatePreferencesFormData =
  {
    status: JobCandidateStatuses.NONE as JobCandidateStatus,
    employmentTypes: defaultEmploymentTypes as JobCandidateEmploymentTypes,
    workplaceTypes: defaultWorkplaceTypes as JobCandidateWorkplaceTypes,
    cityIds: [] as JobCandidateCityIds,
    employmentStatus: '' as JobCandidateEmploymentStatus,
    phoneCallAvailability: '' as JobCandidatePhoneCallAvailability
  };

interface EditJobCandidatePreferencesFormOptions {
  defaultValues?: Partial<EditJobCandidatePreferencesFormData>;
  onEditJobCandidatePreferencesForm: OnEditJobCandidatePreferencesForm;
}

function useEditJobCandidatePreferencesForm({
  defaultValues = {},
  onEditJobCandidatePreferencesForm
}: EditJobCandidatePreferencesFormOptions) {
  const {
    control,
    watch,
    errors,
    isLoading,
    isValid,
    isSubmitted,
    isSubmitSuccessful,
    errorMessage,
    register,
    resetForm,
    handleSubmitReactHookForm,
    setValue
  } = useReactHookForm<EditJobCandidatePreferencesFormData>({
    defaultValues: {
      ...defaultEditJobCandidatePreferencesFormValues,
      ...defaultValues
    }
  });

  const {
    statusRules,
    employmentTypesRules,
    workplaceTypesRules,
    employmentStatusRules,
    phoneCallAvailabilityRules,
    cityIdsRules
  } = useEditJobCandidatePreferencesFormValidationRules();

  return {
    control,
    watch,
    validationErrors: {
      statusValidationError: (errors.status as ReactHookFormFieldError)
        ?.message,
      employmentTypesValidationError: (
        errors.employmentTypes as ReactHookFormFieldError
      )?.message,
      workplaceTypesValidationError: (
        errors.workplaceTypes as ReactHookFormFieldError
      )?.message,
      cityIdsValidationError: (errors.cityIds as ReactHookFormFieldError)
        ?.message,
      employmentStatusValidationError: (
        errors.employmentStatus as ReactHookFormFieldError
      )?.message,
      phoneCallAvailabilityValidationError: (
        errors.phoneCallAvailability as ReactHookFormFieldError
      )?.message
    },
    editJobCandidatePreferencesFormIsLoading: isLoading,
    editJobCandidatePreferencesFormIsValid: isValid,
    editJobCandidatePreferencesFormIsSubmitted: isSubmitted,
    editJobCandidatePreferencesFormIsSubmitSuccessful: isSubmitSuccessful,
    editJobCandidatePreferencesFormErrorMessage: errorMessage,
    resetEditJobCandidatePreferencesForm: resetForm,
    setEditJobCandidatePreferencesFormValue: setValue,
    registerFields: {
      registerStatus: register(JobCandidateFields.STATUS, statusRules),
      registerEmploymentStatus: register(
        JobCandidateFields.EMPLOYMENT_STATUS,
        employmentStatusRules
      ),
      registerPhoneCallAvailability: register(
        JobCandidateFields.PHONE_CALL_AVAILABILITY,
        phoneCallAvailabilityRules
      ),
      registerEmploymentTypes: register(
        JobCandidateFields.EMPLOYMENT_TYPES,
        employmentTypesRules
      ),
      registerWorkplaceTypes: register(
        JobCandidateFields.WORKPLACE_TYPES,
        workplaceTypesRules
      ),
      registerCityIds: register(JobCandidateFields.CITY_IDS, cityIdsRules)
    },
    handleEditJobCandidatePreferencesForm: handleSubmitReactHookForm({
      onSubmit: (data) =>
        onEditJobCandidatePreferencesForm?.({
          status: data.status as JobCandidateStatus,
          employmentTypes: data.employmentTypes as JobCandidateEmploymentTypes,
          workplaceTypes: data.workplaceTypes as JobCandidateWorkplaceTypes,
          cityIds: data.cityIds as JobCandidateCityIds,
          employmentStatus:
            data.employmentStatus as JobCandidateEmploymentStatus,
          phoneCallAvailability:
            data.phoneCallAvailability as JobCandidatePhoneCallAvailability
        })
    })
  };
}

export default useEditJobCandidatePreferencesForm;
