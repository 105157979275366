import { Flex, SimpleGrid, Stack } from '@chakra-ui/layout';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';
import { PureSubmitButtonHelper } from '../../../../../helpers/buttons/PureSubmitButtonHelper';
import { InputField } from '../../../../../helpers/forms/formFields/InputField';
import { SelectField } from '../../../../../helpers/forms/formFields/SelectField';
import { SelectJobCandidateField } from '../../../../../layouts/AppLayout/components/RightSidebar/components/TasksPopup/components/formFields/SelectJobCandidateField';
import { IsLoading } from '../../../../../types';
import { generateNanoId } from '../../../../../utils/generateNanoId';
import {
  JobCandidateId,
  JobCandidateImage,
  JobCandidateName
} from '../../../../jobCandidates/jobCandidatesTypes';
import {
  WorkflowStageId,
  WorkflowStageNanoId
} from '../../../../workflowStages/workflowStagesTypes';
import { useCreateWorkflowStageTask } from '../../../hooks/useCreateWorkflowStageTask';
import { renderWorkflowStageTaskApplication } from '../../../utils/renderWorkflowStageTaskApplication';
import { renderWorkflowStageTaskDueDate } from '../../../utils/renderWorkflowStageTaskDueDate';
import { renderWorkflowStageTaskReminder } from '../../../utils/renderWorkflowStageTaskReminder';
import { WorkflowStageTasksCache } from '../../../WorkflowStageTasksCache';
import {
  WorkflowStageTaskArrangement,
  WorkflowStageTaskFields,
  WorkflowStageTaskNanoId,
  WorkflowStageTaskUserId,
  WorkflowStageTaskWorkflowStageId,
  workflowStageTaskApplications,
  workflowStageTaskDueDates,
  workflowStageTaskReminders
} from '../../../workflowStageTasksTypes';
import { AddWorkflowStageTaskFormCurrentUser } from './AddWorkflowStageTaskForm.types';
import { useAddWorkflowStageTaskForm } from './hooks/useAddWorkflowStageTaskForm';

interface AddWorkflowStageTaskFormProps {
  currentUser?: AddWorkflowStageTaskFormCurrentUser;
  workflowStageId: WorkflowStageId;
  workflowStageNanoId: WorkflowStageNanoId;
  arrangement: number;
  onCancel: () => void;
  onSave: (workflowStageTaskNanoId?: WorkflowStageTaskNanoId) => void;
  gridColumns?: number;
  jobCandidate?: {
    id: JobCandidateId;
  };
  allJobCandidates?: {
    id: JobCandidateId;
    name: JobCandidateName;
    image: JobCandidateImage;
  }[];
  handleSelectCandidate?: (jobCandidateId: JobCandidateId) => void;
  selectJobCandidateIsLoading?: boolean;
}

function AddWorkflowStageTaskForm({
  workflowStageId,
  workflowStageNanoId,
  arrangement,
  onCancel,
  onSave,
  gridColumns = 2,
  jobCandidate,
  allJobCandidates,
  handleSelectCandidate,
  selectJobCandidateIsLoading
}: AddWorkflowStageTaskFormProps) {
  const currentUser = useCurrentUser();

  const { createWorkflowStageTask } = useCreateWorkflowStageTask({
    cacheKeys: [
      WorkflowStageTasksCache.workflowStageIndexCacheKey(workflowStageNanoId)
    ]
  });

  const {
    control,
    registerFields,
    validationErrors,
    addWorkflowStageTaskFormIsLoading,
    addWorkflowStageTaskFormErrorMessage,
    handleAddWorkflowStageTaskForm
  } = useAddWorkflowStageTaskForm({
    onAddWorkflowStageTaskForm: async (data) => {
      const nanoId = generateNanoId<WorkflowStageTaskNanoId>();
      await createWorkflowStageTask({
        ...data,
        workflowStageId: workflowStageId as WorkflowStageTaskWorkflowStageId,
        userId: currentUser?.id as WorkflowStageTaskUserId,
        nanoId,
        arrangement: arrangement as WorkflowStageTaskArrangement
      });

      onSave(nanoId);
    }
  });

  return (
    <Stack as="form" gap={4} onSubmit={handleAddWorkflowStageTaskForm}>
      <SimpleGrid columns={gridColumns} spacing={4}>
        <InputField
          isRequired
          label="Activity name"
          autoFocus
          name={WorkflowStageTaskFields.NAME}
          errorMessage={validationErrors.nameValidationError}
          ref={registerFields.registerName.ref}
          onChange={registerFields.registerName.onChange}
        />

        <SelectField
          isRequired
          control={control}
          label="Activity type"
          name={WorkflowStageTaskFields.APPLICATION}
          options={workflowStageTaskApplications.map(
            (workflowStageTaskApplication) => ({
              value: workflowStageTaskApplication,
              label: renderWorkflowStageTaskApplication(
                workflowStageTaskApplication
              )
            })
          )}
          errorMessage={validationErrors.applicationValidationError}
        />

        {jobCandidate && allJobCandidates && handleSelectCandidate ? (
          <SelectJobCandidateField
            selectedJobCandidate={jobCandidate}
            jobCandidates={allJobCandidates}
            handleSelectCandidate={handleSelectCandidate}
          />
        ) : null}

        <SelectField
          isRequired
          control={control}
          label="Due date"
          name={WorkflowStageTaskFields.DUE_DATE}
          options={workflowStageTaskDueDates.map(
            (workflowStageTaskDueDate) => ({
              value: workflowStageTaskDueDate,
              label: renderWorkflowStageTaskDueDate(workflowStageTaskDueDate)
            })
          )}
          errorMessage={validationErrors.dueDateValidationError}
        />

        <SelectField
          control={control}
          label="Reminder"
          name={WorkflowStageTaskFields.REMINDER}
          options={workflowStageTaskReminders.map((reminder) => ({
            value: reminder,
            label: renderWorkflowStageTaskReminder(reminder)
          }))}
          errorMessage={validationErrors.automationValidationError}
        />
      </SimpleGrid>

      <AlertMessage message={addWorkflowStageTaskFormErrorMessage} />

      <Flex justifyContent="flex-end" gap={2}>
        <PureButtonHelper
          size="small"
          hierarchy="tertiary"
          onClick={onCancel}
          i18nText="Cancel"
        />
        <PureSubmitButtonHelper
          size="small"
          isLoading={
            addWorkflowStageTaskFormIsLoading ||
            (selectJobCandidateIsLoading as IsLoading)
          }
          i18nText="Save"
        />
      </Flex>
    </Stack>
  );
}

export default AddWorkflowStageTaskForm;
