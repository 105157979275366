import {
  Flex,
  LayoutProps,
  PlacementWithLogical,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure
} from '@chakra-ui/react';
import { useState } from 'react';
import { AvatarHelper } from '../../../../../../helpers/AvatarHelper';
import { Button, ButtonProps } from '../../../../../../helpers/Button';
import { Text } from '../../../../../../helpers/Text';
import { ChevronDownIcon } from '../../../../../../icons/ChevronDownIcon';
import {
  UserId,
  UserImage,
  UserName,
  UserNanoId
} from '../../../../../users/usersTypes';
import { ViewJobCandidateDetailsPageNavRecruiterOwnerUsers } from '../ViewJobCandidateDetailsPageNavRecruiterOwnerUsers';

export type ViewJobCandidateDetailsRecruiterOwnerType = {
  id: UserId;
  nanoId: UserNanoId;
  name: UserName;
  image: UserImage;
};

interface ViewJobCandidateDetailsPageNavRecruiterOwnerDropdownProps
  extends ButtonProps {
  placement?: PlacementWithLogical;
  popoverWidth?: LayoutProps['width'];
  onOptionSelect: (userId: UserId) => void;
  currentOwner?: ViewJobCandidateDetailsRecruiterOwnerType;
}

function ViewJobCandidateDetailsPageNavRecruiterOwnerDropdown({
  w,
  width,
  placeholder,
  currentOwner,
  popoverWidth,
  onOptionSelect,
  placement = 'bottom-end',
  ...rest
}: ViewJobCandidateDetailsPageNavRecruiterOwnerDropdownProps) {
  const [selectedUser, setSelectedUser] = useState<
    ViewJobCandidateDetailsRecruiterOwnerType | undefined
  >(currentOwner);
  const { isOpen, onToggle, onClose } = useDisclosure();

  const handleSelectUser = (
    user: ViewJobCandidateDetailsRecruiterOwnerType
  ) => {
    onOptionSelect(user.id);
    setSelectedUser(user);
    onClose();
  };

  return (
    <Popover isLazy isOpen={isOpen} onClose={onClose} placement={placement}>
      <PopoverTrigger>
        <Flex alignItems="stretch" w={w} width={width}>
          <Button
            p={0}
            h="100%"
            w="full"
            minH={6}
            minW={0}
            size="small"
            display="flex"
            alignItems="center"
            onClick={(e) => {
              e.stopPropagation();
              if (!rest?.isDisabled) {
                onToggle();
              }
            }}
            border="none"
            textAlign="left"
            boxShadow="none"
            fontSize="inherit"
            hierarchy="unstyled"
            aria-label="DropdownSelect"
            {...rest}
          >
            <Flex
              gap={2}
              flex={1}
              minW={0}
              alignItems="center"
              fontSize="inherit"
            >
              <Flex gap={2} flex={1} minW={0} alignItems="center">
                {selectedUser ? (
                  <>
                    <AvatarHelper
                      size="xs"
                      name={selectedUser.name}
                      image={selectedUser?.image}
                    />

                    <Text
                      flex={1}
                      isTruncated
                      textStyle="body1Medium"
                      noOfLines={1}
                      minW={0}
                    >
                      {selectedUser?.name}
                    </Text>
                  </>
                ) : (
                  <Text
                    flex={1}
                    isTruncated
                    textStyle="body1Medium"
                    noOfLines={1}
                    minW={0}
                    color="gray.500"
                  >
                    {placeholder}
                  </Text>
                )}
              </Flex>

              <Flex w={5} h={5} flexShrink={0}>
                <ChevronDownIcon fill="gray.500" />
              </Flex>
            </Flex>
          </Button>
        </Flex>
      </PopoverTrigger>

      <Portal>
        <PopoverContent maxH={60} overflow="hidden" w={popoverWidth}>
          <ViewJobCandidateDetailsPageNavRecruiterOwnerUsers
            selectedUserId={selectedUser?.id}
            onSelectUser={handleSelectUser}
          />
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

export default ViewJobCandidateDetailsPageNavRecruiterOwnerDropdown;
