import { CircularProgress, Flex } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { Text } from '../../../../../../helpers/Text';
import { useChakraToast } from '../../../../../../helpers/useChakraToast';
import { useFetchJobCandidateByNanoId } from '../../../../../jobCandidates/hooks/useFetchJobCandidateByNanoId';
import { useUpdateJobCandidateOwner } from '../../../../../jobCandidates/hooks/useUpdateJobCandidateOwner';
import { JobCandidatesCache } from '../../../../../jobCandidates/JobCandidatesCache';
import {
  JobCandidateId,
  JobCandidateNanoId
} from '../../../../../jobCandidates/jobCandidatesTypes';
import {
  FetchJobCandidateResponse,
  fetchJobCandidateQuery
} from '../../../../../jobCandidates/queries/fetchJobCandidate.query';
import { UserId } from '../../../../../users/usersTypes';
import { JobNanoId } from '../../../../jobsTypes';
import { ViewJobCandidateDetailsPageNavRecruiterOwnerDropdown } from '../ViewJobCandidateDetailsPageNavRecruiterOwnerDropdown';

interface ViewJobCandidateDetailsPageNavRecruiterOwnerProps {
  jobNanoId: JobNanoId;
  jobCandidateNanoId: JobCandidateNanoId;
}

export default function ViewJobCandidateDetailsPageNavRecruiterOwner({
  jobNanoId,
  jobCandidateNanoId
}: ViewJobCandidateDetailsPageNavRecruiterOwnerProps) {
  const toast = useChakraToast();

  const { jobCandidate } =
    useFetchJobCandidateByNanoId<FetchJobCandidateResponse>({
      jobCandidateNanoId: jobCandidateNanoId as JobCandidateNanoId,
      query: fetchJobCandidateQuery,
      cacheKey: JobCandidatesCache.showCacheKey()
    });

  const {
    updateJobCandidateOwner,
    updateJobCandidateOwnerReset,
    updateJobCandidateOwnerIsLoading,
    updateJobCandidateOwnerErrorMessage
  } = useUpdateJobCandidateOwner({
    jobCandidateNanoId: jobCandidate?.id as JobCandidateId,
    cacheKeys: [
      JobCandidatesCache.showCacheKey(),
      JobCandidatesCache.jobIndexCacheKey(jobNanoId)
    ]
  });

  useEffect(() => {
    if (updateJobCandidateOwnerErrorMessage) {
      toast({ title: updateJobCandidateOwnerErrorMessage, status: 'error' });
      updateJobCandidateOwnerReset();
    }
  }, [
    toast,
    updateJobCandidateOwnerReset,
    updateJobCandidateOwnerErrorMessage
  ]);

  const handleUpdateJobCandidateOwner = useCallback(
    async (userId: UserId) => {
      await updateJobCandidateOwner({ ownerId: userId });

      updateJobCandidateOwnerReset();
      toast.closeAll();

      toast({
        title: `Owner has been updated successfully`,
        status: 'success'
      });
    },
    [toast, updateJobCandidateOwner, updateJobCandidateOwnerReset]
  );

  return (
    <Flex flex={1}>
      <Flex ml="auto" alignItems="center" gap={2}>
        <Text textStyle="body1Medium" color="gray.600">
          Owner
        </Text>

        <Flex pos="relative" alignItems="center">
          <ViewJobCandidateDetailsPageNavRecruiterOwnerDropdown
            w={44}
            px={2}
            py={1.5}
            bg="white"
            popoverWidth="64"
            alignItems="center"
            border="1px solid"
            borderRadius="base"
            fontWeight="normal"
            borderColor="gray.300"
            placeholder="Select Recruiter"
            currentOwner={jobCandidate?.owner}
            onOptionSelect={handleUpdateJobCandidateOwner}
          />

          {updateJobCandidateOwnerIsLoading && (
            <CircularProgress
              bg="white"
              right="7px"
              pos="absolute"
              isIndeterminate
              thickness={6}
              size={6}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
