import { Stack } from '@chakra-ui/react';

import { Text } from '../../../../../../../helpers/Text';

import { JobCandidateId } from '../../../../../../jobCandidates/jobCandidatesTypes';
import { CommonSkillsBox } from '../../../../../../skills/components/boxes/CommonSkillsBox';
import { SkillKinds } from '../../../../../../skills/skillsTypes';
import { SkillBadgesCache } from '../../../../../../skillBadges/SkillBadgesCache';
import { SkillsCache } from '../../../../../../skills/SkillsCache';
import { SkillBadgeJobCandidateId } from '../../../../../../skillBadges/skillBadgesTypes';
import { EmploymentSkillActionLabelFields } from '../../../../../../employmentSkillBadges/employmentSkillBadgesTypes';

interface HiringPortalLeadDrawerSkillsProps {
  jobCandidateId: JobCandidateId;
}

function HiringPortalLeadDrawerSkills({
  jobCandidateId
}: HiringPortalLeadDrawerSkillsProps) {
  return (
    <>
      <Stack p={4} bg="gray.50" spacing={3}>
        <Text textStyle="upperCase1Medium" color="gray.600">
          Computer skills
        </Text>

        <CommonSkillsBox
          actionLabel={EmploymentSkillActionLabelFields.COMPUTER_SKILLS}
          withoutAddBlock
          showSkillBadgeSkillNameOnly
          skillKind={SkillKinds.COMPUTER}
          jobCandidateId={jobCandidateId}
          skillBadgesCacheKey={SkillBadgesCache.jobCandidateCacheKey(
            jobCandidateId as SkillBadgeJobCandidateId
          )}
          skillsCacheKey={SkillsCache.computerCacheKey()}
          newSkillsCacheKey={SkillsCache.computerNewSkillsCacheKey()}
        />
      </Stack>

      <Stack p={4} bg="gray.50" spacing={3}>
        <Text textStyle="upperCase1Medium" color="gray.600">
          Industry skills
        </Text>

        <CommonSkillsBox
          actionLabel={EmploymentSkillActionLabelFields.INDUSTRY_KNOWLEDGE}
          withoutAddBlock
          showSkillBadgeSkillNameOnly
          skillKind={SkillKinds.INDUSTRY}
          jobCandidateId={jobCandidateId}
          skillBadgesCacheKey={SkillBadgesCache.jobCandidateCacheKey(
            jobCandidateId as SkillBadgeJobCandidateId
          )}
          skillsCacheKey={SkillsCache.industryCacheKey()}
          newSkillsCacheKey={SkillsCache.industryNewSkillsCacheKey()}
        />
      </Stack>
    </>
  );
}

export default HiringPortalLeadDrawerSkills;
