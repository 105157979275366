import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';

import { CleaveInputFieldControl } from './components/CleaveInputFieldControl';

import { CleaveInputFieldProps } from './CleaveInputField.types';

function CleaveInputField<T extends FieldValues>({
  control,
  name,
  ...props
}: CleaveInputFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        return (
          <CleaveInputFieldControl
            name={name}
            value={value}
            onChange={onChange}
            {...props}
          />
        );
      }}
    />
  );
}

export default CleaveInputField;
