import { CircularProgress, Flex } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import size from 'lodash/size';
import { Fragment } from 'react';
import { AvatarHelper } from '../../../../../../helpers/AvatarHelper';
import { Button, ButtonProps } from '../../../../../../helpers/Button';
import { InputField } from '../../../../../../helpers/forms/formFields/InputField';
import { Text } from '../../../../../../helpers/Text';
import { IsDisabled } from '../../../../../../types';
import { usePaginatedUsers } from '../../../../../users/hooks/usePaginatedUsers';
import { fetchUserQuery } from '../../../../../users/queries/fetchUser.query';
import {
  FetchUsersResponse,
  fetchUsersQuery
} from '../../../../../users/queries/fetchUsers.query';
import { UsersCache } from '../../../../../users/UsersCache';
import {
  FetchUsersCountType,
  FetchUsersFilters,
  FetchUsersPrefetchNextPage,
  FetchUsersSort,
  UserCaptureTypes,
  UserFields,
  UserSearchTypes
} from '../../../../../users/usersTypes';
import { ViewJobCandidateDetailsRecruiterOwnerType } from '../ViewJobCandidateDetailsPageNavRecruiterOwnerDropdown/ViewJobCandidateDetailsPageNavRecruiterOwnerDropdown';

interface ViewJobCandidateDetailsPageNavRecruiterOwnerUsersProps
  extends ButtonProps {
  selectedUserId?: string;
  onSelectUser: (user: ViewJobCandidateDetailsRecruiterOwnerType) => void;
}

function ViewJobCandidateDetailsPageNavRecruiterOwnerUsers({
  onSelectUser,
  selectedUserId
}: ViewJobCandidateDetailsPageNavRecruiterOwnerUsersProps) {
  const { users, changeUsersFilters, usersIsLoading } =
    usePaginatedUsers<FetchUsersResponse>({
      cacheKey: UsersCache.candidatesUsersCacheKey(),
      query: fetchUsersQuery,
      countType: 'exact' as FetchUsersCountType,
      prefetchNextPage: true as FetchUsersPrefetchNextPage,
      fetchItemCacheKey: UsersCache.showCacheKey(),
      itemQuery: fetchUserQuery,
      initialFilters: {
        [UserFields.CAPTURE_TYPE]: {
          operator: 'eq',
          value: UserCaptureTypes.RECRUITER
        },
        [UserFields.SEARCH_TYPE]: {
          operator: 'eq',
          value: UserSearchTypes.USER
        }
      } as unknown as FetchUsersFilters,
      initialSort: {
        [UserFields.NAME]: { ascending: true }
      } as unknown as FetchUsersSort
    });

  const debouncedFilterUsers = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeUsersFilters(
        {
          [UserFields.NAME]: {
            operator: 'ilike',
            value: `%${updatedValue}%`
          }
        },
        [UserFields.ID]
      ),
    500
  );

  return (
    <>
      <Flex p={2} pb={0}>
        <InputField
          px={1}
          py={0.5}
          size="small"
          fontSize="sm"
          placeholder="Search"
          borderRadius="none"
          borderWidth={0}
          borderBottomWidth="1px"
          onChange={(e) => debouncedFilterUsers(e.target.value)}
          outline="none"
        />
      </Flex>

      <Flex flexDir="column" overflow="auto" p={2}>
        {usersIsLoading && (
          <Flex h={10} alignItems="center" justifyContent="center">
            <CircularProgress size={8} thickness={6} isIndeterminate />
          </Flex>
        )}

        {size(users) === 0 && !usersIsLoading && (
          <Flex h={10} alignItems="center" justifyContent="center">
            No user found
          </Flex>
        )}

        {users.map((user) => (
          <Fragment key={user.id}>
            {user.name && user.id && (
              <Button
                type="button"
                px={2}
                py={1.5}
                h="auto"
                size="small"
                value={user.id}
                color="inherit"
                textAlign="left"
                fontWeight="400"
                lineHeight={1.5}
                fontSize="inherit"
                borderRadius="none"
                hierarchy="unstyled"
                alignItems="flex-start"
                isDisabled={(selectedUserId === user.id) as IsDisabled}
                onClick={(e) => {
                  e.stopPropagation();
                  onSelectUser({
                    id: user.id,
                    nanoId: user.nanoId,
                    name: user.name,
                    image: user.image
                  });
                }}
                sx={{
                  fontWeight: '400 !important'
                }}
                _hover={{
                  bg: 'gray.100'
                }}
              >
                <Flex gap={2}>
                  <AvatarHelper size="xs" name={user.name} image={user.image} />

                  <Text noOfLines={1} textStyle="body1Regular" isTruncated>
                    {user.name}
                  </Text>
                </Flex>
              </Button>
            )}
          </Fragment>
        ))}
      </Flex>
    </>
  );
}

export default ViewJobCandidateDetailsPageNavRecruiterOwnerUsers;
