import {
  Avatar,
  AvatarGroup,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import { ArcElement, Chart, Legend, Tooltip } from 'chart.js';
import filter from 'lodash/filter';
import size from 'lodash/size';
import { Doughnut } from 'react-chartjs-2';
import { Heading } from '../../../../../../../../helpers/Heading';
import { Text } from '../../../../../../../../helpers/Text';
import { NoScorecardIcon } from '../../../../../../../../icons/NoScorecardIcon';
import { CandidateSubmissionJobCandidatesCache } from '../../../../../../../candidateSubmissionJobCandidates/CandidateSubmissionJobCandidatesCache';
import {
  CandidateSubmissionJobCandidateFields,
  FetchCandidateSubmissionJobCandidatesFilters,
  FetchCandidateSubmissionJobCandidatesPageSize
} from '../../../../../../../candidateSubmissionJobCandidates/candidateSubmissionJobCandidatesTypes';
import { usePaginatedCandidateSubmissionJobCandidates } from '../../../../../../../candidateSubmissionJobCandidates/hooks/usePaginatedCandidateSubmissionJobCandidates';
import {
  FetchCandidateSubmissionJobCandidatesResponse,
  fetchCandidateSubmissionJobCandidatesQuery
} from '../../../../../../../candidateSubmissionJobCandidates/queries/fetchCandidateSubmissionJobCandidates.query';
import { ViewJobCandidateDetailsCandidate } from '../../../../pages/ViewJobCandidateDetailsProfilePage/ViewJobCandidateDetailsProfilePage.types';
import map from 'lodash/map';
import { ViewJobCandidateDetailsProfileOverviewJobFitScorecardItem } from '../ViewJobCandidateDetailsProfileOverviewJobFitScorecardItem';
import { mockScorecardData } from './mockdata';
import { useEffect, useRef } from 'react';
import { Button } from '../../../../../../../../helpers/Button';

Chart.register(ArcElement, Tooltip, Legend);

export type ViewJobCandidateDetailsProfileOverviewJobFitScorecardSubField = {
  label: string;
  value: number;
  color: string;
};

export type ViewJobCandidateDetailsProfileOverviewJobFitScorecardField = {
  label: string;
  value: number;
  color: string;
  subfields: ViewJobCandidateDetailsProfileOverviewJobFitScorecardSubField[];
};

interface ViewJobCandidateDetailsProfileOverviewJobFitScorecardProps {
  jobCandidate: ViewJobCandidateDetailsCandidate;
}

function ViewJobCandidateDetailsProfileOverviewJobFitScorecard({
  jobCandidate
}: ViewJobCandidateDetailsProfileOverviewJobFitScorecardProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const popoverTriggerRef = useRef<HTMLDivElement>(null);

  const {
    candidateSubmissionJobCandidates,
    candidateSubmissionJobCandidatesIsFetched
  } = usePaginatedCandidateSubmissionJobCandidates<FetchCandidateSubmissionJobCandidatesResponse>(
    {
      query: fetchCandidateSubmissionJobCandidatesQuery,
      cacheKey: CandidateSubmissionJobCandidatesCache.jobCandidateIndexCacheKey(
        jobCandidate.nanoId
      ),
      initialFilters: {
        [CandidateSubmissionJobCandidateFields.JOB_CANDIDATE_ID]: {
          operator: 'eq',
          value: jobCandidate.id
        }
      } as unknown as FetchCandidateSubmissionJobCandidatesFilters,
      initialPageSize: 15 as FetchCandidateSubmissionJobCandidatesPageSize
    }
  );

  const allSubmissionsWithEvaluation = filter(
    candidateSubmissionJobCandidates,
    (sumCan) => size(sumCan.candidateSubmission?.evaluations) > 0
  );

  const numberOfReviews = size(allSubmissionsWithEvaluation);

  const fields = mockScorecardData;

  const data = {
    datasets: [
      {
        data: map(fields, 'value'),
        backgroundColor: map(fields, 'color'),
        hoverOffset: 0,
        borderWidth: 0
      }
    ]
  };

  const options = {
    plugins: {
      tooltip: { enabled: false },
      legend: { display: false }
    },
    cutout: '75%',
    maintainAspectRatio: false,
    devicePixelRatio: 2,
    responsive: true
  };

  useEffect(() => {
    const currentTarget = popoverTriggerRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          onClose();
        }
      },
      { threshold: 0.25 }
    );

    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [onClose]);

  const hasReviews =
    candidateSubmissionJobCandidatesIsFetched && numberOfReviews > 0;

  return (
    <Stack p={4} gap={3} flex={1} bg="white" borderRadius="base">
      <Flex gap={2} alignItems="center">
        <Heading
          m={0}
          p={0}
          level="h4"
          fontSize="md"
          lineHeight={1}
          fontWeight="semibold"
        >
          Scorecards
        </Heading>
      </Flex>

      <Flex alignItems="center" justify="center">
        <Popover placement="right-start" isOpen={isOpen} onClose={onClose}>
          <PopoverTrigger>
            <Flex ref={popoverTriggerRef}>
              <Button
                h="auto"
                pos="relative"
                display="flex"
                whiteSpace="normal"
                hierarchy="unstyled"
                onClick={onOpen}
                bg="transparent"
              >
                <Flex w={36} zIndex={1} aspectRatio="square" pos="relative">
                  {hasReviews ? (
                    <>
                      <Doughnut data={data} options={options} />

                      <Flex
                        w={36}
                        h={36}
                        top={0}
                        pos="absolute"
                        flexDir="column"
                        alignItems="center"
                        borderRadius="full"
                        justifyContent="center"
                        zIndex={-1}
                      >
                        <Text
                          color="gray.800"
                          lineHeight="shorter"
                          fontWeight="semibold"
                          fontSize="3xl"
                        >
                          70
                        </Text>

                        <Text textStyle="body1Medium" color="gray.500">
                          out of 100
                        </Text>
                      </Flex>
                    </>
                  ) : (
                    <>
                      <Doughnut
                        data={{
                          datasets: [
                            {
                              data: [1],
                              hoverOffset: 0,
                              borderWidth: 0,
                              backgroundColor: ['#F4F6F7']
                            }
                          ]
                        }}
                        options={options}
                      />

                      <Flex
                        w={36}
                        h={36}
                        top={0}
                        pos="absolute"
                        flexDir="column"
                        textAlign="center"
                        alignItems="center"
                        borderRadius="full"
                        justifyContent="center"
                        zIndex={-1}
                      >
                        <Text w="24" textStyle="body1Medium" color="gray.600">
                          No score available
                        </Text>
                      </Flex>
                    </>
                  )}
                </Flex>
              </Button>
            </Flex>
          </PopoverTrigger>

          <Portal>
            <PopoverContent w="sm">
              <PopoverBody p={4}>
                <Heading level="h5" lineHeight="shorter">
                  Job Fit Score
                </Heading>

                {hasReviews ? (
                  <Flex pt={2} gap={4} flexDir="column">
                    <Flex gap={3} alignItems="baseline">
                      <Flex alignItems="baseline">
                        <Text
                          color="gray.800"
                          fontWeight="semibold"
                          fontSize="3xl"
                        >
                          70
                        </Text>

                        <Text color="gray.500" fontWeight="medium">
                          /100
                        </Text>
                      </Flex>

                      <Text ml="auto" color="green.600" fontWeight="medium">
                        Good fit
                      </Text>
                    </Flex>

                    <Flex gap={3} flexDir="column">
                      {fields.map((item) => (
                        <ViewJobCandidateDetailsProfileOverviewJobFitScorecardItem
                          key={item.label}
                          field={item}
                        />
                      ))}
                    </Flex>
                  </Flex>
                ) : (
                  <Flex gap={2} flexDir="column" alignItems="center" py={6}>
                    <NoScorecardIcon />

                    <Text
                      fontWeight="medium"
                      textAlign="center"
                      fontSize="md"
                      maxW="2xs"
                    >
                      No score available
                    </Text>

                    <Text
                      textStyle="body1Medium"
                      textAlign="center"
                      color="gray.600"
                      maxW="2xs"
                    >
                      Some text explainnig why the score isn’t available here
                    </Text>
                  </Flex>
                )}
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </Flex>

      <Flex justifyContent="flex-end">
        <AvatarGroup spacing={-2}>
          <Avatar src="/images/default-avatar-3.png" size="sm" />
          <Avatar src="/images/default-avatar-5.png" size="sm" />
          <Avatar src="/images/default-avatar-2.png" size="sm" />
        </AvatarGroup>
      </Flex>
    </Stack>
  );
}

export default ViewJobCandidateDetailsProfileOverviewJobFitScorecard;
