import {
  Flex,
  FlexProps,
  FormLabel,
  Tooltip,
  useRadioGroup,
  UseRadioGroupProps
} from '@chakra-ui/react';
import { forwardRef, ReactNode } from 'react';
import { Text } from '../../../../../Text';
import { numericRatingOptions } from '../../NumericRatingField.data';
import { NumericRatingRadio } from '../NumericRatingRadio';

export type NumericRatingFieldSizes = 'sm' | 'md' | 'lg';

interface NumericRatingFieldControlProps
  extends Omit<FlexProps, 'children' | 'defaultValue' | 'onChange'>,
    UseRadioGroupProps {
  options?: {
    label?: ReactNode;
    value: string;
  }[];
  errorMessage?: string;
  label?: string;
  isReadOnly?: boolean;
  size?: NumericRatingFieldSizes;
}

const NumericRatingFieldControl = forwardRef<
  HTMLDivElement,
  NumericRatingFieldControlProps
>(
  (
    {
      name,
      value,
      label,
      isNative,
      onChange,
      isReadOnly,
      isDisabled,
      isFocusable,
      defaultValue,
      errorMessage,
      size = 'md',
      options = defaultOptions({ selectedValue: value, size, isReadOnly }),
      ...props
    },
    ref
  ) => {
    const { getRootProps, getRadioProps } = useRadioGroup({
      name,
      value,
      onChange,
      isNative,
      isDisabled,
      isFocusable,
      defaultValue
    });

    const group = getRootProps();
    const gapMap = {
      sm: 1,
      md: 2,
      lg: 3
    };

    return (
      <Flex flexDir="column" gap={2}>
        {label && <FormLabel>{label}</FormLabel>}

        <Flex gap={gapMap[size]} {...group} {...props} ref={ref}>
          {options.map((option) => {
            const radio = getRadioProps({ value: option.value });

            return (
              <NumericRatingRadio
                key={option.value}
                label={option.label}
                isReadOnly={isReadOnly}
                itemStyles={{
                  color: 'gray.500'
                }}
                {...radio}
              />
            );
          })}
        </Flex>

        {errorMessage && (
          <Text color="red.500" wordBreak="break-all">
            {errorMessage}
          </Text>
        )}
      </Flex>
    );
  }
);

NumericRatingFieldControl.displayName = 'NumericRatingFieldControl';

export default NumericRatingFieldControl;

const defaultOptions = ({
  size = 'md',
  isReadOnly,
  selectedValue
}: {
  isReadOnly?: boolean;
  selectedValue?: string;
  size: NumericRatingFieldSizes;
}) => {
  const sizeMap = {
    sm: { fontSize: 'xs', width: '18px', height: '18px' },
    md: { fontSize: 'sm', width: 6, height: 6 },
    lg: { fontSize: 'md', width: 7, height: 7 }
  };

  return numericRatingOptions.map(({ value, label, description }) => ({
    value,
    label: (
      <Tooltip label={isReadOnly ? null : description} placement="top" hasArrow>
        <Flex
          rounded="full"
          alignItems="center"
          border="1px solid"
          borderColor="primary.500"
          justifyContent="center"
          w={sizeMap[size].width}
          h={sizeMap[size].height}
          color={selectedValue === value.toString() ? 'white' : 'primary.500'}
          bg={
            selectedValue === value.toString() ? 'primary.500' : 'transparent'
          }
        >
          <Text
            noOfLines={1}
            fontWeight="medium"
            fontSize={sizeMap[size].fontSize}
          >
            {label}
          </Text>
        </Flex>
      </Tooltip>
    )
  }));
};
