import { NumericRatingFieldValues } from './NumericRatingField.types';

export const numericRatingOptions = [
  {
    label: 1,
    description: 'Poor',
    value: NumericRatingFieldValues.POOR
  },
  {
    label: 2,
    description: 'Unsatisfactory',
    value: NumericRatingFieldValues.UNSATISFACTORY
  },
  {
    label: 3,
    description: 'Satisfactory',
    value: NumericRatingFieldValues.SATISFACTORY
  },
  {
    label: 4,
    description: 'Good',
    value: NumericRatingFieldValues.GOOD
  },
  {
    label: 5,
    description: 'Great',
    value: NumericRatingFieldValues.GREAT
  }
];
