import snakeCase from 'lodash/snakeCase';

import {
  FetchJobCandidateWorkflowStagesQuery,
  JobCandidateWorkflowStageCreatedAt,
  JobCandidateWorkflowStageFields,
  JobCandidateWorkflowStageId,
  JobCandidateWorkflowStageJobCandidate,
  JobCandidateWorkflowStageJobCandidateId,
  JobCandidateWorkflowStageSentAt,
  JobCandidateWorkflowStageSettings,
  JobCandidateWorkflowStageStatus,
  JobCandidateWorkflowStageUpdatedAt,
  JobCandidateWorkflowStageUser,
  JobCandidateWorkflowStageUserId
} from '../jobCandidateWorkflowStagesTypes';

export interface FetchJobCandidateWorkflowStagesResponse {
  [JobCandidateWorkflowStageFields.ID]: JobCandidateWorkflowStageId;
  [JobCandidateWorkflowStageFields.CREATED_AT]: JobCandidateWorkflowStageCreatedAt;
  [JobCandidateWorkflowStageFields.UPDATED_AT]: JobCandidateWorkflowStageUpdatedAt;
  [JobCandidateWorkflowStageFields.SENT_AT]: JobCandidateWorkflowStageSentAt;
  [JobCandidateWorkflowStageFields.USER_ID]: JobCandidateWorkflowStageUserId;
  [JobCandidateWorkflowStageFields.USER]: JobCandidateWorkflowStageUser;
  [JobCandidateWorkflowStageFields.STATUS]: JobCandidateWorkflowStageStatus;
  [JobCandidateWorkflowStageFields.JOB_CANDIDATE_ID]: JobCandidateWorkflowStageJobCandidateId;
  [JobCandidateWorkflowStageFields.JOB_CANDIDATE]: JobCandidateWorkflowStageJobCandidate;
  [JobCandidateWorkflowStageFields.SETTINGS]: JobCandidateWorkflowStageSettings;
}

export const fetchJobCandidateWorkflowStagesColumns = [
  JobCandidateWorkflowStageFields.ID,
  JobCandidateWorkflowStageFields.NANO_ID,
  JobCandidateWorkflowStageFields.CREATED_AT,
  JobCandidateWorkflowStageFields.UPDATED_AT,
  JobCandidateWorkflowStageFields.SENT_AT,
  JobCandidateWorkflowStageFields.USER_ID,
  JobCandidateWorkflowStageFields.USER,
  JobCandidateWorkflowStageFields.STATUS,
  JobCandidateWorkflowStageFields.JOB_CANDIDATE_ID,
  JobCandidateWorkflowStageFields.JOB_CANDIDATE,
  JobCandidateWorkflowStageFields.SETTINGS
];

const fetchJobCandidateWorkflowStagesSBColumns =
  fetchJobCandidateWorkflowStagesColumns.map(
    (fetchJobCandidateWorkflowStagesColumn) =>
      snakeCase(fetchJobCandidateWorkflowStagesColumn)
  );

export const fetchJobCandidateWorkflowStagesQuery =
  fetchJobCandidateWorkflowStagesSBColumns.join(
    ','
  ) as FetchJobCandidateWorkflowStagesQuery;
