import { JobId } from '../jobs/jobsTypes';

import { JobCandidateId } from '../jobCandidates/jobCandidatesTypes';

import {
  FetchJobCandidateWorkflowStageIndexQueryName,
  FetchJobCandidateWorkflowStageQuery,
  FetchJobCandidateWorkflowStagesCountType,
  FetchJobCandidateWorkflowStagesFilters,
  FetchJobCandidateWorkflowStagesIndexCountType,
  FetchJobCandidateWorkflowStagesIndexFilters,
  FetchJobCandidateWorkflowStagesIndexPage,
  FetchJobCandidateWorkflowStagesIndexPageSize,
  FetchJobCandidateWorkflowStagesIndexQueryName,
  FetchJobCandidateWorkflowStagesIndexSort,
  FetchJobCandidateWorkflowStagesPage,
  FetchJobCandidateWorkflowStagesPageSize,
  FetchJobCandidateWorkflowStagesQuery,
  FetchJobCandidateWorkflowStagesSort,
  JobCandidateWorkflowStageId,
  JobCandidateWorkflowStageNanoId,
  JobCandidateWorkflowStageSettings
} from './jobCandidateWorkflowStagesTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { JobCandidateWorkflowStagesBffRoutes } from './JobCandidateWorkflowStagesBffRoutes';

interface FetchJobCandidateWorkflowStagesRequestParams {
  query: FetchJobCandidateWorkflowStagesQuery;
  countType?: FetchJobCandidateWorkflowStagesCountType;
  page?: FetchJobCandidateWorkflowStagesPage;
  pageSize?: FetchJobCandidateWorkflowStagesPageSize;
  filters?: FetchJobCandidateWorkflowStagesFilters;
  sort?: FetchJobCandidateWorkflowStagesSort;
}

interface FetchJobCandidateWorkflowStagesIndexRequestParams {
  queryName: FetchJobCandidateWorkflowStagesIndexQueryName;
  countType?: FetchJobCandidateWorkflowStagesIndexCountType;
  page?: FetchJobCandidateWorkflowStagesIndexPage;
  pageSize?: FetchJobCandidateWorkflowStagesIndexPageSize;
  filters?: FetchJobCandidateWorkflowStagesIndexFilters;
  sort?: FetchJobCandidateWorkflowStagesIndexSort;
}

interface FetchJobCandidateWorkflowStageRequestParams {
  query: FetchJobCandidateWorkflowStageQuery;
}

interface FetchJobCandidateWorkflowStageIndexRequestParams {
  queryName: FetchJobCandidateWorkflowStageIndexQueryName;
}

export interface JobCandidateWorkflowStageRequestData {
  settings: JobCandidateWorkflowStageSettings;
  jobId: JobId;
  jobCandidateId: JobCandidateId;
}

export interface CreateJobCandidateWorkflowStageRequestData
  extends JobCandidateWorkflowStageRequestData {
  id?: JobCandidateWorkflowStageId;
}

export class JobCandidateWorkflowStagesBffRequests extends BaseBffRequests {
  static fetchJobCandidateWorkflowStages<T>(
    params: FetchJobCandidateWorkflowStagesRequestParams
  ) {
    return this.fetchItems<T>(
      JobCandidateWorkflowStagesBffRoutes.fetchJobCandidateWorkflowStagesRoute(),
      params
    );
  }

  static fetchJobCandidateWorkflowStagesIndex<T>(
    params: FetchJobCandidateWorkflowStagesIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      JobCandidateWorkflowStagesBffRoutes.fetchJobCandidateWorkflowStagesIndexRoute(),
      params
    );
  }

  static fetchJobCandidateWorkflowStage<T>(
    jobCandidateWorkflowStageId: JobCandidateWorkflowStageId,
    params: FetchJobCandidateWorkflowStageRequestParams
  ) {
    return this.fetchItem<T>(
      JobCandidateWorkflowStagesBffRoutes.fetchJobCandidateWorkflowStageRoute(
        jobCandidateWorkflowStageId
      ),
      params
    );
  }

  static fetchJobCandidateWorkflowStageIndex<T>(
    jobCandidateWorkflowStageNanoId: JobCandidateWorkflowStageNanoId,
    params: FetchJobCandidateWorkflowStageIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      JobCandidateWorkflowStagesBffRoutes.fetchJobCandidateWorkflowStageIndexRoute(
        jobCandidateWorkflowStageNanoId
      ),
      params
    );
  }

  static fetchJobCandidateWorkflowStageByNanoId<T>(
    jobCandidateWorkflowStageNanoId: JobCandidateWorkflowStageNanoId,
    params: FetchJobCandidateWorkflowStageRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      JobCandidateWorkflowStagesBffRoutes.fetchJobCandidateWorkflowStageByNanoIdRoute(
        jobCandidateWorkflowStageNanoId
      ),
      params
    );
  }
}
