import { JobCandidateId } from '../jobCandidates/jobCandidatesTypes';
import { JobId } from '../jobs/jobsTypes';
import {
  FetchJobCandidateWorkflowStageCacheKey,
  FetchJobCandidateWorkflowStagesCacheKey
} from './jobCandidateWorkflowStagesTypes';

export class JobCandidateWorkflowStagesCache {
  static indexCacheKey() {
    return 'jobCandidateWorkflowStages' as FetchJobCandidateWorkflowStagesCacheKey;
  }

  static showCacheKey() {
    return 'jobCandidateWorkflowStage' as FetchJobCandidateWorkflowStageCacheKey;
  }

  static jobCandidateIndexCacheKey(jobCandidateId: JobCandidateId) {
    return `jobCandidateWorkflowStage-jobCandidate-${jobCandidateId}` as FetchJobCandidateWorkflowStageCacheKey;
  }

  static jobCandidateJobIndexCacheKey(
    jobCandidateId: JobCandidateId,
    jobId: JobId
  ) {
    return `jobCandidateWorkflowStage-jobCandidate-${jobCandidateId}-${jobId}` as FetchJobCandidateWorkflowStageCacheKey;
  }
}
