import { useEffect } from 'react';

interface ValidationErrors {
  [key: string]: string | undefined;
}

function useScrollToFirstError(validationErrors: ValidationErrors) {
  useEffect(() => {
    const errorKeys = Object.keys(validationErrors);

    if (errorKeys.length > 0) {
      const firstErrorElement = document.querySelector(
        '.chakra-form__error-message'
      );

      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        (firstErrorElement as HTMLElement).focus();
      }
    }
  }, [validationErrors]);
}

export default useScrollToFirstError;
