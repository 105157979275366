import {
  WorkflowStageTaskReminder,
  WorkflowStageTaskReminders
} from '../../workflowStageTasksTypes';

function renderWorkflowStageTaskDueDate(reminder: WorkflowStageTaskReminder) {
  switch (reminder) {
    case WorkflowStageTaskReminders.ONE_HOUR_BEFORE:
      return '1 hour before';
    case WorkflowStageTaskReminders.TWO_HOURS_BEFORE:
      return '2 hours before';
    case WorkflowStageTaskReminders.TWELVE_HOURS_BEFORE:
      return '12 hours before';
    case WorkflowStageTaskReminders.ONE_DAY_BEFORE:
      return '1 day before';
    case WorkflowStageTaskReminders.ONE_WEEK_BEFORE:
      return '1 week before';
    case WorkflowStageTaskReminders.ONE_HOUR_AFTER:
      return '1 hour after';
    case WorkflowStageTaskReminders.TWO_HOURS_AFTER:
      return '2 hours after';
    case WorkflowStageTaskReminders.TWELVE_HOURS_AFTER:
      return '12 hours after';
    case WorkflowStageTaskReminders.ONE_DAY_AFTER:
      return '1 day after';
    case WorkflowStageTaskReminders.ONE_WEEK_AFTER:
      return '1 week after';
    case WorkflowStageTaskReminders.ADD_EMAIL_REMINDER:
      return 'Add email reminder';
    default:
      return 'Unknown';
  }
}

export default renderWorkflowStageTaskDueDate;
