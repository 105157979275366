import { Box, Collapse, Flex } from '@chakra-ui/react';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import size from 'lodash/size';
import sumBy from 'lodash/sumBy';
import { Button } from '../../../../../helpers/Button';
import { Search } from '../../../../../helpers/Search';
import { Text } from '../../../../../helpers/Text';
import { IconButton } from '../../../../../helpers/buttons/IconButton';
import { FilterIcon } from '../../../../../icons/FilterIcon';
import { TalentPoolFilterParams } from '../TalentPoolCandidatesListContainer/TalentPoolCandidatesListContainer';

interface TalentPoolCandidatesSearchFilterProps {
  isFilterOpen?: boolean;
  clearFilters?: () => void;
  toggleFilter?: () => void;
  activeFilters?: TalentPoolFilterParams;
}

export default function TalentPoolCandidatesSearchFilter({
  clearFilters,
  toggleFilter,
  activeFilters
}: TalentPoolCandidatesSearchFilterProps) {
  const getFilterSummary = (filterParams: TalentPoolFilterParams) => {
    const arrayFields = [
      'recruiters',
      'department',
      'location',
      'stages',
      'hiringManager',
      'jobStatus'
    ] as const;

    const arrayTotal = sumBy(arrayFields, (key) => {
      const value = filterParams[key];
      return Array.isArray(value) ? size(value) : 0;
    });

    const booleanTotal = keys(
      pickBy(filterParams, (value) => value === true)
    ).length;

    const totalItems = booleanTotal + arrayTotal;

    return { arrayTotal, booleanTotal, totalItems };
  };

  const { totalItems } =
    (activeFilters && getFilterSummary(activeFilters)) || {};

  return (
    <Flex
      pl={4}
      pr={2}
      pt={3}
      pb={3.5}
      bg={(totalItems ?? 0) > 0 ? 'primary.100' : 'gray.100'}
      alignItems="center"
      zIndex={10}
      pos="sticky"
      gap={2}
      top={0}
    >
      <Flex gap={0} flex={1} flexDir="column" alignItems="center">
        <Search
          bg="white"
          isClickableRightElement
          rightElement={
            (totalItems ?? 0) === 0 ? (
              <IconButton
                p={0}
                size="small"
                color="gray.600"
                aria-label="Filter"
                hierarchy="unstyled"
                icon={<FilterIcon w={5} h={5} />}
                onClick={toggleFilter}
              />
            ) : null
          }
        />

        <Box w="full">
          <Collapse in={(totalItems ?? 0) > 0}>
            <Flex
              mt={2}
              flex={1}
              overflow="hidden"
              borderRadius="md"
              bg="whiteAlpha.600"
            >
              <Button
                h="auto"
                gap={3}
                minH={0}
                flex={1}
                display="flex"
                onClick={toggleFilter}
                justifyContent="flex-start"
                hierarchy="unstyled"
                _hover={{
                  bg: 'white'
                }}
              >
                <Flex
                  pl={2}
                  gap={1.5}
                  alignItems="center"
                  justifyContent="center"
                >
                  <FilterIcon w={5} h={5} />
                  <Text textStyle="body1Medium">Filters</Text>
                </Flex>

                <Flex alignItems="center">
                  <Flex
                    py={0}
                    px={3}
                    gap={1}
                    rounded="full"
                    alignItems="center"
                    bg="white"
                  >
                    <Box w={2.5} h={2.5} bg="blue.500" rounded="full" />
                    <Text fontWeight="semibold">{totalItems}</Text>
                  </Flex>
                </Flex>
              </Button>

              <Button
                ml="auto"
                size="small"
                color="gray.800"
                hierarchy="ghost"
                boxShadow="none"
                borderLeftRadius={0}
                _hover={{
                  bg: 'white'
                }}
                onClick={clearFilters}
              >
                Clear all
              </Button>
            </Flex>
          </Collapse>
        </Box>
      </Flex>
    </Flex>
  );
}
